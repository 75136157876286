import {
    EnhancedFile,
    EnhancedNotification,
    FileHeaders,
    Type
} from '@reportroyal/api';
import debounce from 'lodash.debounce';
import {
    Lambda,
    computed,
    makeAutoObservable,
    observable,
    reaction,
    when
} from 'mobx';
import { RouteEnterEvent, Router, navigate } from 'takeme';
import { TSDI, component, initialize } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import {
    connect,
    deleteNotifications,
    loadNotifications,
    loadProjectTypes,
    sendNotificationMails,
    storage,
    validateConnection
} from '../aws';
import { listenToWebsocketUpdate, subscribeForVersion } from '../aws/websocket';
import { confirm } from '../components';
import { AccessStore } from '../core/access-store';
import { bugsnagClient } from '../core/bugsnag';
import { GoogleAnalytics } from '../core/ga';
import { MediaStore } from '../core/media-store';
import {
    dateFormat,
    guid,
    injectTSDI,
    isNumeric,
    loadImageMetadata,
    replaceUmlauts
} from '../core/utils';
import { cropImages } from '../crop-image';
import { ProcessPdf } from '../process-pdf';

export type Route =
    | '/'
    | '/login'
    | '/password-forgot'
    | '/search/:query'
    | '/project/:id'
    | '/project/:id/:type'
    | '/project/:id/:type/:viewmode'
    | '/project/:id/:type/:viewmode/:item'
    | '/calendar/evt'
    | '/settings'
    | '/settings/pool'
    | '/settings/leadstories'
    | '/settings/authors'
    | '/settings/tags'
    | '/settings/users'
    | '/settings/users/:id'
    | '/settings/projects'
    | '/settings/projects/:id'
    | '/settings/media/:type'
    | '/settings/media/:type/:id'
    | '/settings/mails'
    | '/settings/companies'
    | '/settings/companies/:id'
    | '/settings/roles'
    | '/settings/roles/:id'
    | '/legal/imprint'
    | '/notify/unsubscribe/:token'
    | '/help'
    | '/help/:id';

export type DragoverState =
    | 'wip'
    | 'print-items'
    | 'online-items'
    | 'social-items'
    | 'tvradio-items'
    | 'pool';
type ViewState = 'desktop' | 'tablet' | 'mobile';

interface RouteObj {
    id: string;
    route: Route;
    public?: boolean;
    beforeEnter?(e: RouteEnterEvent): Promise<{ redirect: string } | void>;
    enter?(e: RouteEnterEvent): Promise<any> | void;
}

interface TsdiScope {
    enter(): void;
    leave(): void;
}

@component
export class AppStore {
    private get mediaStore() {
        return injectTSDI(MediaStore);
    }

    private get ga() {
        return injectTSDI(GoogleAnalytics);
    }

    private get tsdi() {
        return injectTSDI(TSDI);
    }

    private get access() {
        return injectTSDI(AccessStore);
    }

    private get processPdf() {
        return injectTSDI(ProcessPdf);
    }

    @computed
    public get loggedIn() {
        return Boolean(storage.get().me);
    }

    public route?: Route;

    public routePath!: string;

    public params: { [key: string]: string } = {};

    public uploading: File[] = [];

    public redirectAfterLogin = '/';

    public dragover?: DragoverState;

    public globalSearch?: string;

    public globalSearchPlaceholder?: string;

    public notifications = wrapRequest(loadNotifications, {
        defaultData: []
    });

    public projectTypes = wrapRequest(loadProjectTypes, {
        defaultData: []
    });

    private viewState!: ViewState;

    private router!: Router;
    private routes: RouteObj[] = [
        { id: 'projects', route: '/' },
        {
            id: 'login',
            route: '/login',
            public: true,
            beforeEnter: async () => {
                if (this.loggedIn) {
                    return {
                        redirect: '/'
                    };
                }

                return;
            }
        },
        { id: 'password-forgot', route: '/password-forgot', public: true },
        { id: 'search', route: '/search/:query' },
        {
            id: 'project',
            route: '/project/:id/:type'
        },
        {
            id: 'project',
            route: '/project/:id/:type/:viewmode',
            enter: (e) => this.onEnterProjectRoute(e)
        },
        {
            id: 'project',
            route: '/project/:id/:type/:viewmode/:item',
            enter: (e) => this.onEnterProjectRoute(e)
        },
        { id: 'settings', route: '/settings' },
        { id: 'settings-pool', route: '/settings/pool' },
        { id: 'settings-leadstories', route: '/settings/leadstories' },
        { id: 'settings-authors', route: '/settings/authors' },
        { id: 'settings-tags', route: '/settings/tags' },
        { id: 'settings-users', route: '/settings/users' },
        { id: 'settings-user', route: '/settings/users/:id' },
        { id: 'settings-projects', route: '/settings/projects' },
        { id: 'settings-project', route: '/settings/projects/:id' },
        { id: 'settings-media', route: '/settings/media/:type' },
        { id: 'settings-media-edit', route: '/settings/media/:type/:id' },
        { id: 'settings-mails', route: '/settings/mails' },
        { id: 'settings-companies', route: '/settings/companies' },
        { id: 'settings-company', route: '/settings/companies/:id' },
        { id: 'settings-roles', route: '/settings/roles' },
        { id: 'settings-role', route: '/settings/roles/:id' },
        { id: 'legal-imprint', route: '/legal/imprint', public: true },
        { id: 'help', route: '/help' },
        { id: 'help-article', route: '/help/:id' },
        { id: 'calendar-evt', route: '/calendar/evt' },
        {
            id: 'notify-unsubscribe',
            route: '/notify/unsubscribe/:token',
            public: true
        }
    ];
    public dropIds: {
        [id: string]: { callback: (e: any) => any; event: string }[];
    } = {};

    public notificationsSubscription?: Lambda;

    private get currentRoute(): RouteObj | undefined {
        return this.routes.find((r) => r.route === this.route);
    }

    public get mobile(): boolean {
        return this.viewState === 'mobile';
    }

    public get tablet(): boolean {
        return this.viewState === 'tablet';
    }

    public get desktop(): boolean {
        return this.viewState === 'desktop';
    }

    @initialize
    public async init() {
        makeAutoObservable(this, {
            dropIds: false,
            notificationsSubscription: observable
        });

        await connect();

        const takemeRoutes = this.routes.map((routeObj) => ({
            $: routeObj.route,
            beforeEnter: (e: RouteEnterEvent) => {
                if (routeObj.beforeEnter) {
                    return routeObj.beforeEnter(e);
                }

                if (!this.loggedIn && !routeObj.public) {
                    this.redirectAfterLogin = e.newPath;

                    return Promise.resolve({ redirect: '/login' });
                }

                if (this.loggedIn && routeObj.route === '/login') {
                    return Promise.resolve({ redirect: '/' });
                }

                return;
            },
            enter: (e: RouteEnterEvent) => {
                this.setRoute(routeObj.route, e);

                if (routeObj.enter) {
                    routeObj.enter(e);
                }

                this.track();
            }
        }));

        this.router = new Router(takemeRoutes);

        const routerInstance = this.router.enableHtml5Routing();

        reaction(
            () => this.route,
            (route) => {
                this.manageGlobalSearch();
                this.manageScopes(route);
            },
            { fireImmediately: true }
        );

        reaction(
            () => this.loggedIn,
            async (loggedIn) => {
                if (loggedIn) {
                    void this.notifications.request(undefined, {
                        throwError: true
                    });

                    await Promise.all([
                        this.access.myRoles.request(),
                        this.projectTypes.request()
                    ]);

                    routerInstance.init();

                    this.notificationsSubscription?.();
                    this.notificationsSubscription = listenToWebsocketUpdate({
                        path: '/notifications',
                        callback: () =>
                            this.notifications.request(undefined, {
                                stateLoading: false
                            })
                    });

                    if (process.env.NODE_ENV === 'production') {
                        this.ga.init();
                    }
                } else {
                    this.access.reset();
                    this.mediaStore.reset();

                    routerInstance.init();

                    if (this.currentRoute && !this.currentRoute.public) {
                        navigate('/login');
                    }
                }
            },
            { fireImmediately: true }
        );

        reaction(
            () => this.globalSearch,
            debounce((val) => {
                if (this.route !== '/project/:id/:type/:viewmode') {
                    if (val) {
                        navigate(`/search/${val}`);
                    } else if (val === '') {
                        navigate('/');
                    }
                }
            }, 300)
        );

        this.setMediaQueries();
        this.versionCheck();
    }

    private onEnterProjectRoute(e: RouteEnterEvent) {
        const { type } = e.params;

        if (type) {
            this.mediaStore.types = [type as Type];
        }
    }

    public async notifyUsers(companyId: string) {
        const uuid = guid();

        await sendNotificationMails({
            uuid,
            companyId
        });

        return uuid;
    }

    public waitForDrop(
        id: DragoverState,
        condition: () => boolean = () => true
    ): Promise<File[]> {
        return new Promise((resolve) => {
            this.disableDropping(id);

            const dragoverCB = (e: React.DragEvent<any>) => {
                e.preventDefault();
                const { items } = e.dataTransfer;

                if (items && items.length && condition()) {
                    this.dragover = id;
                }
            };
            const dragend = (e: React.DragEvent<any>) => {
                e.preventDefault();

                if (condition()) {
                    this.dragover = undefined;
                }
            };
            const dropCB = (e: React.DragEvent<any>) => {
                e.preventDefault();

                if (condition()) {
                    this.dragover = undefined;

                    const { files } = e.dataTransfer;

                    if (files && files.length) {
                        const fileIterator: File[] = [];

                        for (let i = 0; i < files.length; i++) {
                            fileIterator.push(files[i]);
                        }

                        resolve(fileIterator);
                    }
                }
            };

            window.addEventListener('dragover', dragoverCB as any, false);
            window.addEventListener('dragend', dragend as any, false);
            window.addEventListener('dragleave', dragend as any, false);
            window.addEventListener('drop', dropCB as any, false);

            this.dropIds[id] = [
                { event: 'dragover', callback: dragoverCB },
                { event: 'dragend', callback: dragend },
                { event: 'dragleave', callback: dragend },
                { event: 'drop', callback: dropCB }
            ];
        });
    }

    public disableDropping(id: DragoverState) {
        if (this.dropIds[id]) {
            this.dropIds[id].forEach((obj) =>
                window.removeEventListener(obj.event, obj.callback)
            );
        }
        this.dragover = undefined;
    }

    public async upload(
        files: File[],
        destination: string,
        options?: {
            headers?: FileHeaders;
            disableCleanup?: boolean;
            onFile?(file: EnhancedFile, index: number): void;
        }
    ): Promise<EnhancedFile[]> {
        await validateConnection();

        files = files.map(
            (file) =>
                new File([file], replaceUmlauts(file.name), {
                    lastModified: file.lastModified,
                    type: file.type
                })
        );

        this.uploading = files;

        const pdfFiles = files.filter(
            (file) => file.type === 'application/pdf'
        );
        const otherFiles = files.filter(
            (file) => file.type !== 'application/pdf'
        );
        const allFiles = otherFiles.slice(0);

        // always crop online-images
        if (destination === 'items/online') {
            const croppedFiles = await cropImages({ files, type: 'online' });

            allFiles.length = 0;
            allFiles.push(...croppedFiles);
        }

        if (pdfFiles.length) {
            this.uploading = otherFiles;
        }

        for (const pdfFile of pdfFiles) {
            const files = (await this.processPdf.start(pdfFile)).filter(
                Boolean
            );
            const file: File | undefined = files[files.length - 1];

            if (file) {
                this.uploading = [...this.uploading, file];

                allFiles.push(...files);
            }
        }

        const uploadedFiles: EnhancedFile[] = [];

        let i = 0;
        for (const file of allFiles) {
            const ext = file.name.split('.').pop();
            const dir = dateFormat(new Date(), 'YYYYMMDD');
            const postfix = dateFormat(new Date(), 'x');
            const metadata = file.type.startsWith('image')
                ? await loadImageMetadata(file)
                : {};
            const fromPdf = pdfFiles.some(
                (f) =>
                    f.name.replace(/\.pdf$/, '') ===
                    file.name.replace(new RegExp(`_\\{\\d+\\}\.${ext}$`), '')
            );

            const headers: FileHeaders = {
                title: encodeURIComponent(file.name),
                ...metadata
            };

            if (fromPdf) {
                headers['from-pdf'] = 'true';
            }

            const uploadFile = new EnhancedFile({
                bucket: 'report-royal-files',
                key: `${destination}/${dir}/item-${postfix}.${ext}`,
                headers: { ...headers, ...options?.headers }
            });

            try {
                await uploadFile.upload(file);

                options?.onFile?.(uploadFile, i);

                uploadedFiles.push(uploadFile);
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : undefined;

                console.error(e);

                this.uploading = this.uploading.filter(
                    (_, index) => index !== i
                );

                confirm(
                    `<span>Unfortunately, the upload failed for <b>${file.name}</b>.<br>Please try again.<br>We are informed about that.</span>`,
                    { title: 'Upload failed', error: errorMessage }
                );

                if (e instanceof Error) {
                    bugsnagClient?.notify(e);
                }
            }

            i++;
        }

        if (!options?.disableCleanup) {
            this.uploading = [];
        }

        return uploadedFiles;
    }

    private setRoute(route: Route, routeEvent: RouteEnterEvent): void {
        const { params, newPath } = routeEvent;

        this.params = Object.entries(params).reduce((memo, [key, value]) => {
            if (isNumeric(value)) {
                memo[key] = parseInt(value, 10);
            } else {
                memo[key] = value;
            }

            return memo;
        }, {});
        this.routePath = newPath;
        this.route = route;

        scrollTo(0, 0);
    }

    public async readNotification(
        item: EnhancedNotification
    ): Promise<EnhancedNotification> {
        const result = await item.delete();

        this.notifications.reset(
            this.notifications.$.filter(
                (notification) => notification.id !== item.id
            )
        );

        return result;
    }

    public async readNotifications(): Promise<void> {
        confirm('Do you really want to clear all notifications?', {
            title: 'Clear notifications',
            confirmText: 'Ok',
            action: async () => {
                await deleteNotifications();

                this.notifications.reset([]);
            }
        });
    }

    public setSearchPlaceholder(value?: string): void {
        this.globalSearchPlaceholder = value;
    }

    private manageScopes(newRoute?: Route): void {
        if (!newRoute) {
            return;
        }

        // enter current scope
        if (this.currentRoute) {
            this.tsdi.getScope(this.currentRoute.id).enter();
        }

        // leave other scopes
        this.routes
            .reduce((memo, routeObj) => {
                const scope = this.tsdi.getScope(routeObj.id);

                if (this.currentRoute) {
                    if (this.currentRoute.id !== routeObj.id) {
                        memo.push(scope);
                    }
                } else {
                    memo.push(scope);
                }

                return memo;
            }, [] as TsdiScope[])
            .forEach((scope) => scope.leave());
    }

    private manageGlobalSearch(): void {
        if (
            this.route === '/search/:query' &&
            typeof this.params.query === 'string'
        ) {
            this.globalSearch = this.params.query;
        } else if (
            this.globalSearch !== '' &&
            this.globalSearch !== undefined
        ) {
            this.globalSearch = undefined;
        }
    }

    private async track() {
        await when(() => this.access.myRoles.fetched);

        this.ga.sendPageview(this.routePath);
    }

    private setMediaQueries(): void {
        const mediaQueryMobile = matchMedia('(max-width: 767px)');
        const mediaQueryTablet = matchMedia(
            '(min-width: 768px) and (max-width: 1279px)'
        );
        const mediaQueryDesktop = matchMedia('(min-width: 1280px)');

        mediaQueryMobile.addListener((event) => {
            if (event.matches) {
                this.viewState = 'mobile';
            }
        });

        mediaQueryTablet.addListener((event) => {
            if (event.matches) {
                this.viewState = 'tablet';
            }
        });

        mediaQueryDesktop.addListener((event) => {
            if (event.matches) {
                this.viewState = 'desktop';
            }
        });

        if (mediaQueryMobile.matches) {
            this.viewState = 'mobile';
        }

        if (mediaQueryTablet.matches) {
            this.viewState = 'tablet';
        }

        if (mediaQueryDesktop.matches) {
            this.viewState = 'desktop';
        }
    }

    private versionCheck() {
        const unsubscribe = subscribeForVersion(() => {
            unsubscribe();

            confirm(
                "A new version was released. We recommend to reload the application.<br>If you don't want to do that right now, you can always press 'Cancel' and reload the application later.",
                {
                    title: 'New version',
                    confirmText: 'Reload',
                    action: async () => location.reload(),
                    onCancel: () => this.versionCheck()
                }
            );
        });
    }
}
