import { component } from 'tsdi';

@component
export class CompanyStore {
    public isSony = (companyId?: string) => {
        return companyId === '/db/Company/afd302d8-048e-417c-bb6b-b02b103bdb3b';
    };

    public isTobis = (companyId?: string) => {
        return companyId === '/db/Company/83e07b0f-bb1b-49a7-ae5a-9b0a44a47ba6';
    };

    public isStudioCanal = (companyId?: string) => {
        return (
            // SC
            companyId === '/db/Company/01b1ee3b-8065-4302-b173-0b5c90f7262b' ||
            // Report Royal
            companyId === '/db/Company/21afd673-bd42-44ba-8e66-44046e6199c1'
        );
    };
}
