import { createGlobalStyle } from 'styled-components';

export const GlobalCSS = createGlobalStyle`
    ::view-transition-old(root),
    ::view-transition-new(root) {
        animation-duration: 0.3s;
    }

    html, body {
        min-height: 100vh;
    }

    html {
        box-sizing: border-box;
        font-size: 10px;
        -webkit-tap-highlight-color: transparent;
        background: #f9f9f9;
    }

    body {
        overflow-y: scroll;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
        background: #fcfcfc;
        color: #333;
        font-family: 'Ubuntu', sans-serif;
        font-size: 14px;
        font-size: 1.4rem;
        overflow-x: hidden;
        text-rendering: optimizeLegibility;
    }

    #app {
        width: 100%;
        min-height: 100vh;
        background: #eee;
        line-height: 0;
    }

    * {
        box-sizing: border-box;
    }

    a,
    a:link {
        color: #3F4D56;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        padding-left: 15px;
        margin: 10px 0;
        overflow: hidden;
    }
`;
