import {
    EnhancedItemOnline,
    EnhancedItemPrint,
    EnhancedItemSocial,
    EnhancedItemTvradio,
    SearchGetRequest,
    enhanceEntity
} from '@reportroyal/api';
import { client } from './api';

export async function loadSearch(config: SearchGetRequest) {
    const items = await client.searchGet(config);

    const itemsPrint = items.itemsPrint.map((item) =>
        enhanceEntity<EnhancedItemPrint>(item, { depth: 1 })
    );
    const itemsOnline = items.itemsOnline.map((item) =>
        enhanceEntity<EnhancedItemOnline>(item, { depth: 1 })
    );
    const itemsSocial = items.itemsSocial.map((item) =>
        enhanceEntity<EnhancedItemSocial>(item, { depth: 1 })
    );
    const itemsTvradio = items.itemsTvradio.map((item) =>
        enhanceEntity<EnhancedItemTvradio>(item, { depth: 1 })
    );

    return { itemsPrint, itemsOnline, itemsSocial, itemsTvradio };
}
