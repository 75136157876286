import { configure as mobxConfigure } from 'mobx';
import 'mobx-wrap-request';
import moment from 'moment';
import 'moment/locale/de';
import 'prop-types';
import * as React from 'react';
import { render } from 'react-dom';
import styled from 'styled-components';
import { TSDI } from 'tsdi';
import {
    BrowserTitle,
    ConfirmModal,
    Favicon,
    Layout,
    ModalCSS,
    Responsive,
    Spinner
} from '../components';
import { Bugsnag } from '../core/bugsnag';
import { Router } from '../core/router';
import { GlobalCSS } from './css';
import { Footer } from './footer';
import { Header } from './header';
import { StyleSheetManager } from './styled-sheet-manager';

mobxConfigure({
    enforceActions: 'never',
    useProxies: 'Proxy' in window ? 'always' : 'never'
});

moment.locale('de');

const tsdi = new TSDI();

tsdi.enableComponentScanner();

const responsive = tsdi.get(Responsive);

const App = styled(Layout)`
    width: ${responsive.sizeApp.max + 40}px;
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    line-height: normal;
`;

export function renderLoader() {
    render(
        <StyleSheetManager>
            <Spinner top="25px" />
        </StyleSheetManager>,
        document.getElementById('app')
    );
}

export async function renderApp() {
    const ErrorFallback =
        process.env.NODE_ENV === 'production' && !process.env.TESTING
            ? Bugsnag
            : (props) => props.children;

    render(
        <StyleSheetManager>
            <React.StrictMode>
                <App between="16" stack>
                    <Favicon />
                    <BrowserTitle />
                    <ErrorFallback>
                        <Header />
                        <Router />
                    </ErrorFallback>
                    <Footer />
                    <ConfirmModal />
                    <GlobalCSS />
                    <ModalCSS />
                </App>
            </React.StrictMode>
        </StyleSheetManager>,
        document.getElementById('app')
    );
}
