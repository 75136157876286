import { useEffect, useState } from 'react';

export function startViewTransition(cb: () => any) {
    document['startViewTransition']?.(cb) ?? cb();
}

export function useViewTransitionToggle(dep: boolean) {
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        startViewTransition(() => {
            setVisibility(dep);
        });
    }, [dep]);

    return {
        visibility: visibility ? 'visible' : 'hidden',
        visible: visibility
    } as const;
}

export function useViewTransition(dep: any) {
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        setVisibility(false);
    }, [dep]);

    useEffect(() => {
        if (!visibility) {
            startViewTransition(() => {
                setVisibility(true);
            });
        }
    }, [visibility, dep]);

    return {
        visibility: visibility ? 'visible' : 'hidden',
        visible: visibility
    } as const;
}
