import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedMailNotificationsConfigForm = FormState<{
    uuid: FieldState<string>;
    companyId: FieldState<string>;
    NODE_ENV: FieldState<string>;
}>;

export class EnhancedMailNotificationsConfig extends EnhancedEntity<EnhancedMailNotificationsConfigForm> {
    public uuid!: string;
    public companyId!: string;
    public NODE_ENV!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            uuid: observable,
            companyId: observable,
            NODE_ENV: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            uuid: this.uuid,
            companyId: this.companyId,
            NODE_ENV: this.NODE_ENV,
        };
    }

    public initForm() {
        this.form = new FormState({
            uuid: new FieldState<string>(this.uuid),
            companyId: new FieldState<string>(this.companyId),
            NODE_ENV: new FieldState<string>(this.NODE_ENV),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
