import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { MediaItemMediaOnline } from '../schema';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedMediaTypeOnline } from './MediaTypeOnline';

export type EnhancedMediaOnlineForm = FormState<{
    id: FieldState<string>;
    type: FieldState<MediaItemMediaOnline>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    title: FieldState<string>;
    url: FieldState<string>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    agofUniqueUser: FieldState<number>;
    maxStarRating: FieldState<number>;
    relevance: FieldState<number>;
    mediaType: FieldState<EnhancedMediaTypeOnline>;
    active: FieldState<boolean>;
    migrateId: FieldState<number | undefined>;
}>;

export class EnhancedMediaOnline extends EnhancedEntity<EnhancedMediaOnlineForm> {
    /* public id!: string; */
    public type!: MediaItemMediaOnline;
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public title!: string;
    public url!: string;
    public createdAt!: Date;
    public updatedAt!: Date;
    public agofUniqueUser!: number;
    public maxStarRating!: number;
    public relevance!: number;
    public mediaType!: EnhancedMediaTypeOnline;
    public active!: boolean;
    public migrateId!: number | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            type: observable,
            version: observable,
            title: observable,
            url: observable,
            createdAt: observable,
            updatedAt: observable,
            agofUniqueUser: observable,
            maxStarRating: observable,
            relevance: observable,
            mediaType: observable,
            active: observable,
            migrateId: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            type: this.type,
            version: this.version,
            acl: this.acl,
            title: this.title,
            url: this.url,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            agofUniqueUser: this.agofUniqueUser,
            maxStarRating: this.maxStarRating,
            relevance: this.relevance,
            mediaType: this.mediaType,
            active: this.active,
            migrateId: this.migrateId,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            type: new FieldState<MediaItemMediaOnline>(this.type),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            title: new FieldState<string>(this.title),
            url: new FieldState<string>(this.url),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            agofUniqueUser: new FieldState<number>(this.agofUniqueUser),
            maxStarRating: new FieldState<number>(this.maxStarRating),
            relevance: new FieldState<number>(this.relevance),
            mediaType: new FieldState<EnhancedMediaTypeOnline>(
                new EnhancedMediaTypeOnline(this.mediaType, { initForm: true }),
            ),
            active: new FieldState<boolean>(this.active),
            migrateId: new FieldState<number | undefined>(this.migrateId),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
