import { component } from 'tsdi';
import { AccessStore } from './access-store';
import { injectTSDI } from './utils';

@component
export class GoogleAnalytics {
    private get access() {
        return injectTSDI(AccessStore);
    }

    private get ga() {
        if (typeof ga !== 'undefined' && this.allow) {
            return ga;
        }

        return () => {};
    }

    private initialized = false;

    private get allow() {
        const { $: myRoles } = this.access.myRoles;

        return !myRoles.includes('reportroyal');
    }

    public init() {
        if (this.allow && !this.initialized) {
            // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/
            /* tslint:disable */
            // prettier-ignore
            // @ts-ignore
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            /* tslint:enable */

            this.ga('create', 'UA-120506484-1', 'auto');
            this.ga('set', 'anonymizeIP', true as any);

            this.initialized = true;
        }
    }

    public sendPageview(route: string) {
        if (route) {
            this.ga('set', 'page', route);
            this.ga('send', 'pageview');
        }
    }

    public setDimension(dimension: 'dimension1' | 'dimension2', value: string) {
        this.ga('set', dimension, value);
    }

    public sendEvent(
        category: GACategory,
        action?: GAAction,
        label?: string,
        value?: string
    ) {
        this.ga('send', 'event', category, action, label, value);
    }
}
