import { Tag, Type } from '@reportroyal/api';
import * as React from 'react';
import { confirm } from '../components';
import { ModalCrop } from '../process-pdf/modal';

export async function cropImages(config: { files: File[]; type: Type }) {
    const urls = config.files.map((file) => URL.createObjectURL(file));
    const blobs = await openModalForCropping({
        urls,
        type: config.type
    });

    return blobs.map((blob, i) => {
        return new File([blob], config.files[i].name, {
            lastModified: config.files[i].lastModified,
            type: blob.type
        });
    });
}

export async function openModalForCropping(config: {
    urls: string[];
    type: Type;
}) {
    const blobs: Blob[] = [];

    for (let i = 0; i < config.urls.length; i++) {
        const url = config.urls[i];

        const blob = await cropImage({
            type: config.type,
            url,
            page: i + 1,
            maxPages: config.urls.length
        });

        if (blob) {
            blobs.push(blob);
        }
    }

    return blobs;
}

async function cropImage(config: {
    type: Type;
    url: string;
    page: number;
    maxPages: number;
}) {
    const { type, url, page, maxPages } = config;

    return new Promise<Blob | null>((resolve) => {
        const img = document.createElement('img');

        img.onload = async () => {
            let tag: Tag | undefined =
                type === 'online'
                    ? {
                          x: 0,
                          y: 0,
                          width: img.naturalWidth,
                          height: Math.min(
                              img.naturalWidth * 1.414,
                              img.naturalHeight
                          )
                      }
                    : undefined;

            confirm(
                <ModalCrop
                    width={img.naturalWidth}
                    height={img.naturalHeight}
                    url={url}
                    tag={tag}
                    type={type}
                    onUpdate={(newTag) => {
                        tag = newTag;
                    }}
                />,
                {
                    size: 'FULLSCREEN',
                    confirmText: 'Crop',
                    title: `Please select the part you want to crop (Page ${page} of ${maxPages})`,
                    cancelText: 'Skip page',
                    onCancel: () => resolve(null),
                    action: async () => {
                        if (!tag) {
                            tag = {
                                x: 0,
                                y: 0,
                                width: img.naturalWidth,
                                height: img.naturalHeight
                            };
                        }

                        const canvas = document.createElement('canvas');
                        const buffer = document.createElement('canvas');
                        const bufferContext = buffer.getContext('2d')!;
                        const canvasContext = canvas.getContext('2d')!;

                        canvas.width = img.naturalWidth;
                        canvas.height = img.naturalHeight;

                        buffer.width = tag.width;
                        buffer.height = tag.height;

                        canvasContext.drawImage(img, 0, 0);
                        bufferContext.drawImage(
                            canvas,
                            tag.x,
                            tag.y,
                            tag.width,
                            tag.height,
                            0,
                            0,
                            buffer.width,
                            buffer.height
                        );

                        buffer.toBlob((blob) => {
                            resolve(blob);

                            bufferContext.clearRect(
                                0,
                                0,
                                buffer.width,
                                buffer.height
                            );
                            canvasContext.clearRect(
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );

                            buffer.remove();
                            canvas.remove();
                            img.remove();
                        });
                    }
                }
            );
        };

        img.src = url;
    });
}
