import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { Lang } from '../schema';
import { EnhancedFile } from '../../entities/enhanced-file';

export type EnhancedUrlMetaForm = FormState<{
    headline: FieldState<string | undefined>;
    quote: FieldState<string | undefined>;
    media: FieldState<string | undefined>;
    lang: FieldState<Lang | undefined>;
    image: FieldState<EnhancedFile | undefined>;
}>;

export class EnhancedUrlMeta extends EnhancedEntity<EnhancedUrlMetaForm> {
    public headline!: string | undefined;
    public quote!: string | undefined;
    public media!: string | undefined;
    public lang!: Lang | undefined;
    public image!: EnhancedFile | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            headline: observable,
            quote: observable,
            media: observable,
            lang: observable,
            image: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            headline: this.headline,
            quote: this.quote,
            media: this.media,
            lang: this.lang,
            image: this.image,
        };
    }

    public initForm() {
        this.form = new FormState({
            headline: new FieldState<string | undefined>(this.headline),
            quote: new FieldState<string | undefined>(this.quote),
            media: new FieldState<string | undefined>(this.media),
            lang: new FieldState<Lang | undefined>(this.lang),
            image: new FieldState<EnhancedFile | undefined>(
                this.image === undefined || this.image === null
                    ? undefined
                    : new EnhancedFile(this.image),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
