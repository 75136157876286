import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedTranslationForm = FormState<{ text: FieldState<string> }>;

export class EnhancedTranslation extends EnhancedEntity<EnhancedTranslationForm> {
    public text!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            text: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { text: this.text };
    }

    public initForm() {
        this.form = new FormState({
            text: new FieldState<string>(this.text),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
