import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedMailNotificationsResultForm = FormState<{
    uuid: FieldState<string>;
}>;

export class EnhancedMailNotificationsResult extends EnhancedEntity<EnhancedMailNotificationsResultForm> {
    public uuid!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            uuid: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { uuid: this.uuid };
    }

    public initForm() {
        this.form = new FormState({
            uuid: new FieldState<string>(this.uuid),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
