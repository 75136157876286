import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { EnhancedUser } from './User';

export type EnhancedRoleForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    name: FieldState<string>;
    users: FieldState<EnhancedUser[]>;
}>;

export class EnhancedRole extends EnhancedEntity<EnhancedRoleForm> {
    /* public id!: string; */
    public version!: number;
    public createdAt!: Date;
    public updatedAt!: Date;
    public name!: string;
    public users!: EnhancedUser[];

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            name: observable,
            users: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            name: this.name,
            users: this.users,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            name: new FieldState<string>(this.name),
            users: new FieldState<EnhancedUser[]>(
                (this.users || []).map(
                    (item) => new EnhancedUser(item, { initForm: true }),
                ),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
