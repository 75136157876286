import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Layout } from '../../components';

interface ErrorObj {
    error?: Error;
}

type Type = 'warning' | 'error';

interface ErrorMessageProps {
    type?: Type;
    wrapRequest: ErrorObj | ErrorObj[];
}

const Container = styled(Layout)<{ type?: Type }>`
    ${({ type }) => {
        switch (type) {
            default:
            case 'error':
                return css`
                    background: #882731;
                    color: #fff;
                `;
            case 'warning':
                return css`
                    background: #ffd901;
                    color: #000;
                `;
        }
    }}
`;

export const ErrorMessage = observer((props: ErrorMessageProps) => {
    const { wrapRequest, type } = props;

    if (Array.isArray(wrapRequest)) {
        return (
            <>
                {wrapRequest.map((r, i) =>
                    r.error ? (
                        <Container
                            key={i}
                            type={type}
                            data-role="error"
                            padding="8"
                            halign="center"
                        >
                            {r.error.message}
                        </Container>
                    ) : null
                )}
            </>
        );
    }

    return wrapRequest.error ? (
        <Container type={type} padding="8" data-role="error" halign="center">
            {wrapRequest.error.message}
        </Container>
    ) : null;
});
