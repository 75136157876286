import {
    EnhancedEntityOptions,
    EnhancedProject,
    EnhancedProjectForm,
    EnhancedProjectType,
    enhanceEntity,
    form2Entity,
    matchType,
    Project,
    ProjectJSON,
    ProjectsGetRequest,
    RecursivePartial,
    Type
} from '@reportroyal/api';
import { addDays, subDays } from '../core/utils';
import { client, getNewId } from './api';

export interface ProjectLoadOptions {
    depth?: number;
    sortBy?: 'releasedate' | 'name';
    includeArchived?: boolean;
    company?: string;
}

export const pastProjectDate = subDays(new Date(), 7);

export async function loadProjects(
    config: ProjectsGetRequest = {},
    options: EnhancedEntityOptions = { depth: 1 }
): Promise<EnhancedProject[]> {
    const { sortBy = 'releasedate', includeArchived = false, ...rest } = config;

    const items = await client.projectsGet({
        sortBy,
        includeArchived,
        ...rest
    });

    return items.map((item) => enhanceEntity(item, options));
}

export async function loadProject(
    id: string,
    options: EnhancedEntityOptions = { depth: 1 }
) {
    const item = await client.projectGet({ id });

    return enhanceEntity<EnhancedProject>(item, options);
}

export async function loadProjectTypes(): Promise<EnhancedProjectType[]> {
    const items = await client.projectsTypesGet();

    return items.map((item) => enhanceEntity(item));
}

export function putProject(form: EnhancedProjectForm) {
    return client.projectPut({ project: form2Entity(form) });
}

export function postProject(form: EnhancedProjectForm) {
    return client.projectPost({ project: form2Entity(form) });
}

export function deleteProject(id: string) {
    return client.projectDelete({ id });
}

export async function loadProjectType(id: string) {
    const item = await client.projectsTypeGet({ id });

    return enhanceEntity<EnhancedProjectType>(item);
}

export function isCurrentProject(project: EnhancedProject) {
    return addDays(project.releasedate, 7) >= new Date();
}

export function isPastProject(project: EnhancedProject) {
    return project.releasedate < pastProjectDate;
}

export function emptyProject(
    obj: RecursivePartial<Project> = {}
): EnhancedProject {
    return enhanceEntity(
        {
            id: getNewId('Project'),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            archived: false,
            name: '',
            releasedate: new Date().toISOString(),
            types: [],
            tags: [],
            ...obj
        } as ProjectJSON,
        { initForm: true, new: true }
    );
}

export function getProjectTypeLabel(type: Type): string {
    return matchType(type, {
        print: () => 'Print',
        online: () => 'Online',
        social: () => 'Social',
        tvradio: () => 'TV & Radio',
        printonline: () => 'Print & Online'
    });
}
