import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import placeholder from './placeholder.svg';
import brokenImage from './broken-image.svg';

interface ImgProps {
    className?: string;
    src: string;
    externalSrc?: string;
    background?: boolean;
    'data-role'?: string;
    loaderStyle?: React.CSSProperties;
    children?: React.ReactNode;
    onLoad?(target: HTMLImageElement): void;
    onStartLoading?(): void;
    onClick?(e: React.SyntheticEvent<HTMLElement>): void;
}

const StyledImg = styled.img`
    &:focus {
        outline: none;
    }
`;

const opacityAnimation = keyframes`
    0%   { opacity: 0.7; }
    50% { opacity: 0.2; }
    100% { opacity: 0.7; }
`;

export const Loader = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    opacity: 0.7;
    background: #f8f8f8 url('${placeholder}') no-repeat center center;
    background-size: contain;
    animation: 3.5s ${opacityAnimation} ease-out
        ${() => Math.round(Math.random() * 10) / 10}s infinite;
`;

const Error = styled.div`
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: #f8f8f8 url('${brokenImage}') no-repeat center center;
    background-size: contain;
`;

export const Img = React.forwardRef(
    (props: ImgProps, ref: React.Ref<HTMLImageElement>) => {
        const [showLoading, setShowLoading] = React.useState(true);
        const { error, loading, stateSrc } = useImageLoad(
            props.src,
            props.onLoad
        );

        React.useEffect(() => {
            try {
                const url1 = new URL(props.src);
                const url2 = new URL(stateSrc);

                const showLoading = url1.pathname !== url2.pathname;

                setShowLoading(showLoading);

                if (showLoading) {
                    props.onStartLoading?.();
                }
            } catch (_e) {
                setShowLoading(true);
                props.onStartLoading?.();
            }
        }, [props.src]);

        function onClick(): void {
            if (props.externalSrc) {
                open(props.externalSrc);
            }
        }

        const onClickHandler = props.onClick ?? onClick;

        if (error) {
            return (
                <Error
                    className={props.className}
                    style={props.loaderStyle}
                    onClick={onClickHandler}
                >
                    {props.children}
                </Error>
            );
        }

        if (loading && showLoading) {
            return (
                <Loader
                    className={props.className}
                    style={props.loaderStyle}
                    onClick={onClickHandler}
                />
            );
        }

        if (props.background) {
            const backgroundImage = stateSrc ? `url(${stateSrc})` : 'none';

            return (
                <div
                    className={props.className}
                    data-role={props['data-role']}
                    onClick={onClickHandler}
                    style={{
                        backgroundImage
                    }}
                >
                    {props.children}
                </div>
            );
        }

        return (
            <StyledImg
                ref={ref}
                className={props.className}
                data-role={props['data-role']}
                src={stateSrc || EMPTY_IMAGE}
                onClick={onClick}
            />
        );
    }
);

const EMPTY_IMAGE =
    'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';

function useImageLoad(
    src: string,
    onLoad?: (target: HTMLImageElement) => void
) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [stateSrc, setStateSrc] = React.useState('');

    React.useEffect(() => {
        let mounted = true;

        setError(false);
        setLoading(false);

        function onImageLoad(e: Event): void {
            if (!mounted) {
                return;
            }

            const image = e.currentTarget as HTMLImageElement;

            setError(false);
            setLoading(false);
            requestAnimationFrame(() => {
                if (!mounted) {
                    return;
                }

                setStateSrc(src);
                onLoad?.(image);
            });
        }

        function onError() {
            setError(true);
            setLoading(false);
        }

        if (src.startsWith('http')) {
            setLoading(true);

            const img = new Image();

            img.addEventListener('load', onImageLoad, false);
            img.addEventListener('error', onError, false);
            img.src = src;

            return () => {
                mounted = false;

                img.removeEventListener('load', onImageLoad);
                img.removeEventListener('error', onError);
                img.src = EMPTY_IMAGE;
            };
        }

        return () => {
            mounted = false;
        };
    }, [src]);

    return { loading, error, stateSrc };
}
