import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { PdfGenerateConfig } from '../models';
import { PdfData } from '../models';
import { ErrorCause } from '../models';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedUser } from './User';

export type EnhancedPdfForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    user: FieldState<EnhancedUser>;
    pdfUrl: FieldState<string | undefined>;
    htmlUrl: FieldState<string | undefined>;
    xlsUrl: FieldState<string | undefined>;
    error: FieldState<string | undefined>;
    errorCause: FieldState<ErrorCause | undefined>;
    baseKey: FieldState<string | undefined>;
    valid: FieldState<boolean | undefined>;
    progress: FieldState<number | undefined>;
    duration: FieldState<number | undefined>;
    estimate: FieldState<number | undefined>;
    config: FieldState<PdfGenerateConfig>;
    data: FieldState<PdfData>;
    executionArn: FieldState<string | undefined>;
    files: FieldState<string[] | undefined>;
    expire: FieldState<number>;
}>;

export class EnhancedPdf extends EnhancedEntity<EnhancedPdfForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public user!: EnhancedUser;
    public pdfUrl!: string | undefined;
    public htmlUrl!: string | undefined;
    public xlsUrl!: string | undefined;
    public error!: string | undefined;
    public errorCause!: ErrorCause | undefined;
    public baseKey!: string | undefined;
    public valid!: boolean | undefined;
    public progress!: number | undefined;
    public duration!: number | undefined;
    public estimate!: number | undefined;
    public config!: PdfGenerateConfig;
    public data!: PdfData;
    public executionArn!: string | undefined;
    public files!: string[] | undefined;
    public expire!: number;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            user: observable,
            pdfUrl: observable,
            htmlUrl: observable,
            xlsUrl: observable,
            error: observable,
            errorCause: observable,
            baseKey: observable,
            valid: observable,
            progress: observable,
            duration: observable,
            estimate: observable,
            config: observable,
            data: observable,
            executionArn: observable,
            files: observable,
            expire: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            user: this.user,
            pdfUrl: this.pdfUrl,
            htmlUrl: this.htmlUrl,
            xlsUrl: this.xlsUrl,
            error: this.error,
            errorCause: this.errorCause,
            baseKey: this.baseKey,
            valid: this.valid,
            progress: this.progress,
            duration: this.duration,
            estimate: this.estimate,
            config: this.config,
            data: this.data,
            executionArn: this.executionArn,
            files: this.files,
            expire: this.expire,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            user: new FieldState<EnhancedUser>(
                new EnhancedUser(this.user, { initForm: true }),
            ),
            pdfUrl: new FieldState<string | undefined>(this.pdfUrl),
            htmlUrl: new FieldState<string | undefined>(this.htmlUrl),
            xlsUrl: new FieldState<string | undefined>(this.xlsUrl),
            error: new FieldState<string | undefined>(this.error),
            errorCause: new FieldState<ErrorCause | undefined>(
                this.errorCause ? { ...this.errorCause } : undefined,
            ),
            baseKey: new FieldState<string | undefined>(this.baseKey),
            valid: new FieldState<boolean | undefined>(this.valid),
            progress: new FieldState<number | undefined>(this.progress),
            duration: new FieldState<number | undefined>(this.duration),
            estimate: new FieldState<number | undefined>(this.estimate),
            config: new FieldState<PdfGenerateConfig>({ ...this.config }),
            data: new FieldState<PdfData>({ ...this.data }),
            executionArn: new FieldState<string | undefined>(this.executionArn),
            files: new FieldState<string[] | undefined>(this.files || []),
            expire: new FieldState<number>(this.expire),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
