import { renderLoader, renderApp } from './app';
import { AppStore } from './app/store';
import { createApiClient } from './aws';
import { injectTSDI } from './core/utils';

function convertLegacyHashToRoute() {
    const { hash } = window.location;
    const route = hash.replace(/^#/, '');

    if (route.startsWith('/')) {
        const search = `${route}${window.location.search}`;

        window.history.replaceState(null, '', search);
    }
}

(async () => {
    createApiClient();
    convertLegacyHashToRoute();
    injectTSDI(AppStore);

    renderLoader();
    renderApp();
})();
