import { useRef } from 'react';
import { Constructable, IComponentOptions, TSDI } from 'tsdi';
import { tsdiInjector } from './tsdi-injector';

export type TsdiDependency<T> = string | Constructable<T>;

export type TsdiScope = ReturnType<typeof TSDI.prototype.getScope>;

function getComponentOptions<T>(
    Dependency: TsdiDependency<T>
): IComponentOptions {
    const componentOptions: IComponentOptions | undefined =
        typeof Dependency !== 'string' &&
        Reflect.getMetadata('component:options', Dependency);

    return componentOptions || {};
}

export function injectTSDI<T>(Dependency: TsdiDependency<T>): T {
    const componentOptions = getComponentOptions(Dependency);

    if (componentOptions.singleton === false) {
        throw new Error(
            'The option { singleton: false } will not work with `injectTSDI`. Please use `useTSDI` when inside a react-component or use the @inject-decorator in classes.'
        );
    }

    return tsdiInjector.get(Dependency);
}

export function useTSDI<T>(Dependency: TsdiDependency<T>): T {
    const dep = tsdiInjector.get(Dependency);
    const depState = useRef(dep);

    const componentOptions = getComponentOptions(Dependency);

    if (componentOptions.singleton === false) {
        return depState.current;
    }

    return dep;
}
