import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedLatestMailForm = FormState<{
    date: FieldState<Date | undefined>;
}>;

export class EnhancedLatestMail extends EnhancedEntity<EnhancedLatestMailForm> {
    public date!: Date | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            date: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { date: this.date };
    }

    public initForm() {
        this.form = new FormState({
            date: new FieldState<Date | undefined>(
                this.date === undefined || this.date === null
                    ? undefined
                    : new Date(this.date),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
