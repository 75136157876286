import { observer } from 'mobx-react';
import * as React from 'react';
import ReactModal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';
import { TSDI } from 'tsdi';
import { injectTSDI } from '../../core/utils';
import { useViewTransitionToggle } from '../../start-transition';
import { Layout } from '../layout';

export type ModalSize = 'XS' | 'S' | 'M' | 'L' | 'FULLSCREEN';

interface ReactModalProps {
    open: boolean;
    scope?: string;
    title?: string;
    className?: string;
    contentLabel?: string;
    size?: ModalSize;
    children?: React.ReactNode;
    noPadding?: boolean;
    onClose(): void;
    onKeyArrow?(type: 'left' | 'right'): void;
    onKeyEsc?(): void;
    onKeyEnter?(): void;
}

export const ModalCSS = createGlobalStyle`
    .ReactModal__Body--open {
        overflow: hidden;
        padding-right: 15px;
    }

    .ReactModal_Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(42,51,60,0.5);
        z-index: 99;
        display: flex;
    }

    .ReactModal {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 30px);
        max-height: calc(100vh - 30px);
        margin: auto;
        -webkit-overflow-scrolling: touch;
        outline: none;
        box-shadow: 0 5px 15px rgba(0,0,0,0.5);
        background-color: #fff;
        background-clip: padding-box;

        &.size-XS {
            width: 300px;
        }

        &.size-S {
            width: 600px;
        }

        &.size-M {
            width: 900px;
        }

        &.size-L {
            width: 1920px;
            height: 1080px;
        }

        &.size-FULLSCREEN {
            width: 100%;
            height: 100%;
        }
    }

    .ReactModal_confirm {
        z-index: 100;
    }
`;

const ModalHeader = styled.header`
    display: flex;
    padding: 15px;
    flex: 0 0 30px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1;
    background: #f9f9f9;
    box-shadow:
        rgba(0, 0, 0, 0.05) 0px 5px 10px 0px,
        rgb(233, 234, 236) 0px -1px 0px 0px inset;
`;

const ModalTitle = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    justify-content: center;
`;

const ModalClose = styled.button`
    border: none;
    background: none;
    font-size: 26px;
    cursor: pointer;
    position: absolute;
    line-height: 1;
    right: 8px;
    top: 7px;

    &:hover {
        color: #3f4d56;
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }
`;

export const Modal = observer((props: ReactModalProps) => {
    const tsdi = injectTSDI(TSDI);
    const {
        open,
        children,
        size,
        scope,
        className = '',
        contentLabel = 'Modal',
        title = ''
    } = props;
    const { visible } = useViewTransitionToggle(open);

    const modalClassName: ReactModal.Classes = {
        base: `ReactModal size-${size || 'L'}`,
        afterOpen: 'ReactModal_afterOpen',
        beforeClose: 'ReactModal_beforeClose'
    };

    const onKeyUp = React.useCallback((e: KeyboardEvent) => {
        if ((e.target as HTMLElement).classList.contains('ReactModal')) {
            const { onKeyArrow, onKeyEsc, onKeyEnter } = props;

            switch (e.keyCode) {
                case 37: // LEFT ARROW
                    if (onKeyArrow) onKeyArrow('left');
                    break;
                case 39: // RIGHT ARROW
                    if (onKeyArrow) onKeyArrow('right');
                    break;
                case 27: // ESC
                    if (onKeyEsc) onKeyEsc();
                    break;
                case 13: // ENTER
                    if (onKeyEnter) onKeyEnter();
                    break;
            }
        }
    }, []);

    React.useEffect(() => {
        if (scope) {
            const tsdiScope = tsdi.getScope(scope);

            if (open) {
                tsdiScope.enter();

                document.addEventListener('keyup', onKeyUp);
            } else {
                tsdiScope.leave();

                document.removeEventListener('keyup', onKeyUp);
            }
        }
    }, [open, scope]);

    return (
        <ReactModal
            className={modalClassName}
            overlayClassName={`ReactModal_Overlay ${className}`}
            isOpen={visible}
            contentLabel={contentLabel}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
        >
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
                <ModalClose data-role="close" onClick={props.onClose}>
                    ×
                </ModalClose>
            </ModalHeader>
            <Layout
                padding={props.noPadding ? 0 : '16'}
                stretch
                style={{ overflow: 'auto' }}
            >
                {children}
            </Layout>
        </ReactModal>
    );
});
