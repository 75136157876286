import {
    AuthorsGetRequest,
    EnhancedMediaGenre,
    EnhancedMediaOnline,
    EnhancedMediaPrint,
    EnhancedMediaReleaseFreq,
    EnhancedMediaSocial,
    EnhancedMediaType,
    EnhancedMediaTypeOnline,
    EnhancedMediaTypeSocial,
    LeadstoriesGetRequest,
    TagsGetRequest,
    Type
} from '@reportroyal/api';
import { makeAutoObservable, reaction } from 'mobx';
import { Option } from 'react-slct';
import { component, initialize } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import { AppStore } from '../app/store';
import {
    listenToWebsocketUpdate,
    loadAuthors,
    loadItemStaticUpdates,
    loadLeadstories,
    loadMediaGenres,
    loadMediaItemsOnline,
    loadMediaItemsPrint,
    loadMediaItemsSocial,
    loadMediaReleaseFreqs,
    loadMediaTypesOnline,
    loadMediaTypesPrint,
    loadMediaTypesSocial,
    loadTags
} from '../aws';
import { CompanyStore } from './company-store';
import { IS_WHITELABEL, injectTSDI } from './utils';

@component
export class MediaStore {
    private get appStore() {
        return injectTSDI(AppStore);
    }

    private get companyStore() {
        return injectTSDI(CompanyStore);
    }

    public types: Type[] = [];

    public customTypesOptions: Option<string>[] = [];

    public printMedia = wrapRequest(loadMediaItemsPrint, {
        defaultData: [],
        cacheKey: 'media-print'
    });

    public onlineMedia = wrapRequest(loadMediaItemsOnline, {
        defaultData: [],
        cacheKey: 'media-online'
    });

    public socialMedia = wrapRequest(loadMediaItemsSocial, {
        defaultData: [],
        cacheKey: 'media-social'
    });

    public printMediaTypes = wrapRequest(loadMediaTypesPrint, {
        defaultData: [],
        cacheKey: 'media-print-types'
    });

    public onlineMediaTypes = wrapRequest(loadMediaTypesOnline, {
        defaultData: [],
        cacheKey: 'media-online-types'
    });

    public socialMediaTypes = wrapRequest(loadMediaTypesSocial, {
        defaultData: [],
        cacheKey: 'media-social-types'
    });

    private genres = wrapRequest(loadMediaGenres, {
        defaultData: [],
        cacheKey: 'media-genres'
    });

    public releaseFreq = wrapRequest(loadMediaReleaseFreqs, {
        defaultData: [],
        cacheKey: 'media-release-freq'
    });

    public tags = wrapRequest(
        async (params?: TagsGetRequest) =>
            IS_WHITELABEL() ? ([] as string[]) : loadTags(params),
        {
            defaultData: [],
            cacheKey: 'media-tags'
        }
    ).pipe((tags) => {
        return tags
            .map((tag) => {
                const update = this.tagUpdates.$.find(
                    ({ oldValue }) => oldValue === tag
                );

                return update?.newValue ?? tag;
            })
            .filter(Boolean);
    });

    public authors = wrapRequest(
        async (params?: AuthorsGetRequest) =>
            IS_WHITELABEL() ? ([] as string[]) : loadAuthors(params),
        { defaultData: [] }
    ).pipe((authors) => {
        return authors
            .map((author) => {
                const authorUpdate = this.authorUpdates.$.find(
                    ({ oldValue }) => oldValue === author
                );

                return authorUpdate?.newValue ?? author;
            })
            .filter(Boolean);
    });

    public leadstories = wrapRequest(
        async (params?: LeadstoriesGetRequest) =>
            IS_WHITELABEL() ? ([] as string[]) : loadLeadstories(params),
        {
            defaultData: [],
            cacheKey: 'media-leadstories'
        }
    ).pipe((leadstories) => {
        return leadstories
            .map((leadstory) => {
                const leadstoryUpdae = this.leadstoryUpdates.$.find(
                    ({ oldValue }) => oldValue === leadstory
                );

                return leadstoryUpdae?.newValue ?? leadstory;
            })
            .filter(Boolean);
    });

    public authorUpdates = wrapRequest(
        () => loadItemStaticUpdates({ type: 'Author' }),
        { defaultData: [] }
    );

    public tagUpdates = wrapRequest(
        () => loadItemStaticUpdates({ type: 'Tag' }),
        { defaultData: [] }
    );

    public leadstoryUpdates = wrapRequest(
        () => loadItemStaticUpdates({ type: 'Leadstory' }),
        { defaultData: [] }
    );

    @initialize
    public init(): void {
        makeAutoObservable(this);

        reaction(
            () => this.types,
            async (types: Type[]) => {
                if (!this.appStore.loggedIn) {
                    return;
                }

                if (types.includes('print') || types.includes('printonline')) {
                    await this.printMediaTypes.request();
                    await this.printMedia.request();
                }

                if (
                    types.includes('online') ||
                    types.includes('tvradio') ||
                    types.includes('printonline')
                ) {
                    await this.onlineMediaTypes.request();
                    await this.onlineMedia.request();
                }

                if (types.includes('social')) {
                    await this.socialMediaTypes.request();
                    await this.socialMedia.request();
                }
            },
            { fireImmediately: true }
        );

        reaction(
            () => this.appStore.loggedIn,
            (loggedIn) => {
                if (loggedIn) {
                    this.genres.request();
                }
            },
            { fireImmediately: true }
        );

        listenToWebsocketUpdate({
            path: '/authors',
            callback: () => {
                void this.authors.request();
                void this.authorUpdates.request();
            }
        });
        listenToWebsocketUpdate({
            path: '/leadstories',
            callback: () => {
                void this.leadstories.request();
                void this.leadstoryUpdates.request();
            }
        });
        listenToWebsocketUpdate({
            path: '/tags',
            callback: () => {
                void this.tags.request();
                void this.tagUpdates.request();
            }
        });
    }

    public reset() {
        this.printMedia.reset([]);
        this.onlineMedia.reset([]);
        this.printMediaTypes.reset([]);
        this.onlineMediaTypes.reset([]);
        this.socialMediaTypes.reset([]);
        this.genres.reset([]);
        this.releaseFreq.reset([]);
        this.tags.reset([]);
        this.authors.reset([]);
        this.leadstories.reset([]);
        this.authorUpdates.reset([]);
        this.tagUpdates.reset([]);
        this.leadstoryUpdates.reset([]);

        this.types = [];
    }

    public getPrintMedia(id: string): EnhancedMediaPrint | undefined {
        return this.printMedia.$.find((item) => item.id === id);
    }

    public getOnlineMedia(id: string): EnhancedMediaOnline | undefined {
        return this.onlineMedia.$.find((item) => item.id === id);
    }

    public getGenre(id: string): EnhancedMediaGenre | undefined {
        return this.genres.$.find((item) => item.id === id);
    }

    public get printMediaOptions(): (Option<EnhancedMediaPrint> & {
        temp?: boolean;
        group?: string;
    })[] {
        return this.printMedia.$.filter((media) => media.active).map(
            (media) => ({
                value: media,
                label: media.title,
                temp: media.temp,
                group: media.group
            })
        );
    }

    public get printMediaGroups() {
        return [
            ...this.printMedia.$.filter((media) => media.group).reduce(
                (memo, media) => {
                    if (media.group) {
                        memo.add(media.group);
                    }

                    return memo;
                },
                new Set<string>()
            )
        ];
    }

    public get printMediaTypeOptions(): Option<EnhancedMediaType>[] {
        return this.printMediaTypes.$.map((media) => ({
            value: media,
            label: media.title
        }));
    }

    public get onlineMediaOptions(): Option<EnhancedMediaOnline>[] {
        return this.onlineMedia.$.filter((media) => media.active).map(
            (media) => ({
                value: media,
                label: media.title
            })
        );
    }

    public get socialMediaOptions(): Option<EnhancedMediaSocial>[] {
        return this.socialMedia.$.filter((media) => media.active).map(
            (media) => ({
                value: media,
                label: media.title
            })
        );
    }

    public get onlineMediaTypeOptions(): Option<EnhancedMediaTypeOnline>[] {
        return this.onlineMediaTypes.$.map((media) => ({
            value: media,
            label: media.title
        }));
    }

    public get socialMediaTypeOptions(): Option<EnhancedMediaTypeSocial>[] {
        return this.socialMediaTypes.$.map((media) => ({
            value: media,
            label: media.title
        }));
    }

    public get genreOptions(): Option<EnhancedMediaGenre>[] {
        /** Special case: Set genre `Review` as first element */
        const genreOptions = this.genres.$.map<Option<EnhancedMediaGenre>>(
            (genre) => ({
                value: genre,
                label: genre.name
            })
        );
        const filteredGenreOptions = genreOptions.filter(
            (option) => option.label !== 'Review'
        );
        const reviewOption = genreOptions.find(
            (option) => option.label === 'Review'
        );

        if (reviewOption) {
            filteredGenreOptions.unshift(reviewOption);
        }

        return filteredGenreOptions;
    }

    public get releaseFreqOptions(): Option<EnhancedMediaReleaseFreq>[] {
        return this.releaseFreq.$.map((item) => ({
            value: item,
            label: item.name
        }));
    }

    public get authorOptions(): Option<string>[] {
        return this.authors.$.map((author) => ({
            value: author,
            label: author
        }));
    }

    public get leadstoryOptions(): Option<string>[] {
        return this.leadstories.$.map((story) => ({
            value: story,
            label: story
        }));
    }

    public get tagOptions(): Option<string>[] {
        return this.tags.$.map((tag) => ({
            value: tag,
            label: tag
        }));
    }

    public setCustomerTypes(companyId?: string) {
        if (
            process.env.NODE_ENV === 'development' &&
            this.companyStore.isStudioCanal(companyId)
        ) {
            this.customTypesOptions = [
                { label: 'Magazines', value: 'Magazines' },
                { label: 'Newspaper', value: 'Newspaper' },
                { label: 'Weekend Paper', value: 'Weekend Paper' },
                { label: 'TV', value: 'TV' },
                { label: 'Online', value: 'Online' },
                { label: 'Radio', value: 'Radio' }
            ];
        } else {
            this.customTypesOptions = [];
        }
    }
}
