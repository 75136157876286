import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedStatsOverviewRatingsForm = FormState<{
    positive: FieldState<number>;
    neutral: FieldState<number>;
    negative: FieldState<number>;
    mixed: FieldState<number>;
    positiveMixed: FieldState<number>;
    negativeMixed: FieldState<number>;
}>;

export class EnhancedStatsOverviewRatings extends EnhancedEntity<EnhancedStatsOverviewRatingsForm> {
    public positive!: number;
    public neutral!: number;
    public negative!: number;
    public mixed!: number;
    public positiveMixed!: number;
    public negativeMixed!: number;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            positive: observable,
            neutral: observable,
            negative: observable,
            mixed: observable,
            positiveMixed: observable,
            negativeMixed: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            positive: this.positive,
            neutral: this.neutral,
            negative: this.negative,
            mixed: this.mixed,
            positiveMixed: this.positiveMixed,
            negativeMixed: this.negativeMixed,
        };
    }

    public initForm() {
        this.form = new FormState({
            positive: new FieldState<number>(this.positive),
            neutral: new FieldState<number>(this.neutral),
            negative: new FieldState<number>(this.negative),
            mixed: new FieldState<number>(this.mixed),
            positiveMixed: new FieldState<number>(this.positiveMixed),
            negativeMixed: new FieldState<number>(this.negativeMixed),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
