import { EnhancedNotification, enhanceEntity } from '@reportroyal/api';
import { client } from './api';

export async function loadNotifications(): Promise<EnhancedNotification[]> {
    const items = await client.notificationsGet({});

    return items.map(item => enhanceEntity(item));
}

export async function deleteNotification(id: string): Promise<void> {
    await client.notificationDelete({ id });
}

export async function deleteNotifications(): Promise<void> {
    await client.notificationsDelete();
}
