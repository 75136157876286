import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { injectTSDI } from '../../core/utils';
import { Responsive } from './responsive';

type sizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface ColumnProps {
    xs?: sizes;
    sm?: sizes;
    md?: sizes;
    lg?: sizes;
    xl?: sizes;
    between?: 10 | 20;
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

interface ColumnPropsWithResponsive extends ColumnProps {
    responsivesizes: Responsive;
}

function calcSizes(props: ColumnPropsWithResponsive): any {
    const { sizeXs, sizeSm, sizeMd, sizeLg, sizeXl } = props.responsivesizes;

    const xs = props.xs || props.sm || props.md || props.lg || 12;
    const sm = props.sm || xs;
    const md = props.md || sm;
    const lg = props.lg || md;
    const xl = props.xl || lg;

    return css<ColumnPropsWithResponsive>`
        padding: ${({ between = 20 }) => `0 ${between / 2}px`};
        margin-bottom: ${({ between = 20 }) => `${between}px`};

        @media (max-width: ${sizeXs.max}px) {
            width: ${(100 / 12) * xs}%;
        }

        @media (min-width: ${sizeSm.min}px) AND (max-width: ${sizeSm.max}px) {
            width: ${(100 / 12) * sm}%;
        }

        @media (min-width: ${sizeMd.min}px) AND (max-width: ${sizeMd.max}px) {
            width: ${(100 / 12) * md}%;
        }

        @media (min-width: ${sizeLg.min}px) AND (max-width: ${sizeLg.max}px) {
            width: ${(100 / 12) * lg}%;
        }

        @media (min-width: ${sizeXl.min}px) {
            width: ${(100 / 12) * xl}%;
        }
    `;
}

const Col = styled.div<ColumnPropsWithResponsive>`
    display: flex;
    ${(props) => calcSizes(props)};
`;

export const Column = observer((props: ColumnProps) => {
    const sizes = injectTSDI(Responsive);

    return <Col {...props} responsivesizes={sizes} />;
});

export const ColumnContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
`;

ColumnContainer.displayName = 'ColumnContainer';
