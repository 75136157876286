import * as React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'takeme';

type Target = '_blank' | '_self';

interface LinkProps<T = any> {
    value?: T;
    href?: string;
    download?: string;
    target?: Target;
    title?: string;
    underline?: boolean;
    'data-role'?: string;
    disabled?: boolean;
    children: React.ReactNode;
    onClick?(value: T, e: React.MouseEvent<HTMLAnchorElement>): void;
}

const StyledLink = styled.a<{ disabled?: boolean; underline?: boolean }>`
    text-decoration: ${(props) =>
        props.underline ? 'underline !important' : 'none'};

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
        `}

    &:hover {
        color: #000;
        text-decoration: underline;
    }

    &:focus {
        outline: none;
    }
`;

export function Link(props: LinkProps) {
    const {
        href = '#',
        target = '_self',
        underline,
        download,
        title,
        disabled,
        children
    } = props;

    function onClick(e: React.MouseEvent<HTMLAnchorElement>) {
        if (props.onClick) {
            if (!href || href === '#') {
                e.preventDefault();
            }

            props.onClick(props.value, e);
        }

        if (href?.startsWith('/')) {
            e.preventDefault();
            navigate(href, false);
        }
    }

    return (
        <StyledLink
            href={href}
            target={target}
            underline={underline}
            download={download}
            data-role={props['data-role']}
            title={title}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </StyledLink>
    );
}
