import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { ItemStaticType } from '../schema';

export type EnhancedItemStaticUpdateForm = FormState<{
    type: FieldState<ItemStaticType>;
    oldValue: FieldState<string>;
    newValue: FieldState<string | undefined>;
}>;

export class EnhancedItemStaticUpdate extends EnhancedEntity<EnhancedItemStaticUpdateForm> {
    public type!: ItemStaticType;
    public oldValue!: string;
    public newValue!: string | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            type: observable,
            oldValue: observable,
            newValue: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            type: this.type,
            oldValue: this.oldValue,
            newValue: this.newValue,
        };
    }

    public initForm() {
        this.form = new FormState({
            type: new FieldState<ItemStaticType>(this.type),
            oldValue: new FieldState<string>(this.oldValue),
            newValue: new FieldState<string | undefined>(this.newValue),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
