import { Rating, colors } from '@reportroyal/api';
import { Option } from 'react-slct';

export const ratingOptions: Option<Rating>[] = [
    {
        value: 'negative',
        label: 'negative',
        color: colors.NEGATIVE
    },
    {
        value: 'positive',
        label: 'positive',
        color: colors.POSITIVE
    },
    {
        value: 'neutral',
        label: 'neutral',
        color: colors.NEUTRAL
    },
    {
        value: 'mixed',
        label: 'mixed',
        color: colors.MIXED
    },
    {
        value: 'positive-mixed',
        label: 'positive-mixed',
        color: colors.POSITIVE_MIXED
    },
    {
        value: 'negative-mixed',
        label: 'negative-mixed',
        color: colors.NEGATIVE_MIXED
    }
];
