import {
    EnhancedMail,
    enhanceEntity,
    LatestMail,
    MailLatestGetRequest,
    MailNotificationsConfig,
    MailNotificationsUnsubscribePutRequest,
    MailQueueConfig,
    MailsGetRequest,
    EnhancedEntityOptions
} from '@reportroyal/api';
import { client } from './api';

export async function sendNotificationMails(
    config: Omit<MailNotificationsConfig, 'NODE_ENV'>
) {
    return client.mailNotificationsPost({
        mailNotificationsConfig: { ...config, NODE_ENV: process.env.NODE_ENV }
    });
}

export async function latestMail(
    options: MailLatestGetRequest
): Promise<LatestMail> {
    const resp = await client.mailLatestGet(options);

    return { date: resp.date ? new Date(resp.date) : undefined };
}

export async function loadMails(
    params: MailsGetRequest,
    config?: EnhancedEntityOptions
) {
    const mails = await client.mailsGet(params);

    return mails.map<EnhancedMail>((item) => enhanceEntity(item, config));
}

export async function mailNotificationsUnsubscribe(
    config: MailNotificationsUnsubscribePutRequest
) {
    return client.mailNotificationsUnsubscribePut(config);
}

export async function processMailQueue(options: { uuid: string }) {
    return client.mailQueueProcessPost(options);
}

export async function queueMail(config: MailQueueConfig) {
    return client.mailQueuePost({ mailQueueConfig: config });
}
