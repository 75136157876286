import { Tag, Type } from '@reportroyal/api';
import * as React from 'react';
import styled from 'styled-components';
import { Tagtool, Layout, getTagColor, THRESHOLD } from '../components';

interface ModalCropProps {
    width: number;
    height: number;
    url?: string;
    tag?: Tag;
    type: Type;
    onUpdate(tag?: Tag): void;
}

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Image = styled.img`
    position: relative;
    left: ${THRESHOLD}px;
    top: ${THRESHOLD}px;
`;

const AbsoluteTagtool = styled(Tagtool)`
    position: absolute;
    left: 0;
    top: 0;
`;

const Clip = styled.div`
    position: relative;
    margin: 10px 10px 0 20px;
    background-repeat: no-repeat;
    overflow: hidden;
`;

const XAxis = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 2px;
    background: #fff;
    border-right: 1px solid #000;
`;

const YAxis = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #fff;
    border-bottom: 1px solid #000;
`;

const MagniTag = styled.div`
    position: absolute;
    background: ${getTagColor(0.7)};
    left: 50%;
    top: 50%;
`;

export function ModalCrop(props: ModalCropProps) {
    const [tag, setTag] = React.useState<Tag | undefined>(props.tag);
    const [clip, setClip] = React.useState<Tag | undefined>(undefined);
    const [magniTag, setMagniTag] = React.useState<Tag | undefined>(undefined);
    const [containerSize, setContainerSize] = React.useState({
        width: 0,
        height: 0
    });
    const [zoom, setZoom] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);

    const onUpdate = React.useCallback((tag?: Tag) => {
        setTag(tag);
        props.onUpdate(tag);
    }, []);

    React.useEffect(() => {
        let mounted = true;

        setTimeout(() => {
            if (mounted && ref.current) {
                setContainerSize({
                    width: ref.current.clientWidth - 2 * THRESHOLD,
                    height: ref.current.clientHeight - 2 * THRESHOLD
                });
            }
        }, 800);

        return () => {
            mounted = false;
        };
    }, [ref.current]);

    React.useEffect(() => {
        if (containerSize.width && containerSize.height) {
            const zoom = Math.max(
                0.1,
                Math.min(
                    1,
                    containerSize.width > containerSize.height
                        ? containerSize.height / props.height
                        : containerSize.width / props.width
                )
            );

            setZoom(zoom);
        }
    }, [containerSize]);

    const axisX = Math.min(clip?.x || 0, 0);
    const axisY = Math.min(clip?.y || 0, 0);

    function onEnterFrame(clip?: Tag, currentTag?: Tag) {
        setClip(clip);
        setMagniTag(currentTag);
    }

    return (
        <Layout style={{ height: '100%', position: 'relative' }}>
            <ImageContainer ref={ref}>
                <Image
                    src={props.url}
                    width={props.width * zoom}
                    height={props.height * zoom}
                />
                <AbsoluteTagtool
                    imageLoaded
                    isCropping
                    tag={tag}
                    width={props.width}
                    height={props.height}
                    zoom={zoom}
                    onUpdate={onUpdate}
                    onEnterFrame={onEnterFrame}
                />
            </ImageContainer>
            <div style={{ flex: 1 }}>
                {clip && (
                    <Clip
                        style={{
                            backgroundImage: `url(${props.url})`,
                            // prettier-ignore
                            backgroundPosition: `-${Math.max(clip.x, 0)}px -${Math.max(clip.y, 0)}px`,
                            width: clip.width,
                            height: clip.height
                        }}
                    >
                        {magniTag && (
                            <MagniTag
                                style={{
                                    width: magniTag.width,
                                    height: magniTag.height,
                                    // prettier-ignore
                                    transform: `translate(${magniTag.x + axisX}px, ${magniTag.y + axisY}px)`
                                }}
                            />
                        )}
                        <XAxis
                            style={{
                                transform: `translateX(${axisX}px)`
                            }}
                        />
                        <YAxis
                            style={{
                                transform: `translateY(${axisY}px)`
                            }}
                        />
                    </Clip>
                )}
            </div>
        </Layout>
    );
}
