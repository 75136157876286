import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { EnhancedFile } from '../../entities/enhanced-file';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedMediaOnline } from './MediaOnline';

export type EnhancedURLCacheForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    headline: FieldState<string>;
    quote: FieldState<string | undefined>;
    media: FieldState<EnhancedMediaOnline | undefined>;
    lang: FieldState<string | undefined>;
    url: FieldState<string>;
    ref: FieldState<string | undefined>;
    image: FieldState<EnhancedFile | undefined>;
    expire: FieldState<number | undefined>;
}>;

export class EnhancedURLCache extends EnhancedEntity<EnhancedURLCacheForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public headline!: string;
    public quote!: string | undefined;
    public media!: EnhancedMediaOnline | undefined;
    public lang!: string | undefined;
    public url!: string;
    public ref!: string | undefined;
    public image!: EnhancedFile | undefined;
    public expire!: number | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            headline: observable,
            quote: observable,
            media: observable,
            lang: observable,
            url: observable,
            ref: observable,
            image: observable,
            expire: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            headline: this.headline,
            quote: this.quote,
            media: this.media,
            lang: this.lang,
            url: this.url,
            ref: this.ref,
            image: this.image,
            expire: this.expire,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            headline: new FieldState<string>(this.headline),
            quote: new FieldState<string | undefined>(this.quote),
            media: new FieldState<EnhancedMediaOnline | undefined>(
                this.media === undefined || this.media === null
                    ? undefined
                    : new EnhancedMediaOnline(this.media, { initForm: true }),
            ),
            lang: new FieldState<string | undefined>(this.lang),
            url: new FieldState<string>(this.url),
            ref: new FieldState<string | undefined>(this.ref),
            image: new FieldState<EnhancedFile | undefined>(
                this.image === undefined || this.image === null
                    ? undefined
                    : new EnhancedFile(this.image),
            ),
            expire: new FieldState<number | undefined>(this.expire),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
