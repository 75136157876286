import { EnhancedMediaPrint, EnhancedFile } from '@reportroyal/api';

function getDateFromPmgDate(pmgDate: string): Date {
    const [day, month, year] = pmgDate.split('.');

    return new Date(`${year}-${month}-${day}Z`);
}

export async function getPropsFromPmgTitle(
    files: EnhancedFile[],
    allMedia: EnhancedMediaPrint[]
) {
    const PMG_MASK = /(\w+)-(\d{2}\.\d{2}.\d{4})-/;
    const result: {
        media: EnhancedMediaPrint | undefined;
        date: Date | undefined;
    } = {
        media: undefined,
        date: undefined
    };

    await Promise.all(
        files.map(async image => {
            await image.loadMetadata();

            const { title } = image.headers;

            if (title) {
                const match = title.match(PMG_MASK);

                if (match && match.length) {
                    const [, shortcut, pmgDate] = match;

                    const media = allMedia.find(item =>
                        (item.pmgShortcut || []).some(s => s === shortcut)
                    );
                    const date = getDateFromPmgDate(pmgDate);

                    if (media) {
                        result.media = media;
                    }

                    if (date) {
                        result.date = date;
                    }
                }
            }
        })
    );

    return result;
}
