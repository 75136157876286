import debounce from 'lodash.debounce';
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface SizeProps extends DOMRect {
    scrollWidth: number;
    scrollHeight: number;
}

function getCalcFunction(
    ref: React.RefObject<HTMLElement>,
    setRect: React.Dispatch<React.SetStateAction<SizeProps | undefined>>
) {
    return debounce(() => {
        if (ref.current) {
            const size = ref.current.getBoundingClientRect() as SizeProps;
            size.scrollWidth = ref.current.scrollWidth;
            size.scrollHeight = ref.current.scrollHeight;
            setRect(size);
        }
    }, 0);
}

export function useSize(
    ref: React.RefObject<HTMLElement>,
    // tslint:disable-next-line:no-any
    deps: any[] = []
): SizeProps | undefined {
    const [rect, setRect] = React.useState<SizeProps | undefined>(undefined);

    const calc = React.useCallback(getCalcFunction(ref, setRect), []);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        const rObserver = new ResizeObserver(calc);
        const mObserver = new MutationObserver(calc);

        rObserver.observe(ref.current);
        mObserver.observe(ref.current, {
            childList: true
        });

        window.addEventListener('resize', calc);

        calc();

        return () => {
            mObserver.disconnect();
            rObserver.disconnect();
            window.removeEventListener('resize', calc);
        };
    }, [ref.current, ...deps]);

    return rect;
}
