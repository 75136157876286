import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { MailType } from '../schema';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedUser } from './User';
import { EnhancedProject } from './Project';

export type EnhancedMailForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    uuid: FieldState<string>;
    type: FieldState<MailType>;
    messageId: FieldState<string | undefined>;
    user: FieldState<EnhancedUser>;
    error: FieldState<string | undefined>;
    to: FieldState<string>;
    from: FieldState<EnhancedUser>;
    subject: FieldState<string>;
    bodyHTML: FieldState<string>;
    bodyText: FieldState<string>;
    projects: FieldState<EnhancedProject[]>;
    expire: FieldState<number>;
}>;

export class EnhancedMail extends EnhancedEntity<EnhancedMailForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public uuid!: string;
    public type!: MailType;
    public messageId!: string | undefined;
    public user!: EnhancedUser;
    public error!: string | undefined;
    public to!: string;
    public from!: EnhancedUser;
    public subject!: string;
    public bodyHTML!: string;
    public bodyText!: string;
    public projects!: EnhancedProject[];
    public expire!: number;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            uuid: observable,
            type: observable,
            messageId: observable,
            user: observable,
            error: observable,
            to: observable,
            from: observable,
            subject: observable,
            bodyHTML: observable,
            bodyText: observable,
            projects: observable,
            expire: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            uuid: this.uuid,
            type: this.type,
            messageId: this.messageId,
            user: this.user,
            error: this.error,
            to: this.to,
            from: this.from,
            subject: this.subject,
            bodyHTML: this.bodyHTML,
            bodyText: this.bodyText,
            projects: this.projects,
            expire: this.expire,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            uuid: new FieldState<string>(this.uuid),
            type: new FieldState<MailType>(this.type),
            messageId: new FieldState<string | undefined>(this.messageId),
            user: new FieldState<EnhancedUser>(
                new EnhancedUser(this.user, { initForm: true }),
            ),
            error: new FieldState<string | undefined>(this.error),
            to: new FieldState<string>(this.to),
            from: new FieldState<EnhancedUser>(
                new EnhancedUser(this.from, { initForm: true }),
            ),
            subject: new FieldState<string>(this.subject),
            bodyHTML: new FieldState<string>(this.bodyHTML),
            bodyText: new FieldState<string>(this.bodyText),
            projects: new FieldState<EnhancedProject[]>(
                (this.projects || []).map(
                    (item) => new EnhancedProject(item, { initForm: true }),
                ),
            ),
            expire: new FieldState<number>(this.expire),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
