export function sortByProp<T = any>(a: T, b: T, prop: string) {
    let propA = a[prop];
    let propB = b[prop];

    if (typeof propA === 'string') {
        propA = propA.toLowerCase();
    }

    if (typeof propB === 'string') {
        propB = propB.toLowerCase();
    }

    return propA < propB ? -1 : propA > propB ? 1 : 0;
}

export function wait(timeout = 300) {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}

export function pack<T = any>(items: T[], size = 100) {
    const numPackages = items.length / size;
    const packages: T[][] = [];

    for (let i = 0; i < numPackages; i++) {
        const idPackage = items.slice(i * size, i * size + size);

        packages.push(idPackage);
    }

    return packages;
}
