import Konva from 'konva';
import { IRect } from 'konva/lib/types';
import * as React from 'react';
import { Transformer as ReactKonvaTransformer } from 'react-konva';
import { THRESHOLD } from '.';

interface TransformProps {
    selectedShapeName?: string;
    stageSize: { width: number; height: number };
}

interface Box extends IRect {
    rotation: number;
}

export function Transformer(props: TransformProps) {
    const transformer = React.useRef<Konva.Transformer>(null);

    function checkNode() {
        const { current: transformerRef } = transformer;

        if (!transformerRef) {
            return;
        }

        const stage = transformerRef.getStage()!;
        const { selectedShapeName } = props;

        if (!selectedShapeName) {
            try {
                transformerRef.detach();
            } catch (e) {}
            return;
        }

        const selectedNode = stage.findOne('.' + selectedShapeName);

        if (selectedNode === transformerRef.getNode()) {
            return;
        }

        if (selectedNode) {
            transformerRef.nodes([selectedNode]);
        } else {
            transformerRef.detach();
        }

        transformerRef.getLayer()!.batchDraw();
    }

    function onBoundBoxFunc(oldBoundBox: Box, newBoundBox: Box): Box {
        const { width: stageWidth, height: stageHeight } = props.stageSize;
        const newBoundBoxX = newBoundBox.x || 0;
        const newBoundBoxY = newBoundBox.y || 0;
        const newBoundBoxWidth = newBoundBox.width || 0;
        const newBoundBoxHeight = newBoundBox.height || 0;
        const oldBoundBoxX = oldBoundBox.x || 0;
        const oldBoundBoxY = oldBoundBox.y || 0;

        if (newBoundBoxX < THRESHOLD) {
            newBoundBox.x = THRESHOLD;
        }

        if (newBoundBoxY < THRESHOLD) {
            newBoundBox.y = THRESHOLD;
        }

        if (newBoundBoxX + newBoundBoxWidth >= stageWidth + THRESHOLD) {
            newBoundBox.width = stageWidth - oldBoundBoxX + THRESHOLD;
        }

        if (newBoundBoxY + newBoundBoxHeight >= stageHeight + THRESHOLD) {
            newBoundBox.height = stageHeight - oldBoundBoxY + THRESHOLD;
        }

        return newBoundBox;
    }

    React.useLayoutEffect(() => checkNode());

    return (
        <ReactKonvaTransformer
            boundBoxFunc={onBoundBoxFunc}
            rotateEnabled={false}
            keepRatio={false}
            anchorFill="#000000"
            anchorStroke="#000000"
            ref={transformer}
        />
    );
}
