import { Approval } from '@reportroyal/api';
import * as React from 'react';
import styled from 'styled-components';
import { Button } from '../button';
import { Icon } from '../icon';
import { Layout } from '../layout';

interface ItemEditFooterProps {
    isNew: boolean;
    approvable: boolean;
    approval?: Approval;
    guest: boolean;
    worker: boolean;
    current: number;
    total: number;
    saving: boolean;
    onDelete(): void;
    onDuplicate?(): void;
    onPage(type: 'prev' | 'next'): void;
    onApprove(): void;
    onApproveAndDuplicate(): void;
    onSave(): void;
}

const Footer = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    max-width: calc(100vw - 30px);
    background: #f9f9f9;
    padding: 16px;
    margin: -16px;
`;

const PagingContainer = styled(Layout)<{ guest?: boolean }>`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: ${(props) => (props.guest ? 'static' : 'absolute')};
`;

export const ItemEditFooter = (props: ItemEditFooterProps) => {
    const {
        isNew,
        approval,
        approvable,
        guest,
        worker,
        current,
        onApprove,
        onApproveAndDuplicate,
        onDelete,
        onDuplicate,
        saving,
        onSave,
        total
    } = props;

    function renderSubmit() {
        const className = saving ? 'loading' : '';

        if (guest) {
            return null;
        }

        return (
            <Button
                className={className}
                submit
                active
                loading={saving}
                disabled={saving}
                onClick={onSave}
            >
                Save
            </Button>
        );
    }

    return (
        <Footer>
            {!isNew && Boolean(current && total) && (
                <PagingContainer
                    valign="center"
                    halign="center"
                    between="16"
                    guest={guest}
                >
                    <Button
                        borderless
                        transparent
                        disabled={current === 1}
                        onClick={() => props.onPage('prev')}
                    >
                        <Icon type="arrow-left" />
                    </Button>
                    <Layout valign="center">
                        {current} / {total}
                    </Layout>
                    <Button
                        borderless
                        transparent
                        disabled={current === total}
                        onClick={() => props.onPage('next')}
                    >
                        <Icon type="arrow-right" />
                    </Button>
                </PagingContainer>
            )}
            <Layout valign="center" between="16">
                {!isNew && worker && (
                    <>
                        <Button data-role="delete-clipping" onClick={onDelete}>
                            Delete
                        </Button>
                        {onDuplicate && (
                            <Button
                                data-role="duplicate-clipping"
                                onClick={onDuplicate}
                            >
                                Duplicate
                            </Button>
                        )}
                    </>
                )}
            </Layout>
            <Layout between="16" valign="center">
                {!guest &&
                    approval !== 'live' && [
                        <Button
                            key="approve2"
                            data-role="approve-and-duplicate-clipping"
                            submit
                            title={
                                approvable
                                    ? undefined
                                    : 'Please select at least a media, mediatype, rating and tag all the images.'
                            }
                            disabled={saving || !approvable}
                            onClick={onApproveAndDuplicate}
                        >
                            Approve & Duplicate
                        </Button>,
                        <Button
                            key="approve"
                            data-role="approve-clipping"
                            submit
                            title={
                                approvable
                                    ? undefined
                                    : 'Please select at least a media, mediatype, rating and tag all the images.'
                            }
                            disabled={saving || !approvable}
                            onClick={onApprove}
                        >
                            Approve
                        </Button>
                    ]}
                {renderSubmit()}
            </Layout>
        </Footer>
    );
};
