import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { MailType } from '../schema';

export type EnhancedMailQueueConfigForm = FormState<{
    uuid: FieldState<string>;
    type: FieldState<MailType>;
    to: FieldState<string>;
    subject: FieldState<string>;
    content: FieldState<string>;
    userId: FieldState<string>;
    retries: FieldState<number | undefined>;
    projects: FieldState<string[]>;
}>;

export class EnhancedMailQueueConfig extends EnhancedEntity<EnhancedMailQueueConfigForm> {
    public uuid!: string;
    public type!: MailType;
    public to!: string;
    public subject!: string;
    public content!: string;
    public userId!: string;
    public retries!: number | undefined;
    public projects!: string[];

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            uuid: observable,
            type: observable,
            to: observable,
            subject: observable,
            content: observable,
            userId: observable,
            retries: observable,
            projects: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            uuid: this.uuid,
            type: this.type,
            to: this.to,
            subject: this.subject,
            content: this.content,
            userId: this.userId,
            retries: this.retries,
            projects: this.projects,
        };
    }

    public initForm() {
        this.form = new FormState({
            uuid: new FieldState<string>(this.uuid),
            type: new FieldState<MailType>(this.type),
            to: new FieldState<string>(this.to),
            subject: new FieldState<string>(this.subject),
            content: new FieldState<string>(this.content),
            userId: new FieldState<string>(this.userId),
            retries: new FieldState<number | undefined>(this.retries),
            projects: new FieldState<string[]>(this.projects || []),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
