import { component } from 'tsdi';

export const sizeXs = {
    max: 479
};

export const sizeSm = {
    min: sizeXs.max + 1,
    max: 767
};

export const sizeMd = {
    min: sizeSm.max + 1,
    max: 1199
};

export const sizeLg = {
    min: sizeMd.max + 1,
    max: 1599
};

export const sizeXl = {
    min: sizeLg.max + 1
};

export const sizeApp = {
    max: 1560
};

@component
export class Responsive {
    public sizeXs = sizeXs;
    public sizeSm = sizeSm;
    public sizeMd = sizeMd;
    public sizeLg = sizeLg;
    public sizeXl = sizeXl;
    public sizeApp = sizeApp;
}
