import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { MediaItemMediaSocial } from '../schema';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedMediaTypeSocial } from './MediaTypeSocial';

export type EnhancedMediaSocialForm = FormState<{
    id: FieldState<string>;
    type: FieldState<MediaItemMediaSocial>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    title: FieldState<string>;
    url: FieldState<string>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    relevance: FieldState<number>;
    active: FieldState<boolean>;
    mediaType: FieldState<EnhancedMediaTypeSocial>;
}>;

export class EnhancedMediaSocial extends EnhancedEntity<EnhancedMediaSocialForm> {
    /* public id!: string; */
    public type!: MediaItemMediaSocial;
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public title!: string;
    public url!: string;
    public createdAt!: Date;
    public updatedAt!: Date;
    public relevance!: number;
    public active!: boolean;
    public mediaType!: EnhancedMediaTypeSocial;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            type: observable,
            version: observable,
            title: observable,
            url: observable,
            createdAt: observable,
            updatedAt: observable,
            relevance: observable,
            active: observable,
            mediaType: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            type: this.type,
            version: this.version,
            acl: this.acl,
            title: this.title,
            url: this.url,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            relevance: this.relevance,
            active: this.active,
            mediaType: this.mediaType,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            type: new FieldState<MediaItemMediaSocial>(this.type),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            title: new FieldState<string>(this.title),
            url: new FieldState<string>(this.url),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            relevance: new FieldState<number>(this.relevance),
            active: new FieldState<boolean>(this.active),
            mediaType: new FieldState<EnhancedMediaTypeSocial>(
                new EnhancedMediaTypeSocial(this.mediaType, { initForm: true }),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
