import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { EnhancedStatsOverviewRatings } from './StatsOverviewRatings';

export type EnhancedStatsOverviewForm = FormState<{
    mediaValue: FieldState<number>;
    ratings: FieldState<EnhancedStatsOverviewRatings>;
}>;

export class EnhancedStatsOverview extends EnhancedEntity<EnhancedStatsOverviewForm> {
    public mediaValue!: number;
    public ratings!: EnhancedStatsOverviewRatings;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            mediaValue: observable,
            ratings: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { mediaValue: this.mediaValue, ratings: this.ratings };
    }

    public initForm() {
        this.form = new FormState({
            mediaValue: new FieldState<number>(this.mediaValue),
            ratings: new FieldState<EnhancedStatsOverviewRatings>(
                new EnhancedStatsOverviewRatings(this.ratings, {
                    initForm: true,
                }),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
