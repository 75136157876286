import * as React from 'react';
import { dateFormat } from '../../core/utils';

interface DateFormatProps {
    children?: Date;
    format?: string;
    className?: string;
}

export const DateFormat = (props: DateFormatProps) => {
    const { children, className, format = 'DD. MMM YYYY' } = props;

    if (!children) {
        return null;
    }

    return (
        <span
            className={className}
            data-role="date"
            data-role-value={children.toISOString()}
        >
            {dateFormat(children, format)}
        </span>
    );
};
