import styled from 'styled-components';

export const H1 = styled.h1`
    font-size: 2em;
    margin: 0 0 15px;
    padding: 0;
`;

export const H2 = styled.h2``;

export const H3 = styled.h3``;

export const H4 = styled.h4`
    font-size: 1.4em;
    line-height: 1.3;
    padding: 0;
    margin: 0;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const H5 = styled.h5``;

export const H6 = styled.h6``;
