import * as React from 'react';
import styled from 'styled-components';

type SpaceSize = '2' | '4' | '8' | '16' | '32' | '64' | '0' | 0;
type LayoutVAlign = 'flex-start' | 'center' | 'flex-end';
type LayoutHAlign = 'center' | 'space-between' | 'flex-end';

function mapSpaceSize(size?: SpaceSize): string {
    return `${size ?? 0}px`;
}

interface LayoutProps {
    children: React.ReactNode;
    className?: string;
    padding?: SpaceSize | SpaceSize[];
    between?: SpaceSize;
    stack?: boolean;
    stretch?: boolean;
    flex?: string;
    width?: string;
    valign?: LayoutVAlign;
    halign?: LayoutHAlign;
    'data-role'?: string;
    'data-role-value'?: string | number;
    style?: React.CSSProperties;
}

function getDirectionCssValue({ stack }: LayoutProps): string {
    return stack ? 'column' : 'row';
}

function getFlexCssValue({ stretch, flex }: LayoutProps): string {
    return stretch ? '1' : flex || '0 1 auto';
}

function getPaddingCssValue({ padding }: LayoutProps): string {
    if (Array.isArray(padding)) {
        return padding.map((size) => mapSpaceSize(size)).join(' ');
    }

    return mapSpaceSize(padding);
}

function getBetweenCssProperty({ stack }: LayoutProps): string {
    return stack ? 'margin-bottom' : 'margin-right';
}

function getBeweenCssValue({ between }: LayoutProps): string {
    return mapSpaceSize(between);
}

function getVAlignCssValue({ valign }: LayoutProps): string {
    return valign || 'stretch';
}

function getHAlignCssValue({ halign }: LayoutProps): string {
    return halign || 'flex-start';
}

function getWidthCssValue({ width }: LayoutProps): string {
    return width || 'auto';
}

export const Layout = styled.div<LayoutProps>`
    display: flex;
    flex-direction: ${getDirectionCssValue};
    flex: ${getFlexCssValue};
    padding: ${getPaddingCssValue};
    align-items: ${getVAlignCssValue};
    justify-content: ${getHAlignCssValue};
    width: ${getWidthCssValue};

    > *:not(:last-child) {
        ${getBetweenCssProperty}: ${getBeweenCssValue};
    }
`;

export const VSpace = styled.div<{ size?: number }>`
    height: ${(props) => props.size || 10}px;
`;
