import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedAnyObjectForm = FormState<{
    _u: FieldState<string | undefined>;
}>;

export class EnhancedAnyObject extends EnhancedEntity<EnhancedAnyObjectForm> {
    public _u!: string | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            _u: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { _u: this._u };
    }

    public initForm() {
        this.form = new FormState({
            _u: new FieldState<string | undefined>(this._u),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
