import { external, inject, TSDI } from 'tsdi';
import { TsdiDependency, TsdiScope } from './tsdi';

@external
class Injector {
    @inject
    private tsdi!: TSDI;
    public get<T>(Dependency: TsdiDependency<T>): T {
        return this.tsdi.get(Dependency);
    }
    public getScope(name: string): TsdiScope {
        return this.tsdi.getScope(name);
    }
}

class InjectorFactory {
    private injector?: Injector;
    public get<T>(Dependency: TsdiDependency<T>): T {
        if (!this.injector) {
            this.injector = new Injector();
        }
        return this.injector.get(Dependency);
    }
    public getScope(name: string): TsdiScope {
        if (!this.injector) {
            this.injector = new Injector();
        }
        return this.injector.getScope(name);
    }
}

export const tsdiInjector = new InjectorFactory();
