import {
    computed,
    IComputedValue,
    Lambda,
    makeAutoObservable,
    observable,
    observe,
    reaction
} from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { component, initialize } from 'tsdi';
import { injectTSDI } from '../../core/utils';
import { Button } from '../button';
import { Layout } from '../layout';
import { Modal, ModalSize } from '../modal';

const _default = (val, def) => (val === null ? null : val || def);

const Form = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100%;
`;

const MessageContainer = styled.div`
    display: flex;
    overflow: auto;
    flex-direction: column;
    margin-bottom: 30px;
    flex: 1;
`;

const ErrorMessage = styled.div`
    color: rgb(136, 39, 49);
    margin-bottom: 15px;
`;

@component
class ConfirmModalStore {
    public title?: string;

    public message?: React.ReactNode;

    public error?: React.ReactNode;

    public open = false;

    public loading = false;

    public confirmText?: string | null;

    public confirmDisabled?: IComputedValue<boolean>;

    public confirmDisabledValue = false;

    public cancelText?: string | null;

    public size: ModalSize = 'S';

    public action: () => Promise<any> = () => Promise.resolve();

    public onConfirm?: (result: any) => void;

    public onCancel?: () => void;

    public onClose?: () => void;

    public reset() {
        this.open = false;
        this.error = undefined;
        this.loading = false;
        this.confirmDisabledValue = false;
        this.onCancel = undefined;
        this.onClose = undefined;

        setTimeout(() => {
            if (this.open === false) {
                this.message = undefined;
            }
        }, 150);
    }

    @initialize
    protected init() {
        makeAutoObservable(this, {
            message: observable.ref,
            error: observable.ref,
            action: observable,
            onConfirm: observable,
            onCancel: observable,
            onClose: observable
        });
    }
}
let modalStore: ConfirmModalStore | undefined;

export async function confirm<T = boolean>(
    message: React.ReactNode,
    options: {
        title?: string;
        error?: React.ReactNode;
        confirmText?: string | null;
        confirmDisabled?: () => boolean;
        cancelText?: string | null;
        size?: ModalSize;
        action?: () => Promise<any>;
        /** is called once the user cancels or closes the dialog using the 'x' */
        onCancel?: () => void;
        /** is always called whenever the dialog closes */
        onClose?: () => void;
    } = {}
): Promise<T> {
    return new Promise<T>((resolve) => {
        if (modalStore) {
            if (modalStore.open && modalStore.onClose) {
                modalStore.onClose();
            }

            modalStore.confirmDisabledValue = false;
            modalStore.open = true;
            modalStore.message = message;
            modalStore.title = options.title;
            modalStore.error = options.error;
            modalStore.onCancel = options.onCancel;
            modalStore.onClose = options.onClose;
            modalStore.size = options.size || 'S';
            modalStore.confirmText = _default(options.confirmText, 'Ok');
            modalStore.cancelText = _default(options.cancelText, 'Cancel');
            modalStore.confirmDisabled = options.confirmDisabled
                ? computed(options.confirmDisabled)
                : undefined;
            modalStore.action = _default(options.action, () =>
                Promise.resolve()
            );
            modalStore.onConfirm = resolve;
        }
    });
}

confirm.close = () => {};

export const ConfirmModal = observer(() => {
    const store = injectTSDI(ConfirmModalStore);
    const {
        open,
        title,
        message,
        error,
        loading,
        size,
        confirmText,
        confirmDisabledValue,
        cancelText
    } = store;

    let confirmDisabledDisposer = React.useRef<Lambda>();

    React.useEffect(() => {
        modalStore = store;

        confirm.close = close;

        return reaction(
            () => store.confirmDisabled,
            (confirmDisabled) => {
                if (confirmDisabled) {
                    confirmDisabledDisposer.current = observe(
                        confirmDisabled,
                        (change) => {
                            store.confirmDisabledValue = change.newValue;
                        }
                    );

                    store.confirmDisabledValue = confirmDisabled.get();
                }
            }
        );
    }, []);

    const close = (
        options: { triggerCancel?: boolean } = { triggerCancel: true }
    ) => {
        confirmDisabledDisposer.current?.();

        if (options.triggerCancel) {
            store.onCancel?.();
        }

        store.onClose?.();
        store.reset();
    };

    const onConfirm = async () => {
        store.error = undefined;
        store.loading = true;

        try {
            const result = await store.action();

            close({ triggerCancel: false });

            store.onConfirm?.(result || true);
        } catch (e) {
            console.error(e);

            store.loading = false;
            store.error =
                (e instanceof Error && e.message) ||
                'An error occured. Please try again.';

            throw e;
        }
    };

    const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();

        onConfirm();
    };

    return (
        <Modal
            open={open}
            title={title}
            className="ReactModal_confirm"
            size={size}
            scope="modal"
            onClose={close}
            onKeyEnter={onConfirm}
        >
            <Form onSubmit={onSubmit}>
                <MessageContainer
                    dangerouslySetInnerHTML={
                        typeof message === 'string'
                            ? { __html: message }
                            : undefined
                    }
                >
                    {typeof message !== 'string' ? message : undefined}
                </MessageContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <Layout halign={cancelText ? 'space-between' : 'flex-end'}>
                    {cancelText && (
                        <Button value={undefined} onClick={close}>
                            {cancelText}
                        </Button>
                    )}
                    {confirmText && (
                        <Button
                            active
                            submit
                            disabled={confirmDisabledValue}
                            loading={loading}
                            data-role="confirm-action"
                        >
                            {confirmText}
                        </Button>
                    )}
                </Layout>
            </Form>
        </Modal>
    );
});
