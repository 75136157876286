import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { MediaItemMediaTypeSocial } from '../schema';
import { EnhancedAcl } from '../../entities/enhanced-acl';

export type EnhancedMediaTypeSocialForm = FormState<{
    id: FieldState<string>;
    type: FieldState<MediaItemMediaTypeSocial>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    title: FieldState<string>;
    createdAt: FieldState<string>;
    updatedAt: FieldState<string>;
}>;

export class EnhancedMediaTypeSocial extends EnhancedEntity<EnhancedMediaTypeSocialForm> {
    /* public id!: string; */
    public type!: MediaItemMediaTypeSocial;
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public title!: string;
    public createdAt!: string;
    public updatedAt!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            type: observable,
            version: observable,
            title: observable,
            createdAt: observable,
            updatedAt: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            type: this.type,
            version: this.version,
            acl: this.acl,
            title: this.title,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            type: new FieldState<MediaItemMediaTypeSocial>(this.type),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            title: new FieldState<string>(this.title),
            createdAt: new FieldState<string>(this.createdAt),
            updatedAt: new FieldState<string>(this.updatedAt),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
