import { observer } from 'mobx-react';
import * as React from 'react';
import { IS_WHITELABEL } from '../../core/utils';

interface FaviconProps {
    href?: string;
}

export const Favicon = observer((props: FaviconProps) => {
    React.useEffect(() => {
        if (!IS_WHITELABEL()) {
            const link = document.createElement('link');

            link.setAttribute('ref', 'shortcut icon');
            link.setAttribute('type', 'image/x-icon');
            link.setAttribute('href', props.href || '/favicon.ico');

            document.head.appendChild(link);

            return () => document.head.removeChild(link);
        }

        return () => {};
    }, []);

    return <></>;
});
