import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedVoidSuccessForm = FormState<{
    success: FieldState<boolean | undefined>;
}>;

export class EnhancedVoidSuccess extends EnhancedEntity<EnhancedVoidSuccessForm> {
    public success!: boolean | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            success: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { success: this.success };
    }

    public initForm() {
        this.form = new FormState({
            success: new FieldState<boolean | undefined>(this.success),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
