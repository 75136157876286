import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { link } from 'takeme';
import { AppStore } from '../app/store';
import { logout, storage } from '../aws';
import { Icon, Layout, Link, Textfield } from '../components';
import { AccessStore } from '../core/access-store';
import { NotificationsButton } from '../core/notifications';
import { useTSDI } from '../core/utils';
import { SettingsNav } from '../settings/nav';
import LogoPNG from './assets/logo.png';

const NavWrapper = styled(Layout)`
    display: flex;
    background: #f9f9f9;
    margin: -20px -20px 25px;
    padding: 10px 20px;
    box-shadow:
        rgba(0, 0, 0, 0.05) 0px 5px 10px 0px,
        rgb(233, 234, 236) 0px -1px 0px 0px inset;
    flex-wrap: wrap;
`;

const StyledLogo = styled.div`
    background: url('${LogoPNG}') no-repeat 0 0;
    background-size: contain;
    width: 150px;
    height: 50px;
    cursor: pointer;

    @media (max-width: 640px) {
        width: 80px;
    }
`;

const SearchWrapper = styled.div`
    position: relative;
    width: 350px;

    @media (max-width: 640px) {
        max-width: calc(100% - 90px);
    }
`;

const SearchIcon = styled(Icon)`
    position: absolute;
    right: 10px;
    top: 8px;
`;

const Search = styled(Textfield)`
    padding-right: 30px;
`;

const SearchClearer = styled.button`
    position: absolute;
    right: 28px;
    top: 4px;
    color: #ccc;
    font-size: 20px;
    line-height: 1;
    border: none;
    cursor: pointer;

    &:hover {
        color: #aaa;
    }
`;

const Logo = () => (
    <Link href={link('/')}>
        <StyledLogo />
    </Link>
);

const UserMenu = observer(() => {
    const { isAdmin, isWorker } = useTSDI(AccessStore);

    function onLogoutClick(e: React.SyntheticEvent<HTMLAnchorElement>): void {
        e.preventDefault();
        logout();
    }

    return (
        <Layout valign="center" between="8">
            {isWorker && (
                <Link href={link('/calendar/evt')} title="EVTs">
                    <Icon type="calendar" />
                </Link>
            )}
            {isAdmin && (
                <Link href={link('/settings')} title="Settings">
                    <Icon type="settings" />
                </Link>
            )}
            <span>
                <a data-role="logout" href="#" onClick={onLogoutClick}>
                    logout
                </a>
            </span>
        </Layout>
    );
});

const Notifications = observer(() => {
    const appStore = useTSDI(AppStore);

    return (
        <NotificationsButton
            notifications={appStore.notifications.$}
            onClickRead={(notification) =>
                appStore.readNotification(notification)
            }
            onClickReadAll={() => appStore.readNotifications()}
        />
    );
});

export const Header = observer(() => {
    const appStore = useTSDI(AppStore);
    const {
        params,
        route,
        loggedIn,
        globalSearchPlaceholder = 'Search...',
        globalSearch
    } = appStore;
    const searchDisabled =
        route === '/project/:id/:type/:viewmode' && params.viewmode !== 'live';
    const { me } = storage.get();

    function onSearch(value: string): void {
        appStore.globalSearch = value;
    }

    return (
        <>
            <NavWrapper
                as={'header' as any}
                halign="space-between"
                valign="center"
            >
                <Logo />
                {loggedIn && (
                    <>
                        <SearchWrapper>
                            <Search
                                placeholder={globalSearchPlaceholder}
                                disabled={searchDisabled}
                                value={globalSearch || ''}
                                onChange={onSearch}
                            />
                            {globalSearch && (
                                <SearchClearer
                                    type="button"
                                    onClick={() => onSearch('')}
                                >
                                    ×
                                </SearchClearer>
                            )}
                            <SearchIcon type="search" />
                        </SearchWrapper>
                        <Layout
                            between="16"
                            stretch
                            halign="flex-end"
                            valign="flex-start"
                        >
                            <Layout
                                between="4"
                                halign="flex-end"
                                valign="flex-end"
                                stack
                            >
                                <span>{me?.username}</span>
                                <UserMenu />
                            </Layout>
                            <Notifications />
                        </Layout>
                    </>
                )}
            </NavWrapper>
            {route && route.startsWith('/settings') && <SettingsNav />}
        </>
    );
});
