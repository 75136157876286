import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { Lang } from '../schema';
import { EnhancedFile } from '../../entities/enhanced-file';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedCompany } from './Company';
import { EnhancedProjectType } from './ProjectType';

export type EnhancedProjectForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    name: FieldState<string>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    image: FieldState<EnhancedFile | undefined>;
    releasedate: FieldState<Date>;
    startdate: FieldState<Date>;
    enddate: FieldState<Date>;
    company: FieldState<EnhancedCompany>;
    types: FieldState<EnhancedProjectType[]>;
    lang: FieldState<Lang[]>;
    tags: FieldState<string[]>;
    archived: FieldState<boolean>;
    autoArchieveDisabled: FieldState<boolean | undefined>;
}>;

export class EnhancedProject extends EnhancedEntity<EnhancedProjectForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public name!: string;
    public createdAt!: Date;
    public updatedAt!: Date;
    public image!: EnhancedFile | undefined;
    public releasedate!: Date;
    public startdate!: Date;
    public enddate!: Date;
    public company!: EnhancedCompany;
    public types!: EnhancedProjectType[];
    public lang!: Lang[];
    public tags!: string[];
    public archived!: boolean;
    public autoArchieveDisabled!: boolean | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            name: observable,
            createdAt: observable,
            updatedAt: observable,
            image: observable,
            releasedate: observable,
            startdate: observable,
            enddate: observable,
            company: observable,
            types: observable,
            lang: observable,
            tags: observable,
            archived: observable,
            autoArchieveDisabled: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            name: this.name,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            image: this.image,
            releasedate: this.releasedate,
            startdate: this.startdate,
            enddate: this.enddate,
            company: this.company,
            types: this.types,
            lang: this.lang,
            tags: this.tags,
            archived: this.archived,
            autoArchieveDisabled: this.autoArchieveDisabled,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            name: new FieldState<string>(this.name),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            image: new FieldState<EnhancedFile | undefined>(
                this.image === undefined || this.image === null
                    ? undefined
                    : new EnhancedFile(this.image),
            ),
            releasedate: new FieldState<Date>(new Date(this.releasedate)),
            startdate: new FieldState<Date>(new Date(this.startdate)),
            enddate: new FieldState<Date>(new Date(this.enddate)),
            company: new FieldState<EnhancedCompany>(
                new EnhancedCompany(this.company, { initForm: true }),
            ),
            types: new FieldState<EnhancedProjectType[]>(
                (this.types || []).map(
                    (item) => new EnhancedProjectType(item, { initForm: true }),
                ),
            ),
            lang: new FieldState<Lang[]>(this.lang || []),
            tags: new FieldState<string[]>(this.tags || []),
            archived: new FieldState<boolean>(this.archived),
            autoArchieveDisabled: new FieldState<boolean | undefined>(
                this.autoArchieveDisabled,
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
