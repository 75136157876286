import { EnhancedNotification, getThumbImage } from '@reportroyal/api';
import * as React from 'react';
import styled from 'styled-components';
import { Button, H4, Icon, Layout } from '../../components';
import { getDefaultImageFormat } from '../webp';

interface NotificationsButtonProps {
    notifications: EnhancedNotification[];
    onClickRead(notification: EnhancedNotification): void;
    onClickReadAll(): void;
}

interface NotificationsListProps {
    notifications: EnhancedNotification[];
    open: boolean;
    onClickRead(notification: EnhancedNotification): void;
    onClose(): void;
    onClickReadAll(): void;
}

interface NotificationListItemProps {
    notification: EnhancedNotification;
    onClickRead(notification: EnhancedNotification): void;
}

const Container = styled.div`
    position: relative;
`;

const ListContainer = styled(Layout)`
    position: absolute;
    right: 0;
    top: 40px;
    width: 330px;
    max-height: 365px;
    background: #fff;
    z-index: 5;
    border: 0.1rem solid #efefef;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
`;

const ListItem = styled(Layout)`
    border-bottom: 1px solid #efefef;
    flex-shrink: 0;

    &:last-child {
        border-bottom: none;
    }
`;

const Content = styled(Layout)`
    overflow: hidden;
`;

const Headline = styled(Layout)`
    padding: 22px 0px 22px 16px;
`;

const ListItemDate = styled.span`
    color: #888;
    font-size: 12px;
`;

const ListImage = styled.a<{ src: string }>`
    display: block;
    background-image: ${(props) => `url('${props.src}')`};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    flex: 0 0 50px;
`;

const ReadButton = styled(Button)`
    position: relative;
    top: -0.7rem;
    font-size: 8px;
`;

export function NotificationsButton(props: NotificationsButtonProps) {
    const [open, setOpen] = React.useState(false);
    const { notifications, onClickRead, onClickReadAll } = props;

    function onToggle() {
        setOpen((open) => !open);
    }

    return (
        <Container>
            <Button
                title="Notifications"
                className="notifications-button"
                bobble={
                    !open
                        ? notifications.length > 99
                            ? '99+'
                            : notifications.length
                        : undefined
                }
                active={open}
                disabled={notifications.length === 0}
                onClick={onToggle}
            >
                <Icon type="inbox" />
            </Button>
            <NotificationsList
                open={open}
                notifications={notifications}
                onClickRead={onClickRead}
                onClickReadAll={onClickReadAll}
                onClose={onToggle}
            />
        </Container>
    );
}

function NotificationsList(props: NotificationsListProps) {
    const { open, notifications } = props;

    React.useEffect(() => {
        function onDocumentClick(e: Event) {
            const target = e.target as HTMLElement;

            if (
                target.closest('.notifications-list') === null &&
                target.closest('.notifications-button') === null
            ) {
                props.onClose();
            }
        }

        if (open) {
            document.addEventListener('click', onDocumentClick);

            return () => document.removeEventListener('click', onDocumentClick);
        }

        return;
    }, [open]);

    return open ? (
        <ListContainer className="notifications-list" stack between="16">
            <Headline halign="space-between" valign="center">
                <H4>Notifications</H4>
                <Button borderless onClick={props.onClickReadAll}>
                    Clear all
                </Button>
            </Headline>
            {notifications.map((item) => (
                <NotificationListItem
                    key={item.id}
                    notification={item}
                    onClickRead={props.onClickRead}
                />
            ))}
        </ListContainer>
    ) : null;
}

const NotificationListItem = (props: NotificationListItemProps) => {
    const { notification } = props;
    const { createdAt } = notification;

    return (
        <ListItem between="16" padding={[0, 0, '16', '16']}>
            <i className={`icon icon-${notification.icon}`} />
            {notification.image && (
                <ListImage
                    href={notification.link}
                    src={getThumbImage(notification.image, {
                        format: getDefaultImageFormat()
                    })}
                />
            )}
            <Content stack stretch between="4">
                <div
                    dangerouslySetInnerHTML={{
                        __html: notification.text
                    }}
                />
                <ListItemDate>
                    {createdAt.toLocaleDateString()}{' '}
                    {createdAt.toLocaleTimeString()}
                </ListItemDate>
            </Content>
            <ReadButton
                borderless
                title="Mark read"
                onClick={() => props.onClickRead(props.notification)}
            >
                <Icon type="circle" />
            </ReadButton>
        </ListItem>
    );
};
