import { Tag } from '@reportroyal/api';
import Konva from 'konva';
import * as React from 'react';
import { Group, Layer, Rect, Stage, Text } from 'react-konva';
import { Transformer } from './transformer';
import { round, getTagColor as tagColor } from './utils';

interface TagtoolProps {
    className?: string;
    tag?: Tag;
    disabled?: boolean;
    imageLoaded?: boolean;
    width: number;
    height: number;
    zoom: number;
    isCropping?: boolean;
    onUpdate(tag: Tag | undefined): void;
    onEnterFrame?(clip?: Tag, magniTag?: Tag): void;
}

interface TagtoolState {
    textVisible: boolean;
    transforming: boolean;
    stageFocused: boolean;
    selectedShapeName?: string;
    magniTag?: Tag;
}

const GROUP_NAME = 'clipping';
export const THRESHOLD = 10;

export class Tagtool extends React.Component<TagtoolProps, TagtoolState> {
    private group = React.createRef<Konva.Group>();
    private stage?: Konva.Stage;
    private stagePosition: Konva.Vector2d = { x: 0, y: 0 };

    private get className() {
        const { className, tag, imageLoaded } = this.props;

        return [
            'tagtool',
            className,
            tag ? 'tagged' : undefined,
            imageLoaded ? 'loaded' : undefined
        ]
            .filter((v) => v)
            .join(' ');
    }

    constructor(props: TagtoolProps) {
        super(props);

        this.state = {
            textVisible: true,
            transforming: false,
            stageFocused: false,
            selectedShapeName: undefined
        };
    }

    public componentDidMount(): void {
        document.body.addEventListener('keyup', this.onKeyup, false);
    }

    public componentWillUnmount(): void {
        document.body.removeEventListener('keyup', this.onKeyup, false);
    }

    public render(): JSX.Element | null {
        const { width, height, imageLoaded } = this.props;
        const zoom = Math.min(this.props.zoom, 1);

        return (
            <Stage
                className={this.className}
                width={width * zoom + 2 * THRESHOLD}
                height={height * zoom + 2 * THRESHOLD}
                ref={(e) => this.onStage(e as any)}
                onMouseDown={(e) => this.onStageMouseDown(e)}
                onMouseEnter={() => this.onStageMouseEnter()}
                onMouseMove={(e) => this.onStageMouseMove(e)}
                onMouseLeave={() => this.onStageMouseLeave()}
                style={{ visibility: imageLoaded ? 'visible' : 'hidden' }}
            >
                {this.renderTag()}
            </Stage>
        );
    }

    private renderTag() {
        const { tag, zoom, disabled, isCropping } = this.props;
        const { selectedShapeName, textVisible } = this.state;

        if (!tag) {
            return null;
        }

        const tagText = this.getTagText(tag);
        const x = tag.x * zoom + THRESHOLD;
        const y = tag.y * zoom + THRESHOLD;
        const width = tag.width * zoom;
        const height = tag.height * zoom;
        const scale = { x: 1, y: 1 };

        return (
            <Layer>
                <Group
                    name={GROUP_NAME}
                    ref={this.group}
                    draggable={!disabled}
                    dragBoundFunc={(point) => this.onValidateBounds(point)}
                    scale={scale}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    onTransformStart={() => this.onTransformStart()}
                    onTransformEnd={(e) => this.onTransformEnd(e)}
                    onDragStart={() => this.onTransformStart()}
                    onDragMove={(e) => this.onDragMove(e)}
                    onDragEnd={(e) => this.onUpdateRect(e)}
                    onMouseOver={() => this.onTagMouseOver()}
                    onMouseOut={() => this.onTagMouseOut()}
                >
                    <Rect
                        scale={scale}
                        width={width}
                        height={height}
                        fill={tagColor(0.65)}
                    />
                    {!isCropping && (
                        <Text
                            fill="white"
                            align="center"
                            verticalAlign="middle"
                            width={width}
                            height={height}
                            fontSize={22}
                            shadowEnabled
                            shadowColor="#111"
                            shadowBlur={5}
                            listening={false}
                            visible={textVisible}
                            text={tagText}
                        />
                    )}
                </Group>
                <Transformer
                    stageSize={{
                        width: this.props.width,
                        height: this.props.height
                    }}
                    selectedShapeName={selectedShapeName}
                />
            </Layer>
        );
    }

    private getTagText(tag: Tag) {
        const { zoom } = this.props;
        const width = this.props.width / zoom;
        const height = this.props.height / zoom;

        const size = round((tag.width * tag.height) / (width * height));
        let text = '';

        if (size < 0.03) return text;
        if (size < 0.124) text = `1/${Math.round(1 / size)}`;
        if (size >= 0.125) text = '1/8 page';
        if (size >= 0.16) text = '1/6 page';
        if (size >= 0.2) text = '1/5 page';
        if (size >= 0.25) text = '1/4 page';
        if (size >= 0.33) text = '1/3 page';
        if (size >= 0.45) text = '1/2 page';
        if (size >= 0.6) text = '2/3 page';
        if (size >= 0.75) text = '3/4 page';
        if (size >= 0.8) text = '4/5 page';
        if (size >= 0.9) text = 'Full page';

        return text;
    }

    private onTransformStart() {
        this.setState({ textVisible: false, transforming: true });
    }

    private onTransformEnd(e: Konva.KonvaEventObject<Event>) {
        this.onUpdateRect(e);
        this.setState({ transforming: false });
    }

    private onUpdateRect(e: Konva.KonvaEventObject<Event>) {
        const rect = e.target as Konva.Rect;
        const tag = this.getTag(rect);

        this.setState({ textVisible: true, magniTag: undefined }, () => {
            this.props.onUpdate(tag);
        });
    }

    private getTag(rect: Konva.Rect): Tag {
        const { zoom } = this.props;
        const scale = rect.getAbsoluteScale();
        const size = rect.getSize();
        const pos = rect.getPosition();

        const x = Math.max(0, Math.round((pos.x - THRESHOLD) / zoom));
        const y = Math.max(0, Math.round((pos.y - THRESHOLD) / zoom));

        let width = Math.round((size.width * scale.x) / zoom);
        let height = Math.round((size.height * scale.y) / zoom);

        if (x + width > this.props.width) {
            width = this.props.width - x;
        }

        if (height + y > this.props.height) {
            height = this.props.height - y;
        }

        return {
            x,
            y,
            width,
            height
        };
    }

    private onStageMouseEnter() {
        if (this.props.tag) {
            this.setState({
                selectedShapeName: GROUP_NAME,
                stageFocused: true
            });
        }
    }

    private onStageMouseLeave() {
        if (!this.state.transforming) {
            this.setState({
                selectedShapeName: undefined,
                stageFocused: false
            });
        }

        this.props.onEnterFrame?.();
    }

    private onStage(stage: Konva.Stage | null) {
        if (!stage) {
            return;
        }

        const stageBounds = stage.getContent().getBoundingClientRect();

        this.stage = stage;
        this.stagePosition = { x: stageBounds.left, y: stageBounds.top };
    }

    private onStageMouseDown(e: Konva.KonvaEventObject<MouseEvent>) {
        const { tag, disabled } = this.props;
        const { target } = e;
        const { current: group } = this.group;
        const stage = target.getStage();
        const parent = target.getParent();

        if (disabled) {
            return;
        }

        // @ts-ignore
        if (target === stage) {
            // clear selection
            this.setState({
                selectedShapeName: undefined
            });

            // start drawing
            this.handleDrawing(e);
            return;
        }

        // clicked on transformer - do nothing
        if (parent instanceof Konva.Transformer) {
            return;
        }

        if (group) {
            this.setState({
                selectedShapeName: tag ? group.name() : undefined
            });
        }
    }

    private onDragMove(e: Konva.KonvaEventObject<MouseEvent>) {
        const rect = e.target as Konva.Rect;
        const magniTag = this.getTag(rect);

        this.setState({ magniTag }, () => this.onStageMouseMove(e));
    }

    private onStageMouseMove(e: Konva.KonvaEventObject<MouseEvent>) {
        const { zoom } = this.props;
        const width = this.props.width * zoom;
        const height = this.props.height * zoom;
        const offsetX = (e.evt.offsetX - THRESHOLD) / zoom;
        const offsetY = (e.evt.offsetY - THRESHOLD) / zoom;

        const tag = this.state.magniTag || this.props.tag;

        this.props.onEnterFrame?.(
            {
                x: offsetX - width / 2,
                y: offsetY - height / 2,
                width,
                height
            },
            tag && {
                x: (offsetX - tag.x) * -1,
                y: (offsetY - tag.y) * -1,
                width: tag.width,
                height: tag.height
            }
        );
    }

    private onTagMouseOver() {
        if (this.stage) {
            this.stage.container().style.cursor = 'move';
        }
    }

    private onTagMouseOut() {
        if (this.stage) {
            this.stage.container().style.cursor = 'default';
        }
    }

    private onValidateBounds(point: Konva.Vector2d): Konva.Vector2d {
        const { tag, width, height, zoom } = this.props;

        if (!tag) {
            return point;
        }

        if (point.x! < THRESHOLD) {
            point.x = THRESHOLD;
        }

        if (point.y < THRESHOLD) {
            point.y = THRESHOLD;
        }

        if (point.x + tag.width * zoom > width + THRESHOLD) {
            point.x = width + THRESHOLD - tag.width * zoom;
        }

        if (point.y + tag.height * zoom > height + THRESHOLD) {
            point.y = height + THRESHOLD - tag.height * zoom;
        }

        return point;
    }

    private onKeyup = (e: KeyboardEvent) => {
        if (this.state.stageFocused) {
            const { target, keyCode } = e;
            const tagName =
                target instanceof HTMLElement
                    ? target.tagName.toLowerCase()
                    : undefined;
            const allowDelete = tagName !== 'textarea' && tagName !== 'input';

            // Backspace || Delete
            if (allowDelete && (keyCode === 8 || keyCode === 46)) {
                this.setState({ selectedShapeName: undefined }, () => {
                    this.onTagMouseOut();
                    this.props.onUpdate(undefined);
                    this.props.onEnterFrame?.();
                });
            }
        }
    };

    private handleDrawing(e: Konva.KonvaEventObject<MouseEvent>) {
        const stage = e.target as any as Konva.Stage;

        let added = false;
        const layer = new Konva.Layer();
        const { x, y } = e.evt;
        const rect = new Konva.Rect({
            x: x - this.stagePosition.x,
            y: y - this.stagePosition.y,
            fill: tagColor(0.7)
        });

        const onMouseUp = () => {
            stage.off('mousemove');
            document.removeEventListener('mouseup', onMouseUp);

            if (rect.width() && rect.height()) {
                this.onUpdateRect({
                    type: '',
                    pointerId: 0,
                    cancelBubble: false,
                    currentTarget: {} as any,
                    target: rect,
                    evt: {} as any
                });
            }

            layer.remove();
        };

        stage.on('mousemove', ({ evt }) => {
            const newX = evt.x;
            const newY = evt.y;

            if (!added) {
                layer.add(rect);
                stage.add(layer);

                added = true;
            }

            if (this.props.tag) {
                this.props.onUpdate(undefined);
            }

            const left = x > newX ? newX : x;
            const top = y > newY ? newY : y;
            const width = Math.abs(newX - x);
            const height = Math.abs(newY - y);

            rect.x(left - this.stagePosition.x);
            rect.y(top - this.stagePosition.y);
            rect.width(width);
            rect.height(height);

            layer.batchDraw();

            this.setState({
                magniTag: this.getTag(rect)
            });
        });

        document.addEventListener('mouseup', onMouseUp, false);
    }
}
