import {
    ApiError,
    EnhancedAcl,
    EnhancedEntityOptions,
    EnhancedRole,
    EnhancedRoleForm,
    EnhancedUser,
    EnhancedUserForm,
    EnhancedUserProjectType,
    EnhancedUserProjectTypeForm,
    enhanceEntity,
    form2Entity,
    RecursivePartial,
    RoleGetRequest,
    RoleJSON,
    UserJSON,
    UserProjectTypeGetRequest,
    UserProjectTypeJSON,
    UserProjectTypesGetRequest,
    UsersGetRequest
} from '@reportroyal/api';
import { client, getNewId } from './api';
import { storage } from './auth';

export async function loadUserProjectTypes(
    options: UserProjectTypesGetRequest
): Promise<EnhancedUserProjectType[]> {
    const items = await client.userProjectTypesGet(options);

    return items.map((item) => enhanceEntity(item));
}

export async function loadUserProjectType(options: UserProjectTypeGetRequest) {
    const item = await client.userProjectTypeGet(options);

    return enhanceEntity<EnhancedUserProjectType>(item);
}

export function postUserProjectType(form: EnhancedUserProjectTypeForm) {
    return client.userProjectTypePost({ userProjectType: form2Entity(form) });
}

export function putUserProjectType(form: EnhancedUserProjectTypeForm) {
    return client.userProjectTypePut({ userProjectType: form2Entity(form) });
}

export function deleteUserProjectType(id: string) {
    return client.userProjectTypeDelete({ id });
}

export async function loadMyRoles(): Promise<string[]> {
    return client.rolesMyGet({});
}

export async function loadRoles(
    options: EnhancedEntityOptions = {}
): Promise<EnhancedRole[]> {
    const items = await client.rolesGet({});

    return items.map((item) => enhanceEntity(item, options));
}

export async function loadRole(
    config: RoleGetRequest,
    options?: EnhancedEntityOptions
) {
    const item = await client.roleGet(config);

    return enhanceEntity<EnhancedRole>(item, options);
}

export async function loadUsers(
    config: UsersGetRequest = {},
    options: EnhancedEntityOptions = {}
): Promise<EnhancedUser[]> {
    const items = await client.usersGet(config);

    return items.map((item) => enhanceEntity(item, options));
}

export async function loadUser(id: string, options?: EnhancedEntityOptions) {
    const item = await client.userGet({ id });

    return enhanceEntity<EnhancedUser>(item, options);
}

export async function putUser(form: EnhancedUserForm) {
    const item = await client.userPut({ user: form2Entity(form) });

    return enhanceEntity<EnhancedUser>(item);
}

export async function postUser(form: EnhancedUserForm) {
    return client.userPost({ user: form2Entity(form) });
}

export async function putRole(form: EnhancedRoleForm) {
    const item = await client.rolePut({ role: form2Entity(form) });

    return enhanceEntity<EnhancedRole>(item);
}

export function postRole(form: EnhancedRoleForm) {
    return client.rolePost({ role: form2Entity(form as any) });
}

export function deleteRole(id: string) {
    return client.roleDelete({ id });
}

export async function loadMyUser(): Promise<EnhancedUser> {
    try {
        const item = await client.userMeGet({});

        localStorage.setItem('me', JSON.stringify(item));
        storage.set({ ...storage.get(), me: item });

        return enhanceEntity<EnhancedUser>(item);
    } catch (e) {
        if (e instanceof ApiError && e.status !== 504) {
            return loadMyUser();
        }

        throw e;
    }
}

export function getEntityRoles(acl: EnhancedAcl, allRoles: EnhancedRole[]) {
    return Object.keys(acl.read)
        .filter((rule) => rule.startsWith('/db/Role'))
        .map((rule) => allRoles.find((r) => r.id === rule)!)
        .filter((role) => Boolean(role));
}

export function getEntityUsers(acl: EnhancedAcl, allUsers: EnhancedUser[]) {
    return Object.keys(acl.read)
        .filter((rule) => rule.startsWith('/db/User'))
        .map((rule) => allUsers.find((r) => r.id === rule)!)
        .filter((role) => Boolean(role));
}

export function isReadAllowed(
    acl: EnhancedAcl,
    user: EnhancedUser,
    roles: EnhancedRole[] = []
): boolean {
    const entityRoles = Object.keys(acl.read);

    if (entityRoles.includes(user.id)) {
        return true;
    }

    for (const role of roles) {
        if (entityRoles.includes(role.id)) {
            for (const userInRole of role.users || []) {
                if (userInRole.id === user.id) {
                    return true;
                }
            }
        }
    }

    return false;
}

export function emptyUserProjectType(
    obj: RecursivePartial<UserProjectTypeJSON>
): EnhancedUserProjectType {
    return enhanceEntity(
        {
            id: getNewId('UserProjectType'),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            ...obj
        },
        { new: true, initForm: true }
    );
}

export function emptyUser(obj: RecursivePartial<UserJSON> = {}): EnhancedUser {
    return enhanceEntity(
        {
            id: getNewId('User'),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            numLogins: 0,
            notifications: true,
            unsubscribed: false,
            inactive: false,
            ...obj
        },
        { new: true, initForm: true }
    );
}

export function emptyRole(obj: RecursivePartial<RoleJSON>): EnhancedRole {
    return enhanceEntity(
        {
            id: getNewId('Role'),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            users: [],
            ...obj
        },
        { new: true, initForm: true }
    );
}
