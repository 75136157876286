import {
    EnhancedPdf,
    Path,
    PathResolver,
    PdfJSON,
    URLCacheJSON,
    WSS_URL,
    WebsocketDbTriggerResult,
    WebsocketTopic,
    enhanceEntity
} from '@reportroyal/api';
import { v1 as uuid } from 'uuid';
import { confirm } from '../components';
import { bugsnagClient } from '../core/bugsnag';
import { wait } from '../core/utils';
import { connect, storage } from './auth';
import { loadPdf } from './pdf';

type CallbackFn<D = any> = (payload: WebsocketPayload<D>) => void;
type ValidateFn<D = any> = (payload: WebsocketPayload<D>) => boolean;
export type Subscription = () => void;
export type WebsocketPayload<D = undefined> = {
    message: WebsocketTopic;
    user: string;
    connectionId: string;
    data: D;
};

export interface ItemSubscriptionPayload {
    id: string;
    project: string;
}

export function subscribeToWebsockets() {
    return addSubscription<WebsocketDbTriggerResult<PathResolver>>(
        'notify',
        (e) => {
            const document = e.data.document!;

            [...websocketListeners]
                .filter((listener) => document.path === listener.path)
                .map((listener) => {
                    if (
                        listener.entityId &&
                        document.entityId !== listener.entityId
                    ) {
                        return;
                    }

                    listener.callback(document);
                });
        },
        (e) => e.data.entity === 'PathResolver'
    );
}

export function subscribeToURLCacheUpdate(url: string) {
    return new Promise<URLCacheJSON | undefined>((resolve) => {
        let disposed = false;
        const dispose = addSubscription<WebsocketDbTriggerResult<URLCacheJSON>>(
            'db.trigger',
            (e) => {
                const { document } = e.data;

                if (document?.url === url && document?.image) {
                    disposed = true;
                    dispose();
                    resolve(document);
                }
            }
        );

        // timeout: 50sec
        wait(50 * 1000).then(() => {
            if (!disposed) {
                dispose();
                resolve(undefined);
            }
        });
    });
}

export function subscribeToPdfResult(pdfId: string) {
    return new Promise<EnhancedPdf>((resolve, reject) => {
        let intervalFallback: NodeJS.Timeout;

        function check(document: PdfJSON | EnhancedPdf) {
            if (document.id !== pdfId) {
                return;
            }

            if (document.progress === 100) {
                dispose();
                clearInterval(intervalFallback);
                resolve(enhanceEntity<EnhancedPdf>(document));
            }

            if (document.error) {
                dispose();
                clearInterval(intervalFallback);
                reject(new Error(document.error));
            }
        }

        const dispose = addSubscription<WebsocketDbTriggerResult<PdfJSON>>(
            'db.trigger',
            (e) => {
                const { document } = e.data;

                if (document) {
                    check(document);
                }
            }
        );

        // this is just a fallback in case the trigger for Pdf is not working
        intervalFallback = setInterval(async () => {
            const pdf = await loadPdf(pdfId);

            check(pdf);
        }, 8000);
    });
}

export function subscribeForVersion(callback: CallbackFn<void>) {
    return addSubscription('version', callback);
}

export function subscribeToBulkImages(uuid: string) {
    // TODO: add timeout
    return new Promise<string>((resolve) => {
        addSubscription<{ uuid: string; url: string }>(
            'bulk-images',
            (e) => resolve(e.data.url),
            (e) => uuid === e.data.uuid
        );
    });
}

interface WebsocketListener {
    path: Path;
    entityId?: string;
    callback(resolver: PathResolver): void;
}

const websocketListeners = new Set<WebsocketListener>();

export function listenToWebsocketUpdate(
    listener: WebsocketListener
): Subscription {
    websocketListeners.add(listener);

    return () => {
        websocketListeners.delete(listener);
    };
}

let socket: WebSocket | undefined;
let socketEvents: {
    id: string;
    topic: WebsocketTopic;
    cb: CallbackFn;
    validate?: ValidateFn;
}[] = [];

let connectionFailures = 0;

const onWebsocketMessage = (e: MessageEvent) => {
    const allData = JSON.parse(e.data) as WebsocketPayload;
    const { message } = allData;

    socketEvents.forEach((event) => {
        if (message === event.topic) {
            if (event.validate && !event.validate(allData)) {
                return;
            }

            event.cb(allData);
        }
    });
};

const onWebsocketError = (e: Event) => {
    connectionFailures++;

    closeWebSocket();

    if (connectionFailures >= 5) {
        const warning = `Connection to ${WSS_URL} failed after 5 tries.`;

        console.warn(warning, e);

        bugsnagClient?.notify(new Error(warning));

        void confirm(
            'There seems to be an issue with your connection.<br/>We recommend to reload the application to re-authenticate your account.',
            {
                title: 'Connection problem',
                confirmText: 'Reload',
                action: async () => location.reload()
            }
        );
    } else {
        setTimeout(connect, 200);
    }
};

const onWebsocketClose = (_e: CloseEvent) => {
    closeWebSocket();
    connect();
};

const onWebsocketConnect = () => {
    connectionFailures = 0;
};

export function createWebSocket() {
    const { apiKey } = storage.get();

    if (socket && !socket.url.includes(apiKey)) {
        closeWebSocket();
    }

    if (!socket) {
        socket = new WebSocket(`${WSS_URL}?token=${apiKey}`);

        socket.addEventListener('connect', onWebsocketConnect, false);
        socket.addEventListener('message', onWebsocketMessage, false);
        socket.addEventListener('error', onWebsocketError, false);
        socket.addEventListener('close', onWebsocketClose, false);
    }
}

export function closeWebSocket() {
    if (socket) {
        socket.removeEventListener('connect', onWebsocketConnect);
        socket.removeEventListener('message', onWebsocketMessage);
        socket.removeEventListener('error', onWebsocketError);
        socket.removeEventListener('close', onWebsocketClose);

        socket.close();
        socket = undefined;
    }
}

function addSubscription<D = any>(
    topic: WebsocketTopic,
    cb: CallbackFn<D>,
    validate?: ValidateFn<D>
): Subscription {
    const id = uuid();

    socketEvents.push({
        id,
        cb,
        topic,
        validate
    });

    return () => {
        socketEvents = socketEvents.filter((e) => e.id !== id);
    };
}
