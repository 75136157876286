import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { Path } from '../paths';
import { EnhancedAnyObject } from './AnyObject';

export type EnhancedPathResolverForm = FormState<{
    acl: FieldStateAcl;
    path: FieldState<Path>;
    entityId: FieldState<string | undefined>;
    version: FieldState<number>;
    payload: FieldState<EnhancedAnyObject | undefined>;
}>;

export class EnhancedPathResolver extends EnhancedEntity<EnhancedPathResolverForm> {
    /* public acl!: EnhancedAcl; */
    public path!: Path;
    public entityId!: string | undefined;
    public version!: number;
    public payload!: EnhancedAnyObject | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            path: observable,
            entityId: observable,
            version: observable,
            payload: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            acl: this.acl,
            path: this.path,
            entityId: this.entityId,
            version: this.version,
            payload: this.payload,
        };
    }

    public initForm() {
        this.form = new FormState({
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            path: new FieldState<Path>(this.path),
            entityId: new FieldState<string | undefined>(this.entityId),
            version: new FieldState<number>(this.version),
            payload: new FieldState<EnhancedAnyObject | undefined>(
                this.payload === undefined || this.payload === null
                    ? undefined
                    : new EnhancedAnyObject(this.payload, { initForm: true }),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
