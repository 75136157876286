import { observer } from 'mobx-react';
import * as React from 'react';
import { navigate } from 'takeme';
import { AppStore } from '../app/store';
import { TabItem, Tabs } from '../components';
import { injectTSDI } from '../core/utils';

export const SettingsNav = observer(() => {
    const { route } = injectTSDI(AppStore);

    return (
        <Tabs onChange={navigate}>
            <TabItem
                selected={route === '/settings/pool'}
                value="/settings/pool"
            >
                Pool
            </TabItem>
            <TabItem
                selected={route === '/settings/leadstories'}
                value="/settings/leadstories"
            >
                Leadstories
            </TabItem>
            <TabItem
                selected={route === '/settings/authors'}
                value="/settings/authors"
            >
                Authors
            </TabItem>
            <TabItem
                selected={route === '/settings/tags'}
                value="/settings/tags"
            >
                Tags
            </TabItem>
            <TabItem
                selected={
                    route === '/settings/projects' ||
                    route === '/settings/projects/:id'
                }
                value="/settings/projects"
            >
                Projects
            </TabItem>
            <TabItem
                selected={
                    route === '/settings/users' ||
                    route === '/settings/users/:id'
                }
                value="/settings/users"
            >
                Users
            </TabItem>
            <TabItem
                selected={
                    route === '/settings/media/:type' ||
                    route === '/settings/media/:type/:id'
                }
                value="/settings/media/print"
            >
                Media
            </TabItem>
            <TabItem
                selected={route === '/settings/mails'}
                value="/settings/mails"
            >
                E-Mails
            </TabItem>
            <TabItem
                selected={
                    route === '/settings/companies' ||
                    route === '/settings/companies/:id'
                }
                value="/settings/companies"
            >
                Companies
            </TabItem>
            <TabItem
                selected={
                    route === '/settings/roles' ||
                    route === '/settings/roles/:id'
                }
                value="/settings/roles"
            >
                Roles
            </TabItem>
        </Tabs>
    );
});
