import {
    Approval,
    EnhancedEntityLoadOptions,
    EnhancedFile,
    EnhancedItemOnline,
    EnhancedItemOnlineForm,
    EnhancedItemPrint,
    EnhancedItemPrintForm,
    EnhancedItemSocial,
    EnhancedItemTvradio,
    EnhancedItemUpdate,
    Item,
    ItemOnline,
    ItemOnlineFromJSON,
    ItemOnlineJSON,
    ItemPrint,
    ItemPrintFromJSON,
    ItemPrintJSON,
    ItemSocial,
    ItemSocialFromJSON,
    ItemSocialJSON,
    ItemTvradio,
    ItemTvradioFromJSON,
    ItemTvradioJSON,
    ItemsOnlineGetRequest,
    ItemsOnlineIdsGetRequest,
    ItemsPrintGetRequest,
    ItemsPrintIdsGetRequest,
    ItemsPrintonlineGetRequest,
    ItemsPrintonlineIdsGetRequest,
    ItemsSocialGetRequest,
    ItemsSocialIdsGetRequest,
    ItemsTvradioGetRequest,
    ItemsTvradioIdsGetRequest,
    ItemsUpdatesGetRequest,
    Rating,
    RecursivePartial,
    SortingType,
    Tag,
    Viewmode,
    enhanceEntity,
    form2Entity,
    getHeader,
    isApprovableItemOnline,
    isApprovableItemPrint,
    isPrintItem,
    matchItem,
    requiredItemOnlinePropsForApproval,
    requiredItemPrintPropsForApproval
} from '@reportroyal/api';
import { client, getNewId, withPage } from './api';

export interface ItemFilter {
    approval: Approval;
    media?: string[];
    genre?: string[];
    rating?: Rating[];
    search?: string;
    from?: Date;
    to?: Date;
    itemIds?: string[];
    updatedAt?: Date;
    sortBy?: SortingType;
}

export async function loadPrintItems(
    config: ItemsPrintGetRequest,
    options: { depth?: number; metadata?: boolean } = { depth: 0 }
) {
    const items = withPage(await client.itemsPrintGet(config));

    const { page } = items;

    const enhancedItems = items.map<EnhancedItemPrint>((item) =>
        enhanceEntity(item, options)
    );

    if (options.metadata) {
        try {
            await Promise.all(
                enhancedItems.map((item) =>
                    Promise.all(
                        item.images.map((image) => image.loadMetadata())
                    )
                )
            );
        } catch (e) {
            console.error(e);
        }
    }

    return withPage(enhancedItems, page);
}

export async function loadOnlineItems(
    config: ItemsOnlineGetRequest,
    options: { depth?: number; metadata?: boolean } = { depth: 0 }
) {
    const items = withPage(await client.itemsOnlineGet(config));
    const { page } = items;

    const enhancedItems = items.map<EnhancedItemOnline>((item) =>
        enhanceEntity(item, options)
    );

    if (options.metadata) {
        await Promise.all(
            enhancedItems.map((item) =>
                Promise.all(item.images.map((image) => image.loadMetadata()))
            )
        );
    }

    return withPage(enhancedItems, page);
}

export async function loadSocialItems(
    config: ItemsSocialGetRequest,
    options: { depth?: number; metadata?: boolean } = { depth: 0 }
) {
    const items = withPage(await client.itemsSocialGet(config));
    const { page } = items;

    const enhancedItems = items.map<EnhancedItemSocial>((item) =>
        enhanceEntity(item, options)
    );

    if (options.metadata) {
        await Promise.all(
            enhancedItems.map((item) =>
                Promise.all(item.images.map((image) => image.loadMetadata()))
            )
        );
    }

    return withPage(enhancedItems, page);
}

export async function loadTvradioItems(
    config: ItemsTvradioGetRequest,
    options: { depth?: number; metadata?: boolean } = { depth: 0 }
) {
    const items = withPage(await client.itemsTvradioGet(config));
    const { page } = items;

    const enhancedItems = items.map<EnhancedItemTvradio>((item) =>
        enhanceEntity(item, options)
    );

    if (options.metadata) {
        await Promise.all(
            enhancedItems.map((item) =>
                Promise.all(item.images.map((image) => image.loadMetadata()))
            )
        );
    }

    return withPage(enhancedItems, page);
}

export async function loadPrintOnlineItems(
    config: ItemsPrintonlineGetRequest,
    options: { depth?: number; metadata?: boolean } = { depth: 0 }
) {
    const items = withPage(await client.itemsPrintonlineGet(config));
    const { page } = items;

    const enhancedItems = items.map<EnhancedItemPrint>((item) =>
        enhanceEntity(item, options)
    );

    if (options.metadata) {
        await Promise.all(
            enhancedItems.map((item) =>
                Promise.all(item.images.map((image) => image.loadMetadata()))
            )
        );
    }

    return withPage(enhancedItems, page);
}

export async function loadPrintItem(
    id: string,
    options: EnhancedEntityLoadOptions = { depth: 0 }
): Promise<EnhancedItemPrint> {
    const v = options.force ? '0' : undefined;

    const item = await client.itemPrintGet({ id, v });

    return enhanceEntity<EnhancedItemPrint>(item, options);
}

export async function loadOnlineItem(
    id: string,
    options: EnhancedEntityLoadOptions = { depth: 0 }
): Promise<EnhancedItemOnline> {
    const v = options.force ? '0' : undefined;

    const item = await client.itemOnlineGet({ id, v });

    return enhanceEntity<EnhancedItemOnline>(item, options);
}

export async function loadTvradioItem(
    id: string,
    options: EnhancedEntityLoadOptions = { depth: 0 }
): Promise<EnhancedItemTvradio> {
    const v = options.force ? '0' : undefined;

    const item = await client.itemTvradioGet({ id, v });

    return enhanceEntity<EnhancedItemTvradio>(item, options);
}

export async function loadSocialItem(
    id: string,
    options: EnhancedEntityLoadOptions = { depth: 0 }
): Promise<EnhancedItemSocial> {
    const v = options.force ? '0' : undefined;

    const item = await client.itemSocialGet({ id, v });

    return enhanceEntity<EnhancedItemSocial>(item, options);
}

export async function loadPrintItemIds(config: ItemsPrintIdsGetRequest) {
    return client.itemsPrintIdsGet(config);
}

export async function loadOnlineItemIds(config: ItemsOnlineIdsGetRequest) {
    return client.itemsOnlineIdsGet(config);
}

export async function loadSocialItemIds(config: ItemsSocialIdsGetRequest) {
    return client.itemsSocialIdsGet(config);
}

export async function loadTvradioItemIds(config: ItemsTvradioIdsGetRequest) {
    return client.itemsTvradioIdsGet(config);
}

export async function loadPrintOnlineItemIds(
    config: ItemsPrintonlineIdsGetRequest
) {
    return client.itemsPrintonlineIdsGet(config);
}

export async function loadItemImagesMetadata(item: Item): Promise<void> {
    if (item.images && item.images.length) {
        await Promise.all(item.images.map((image) => image.loadMetadata()));
    }
}

export function putItemPrint(form: EnhancedItemPrintForm) {
    return client.itemsPrintPut({ itemPrint: form2Entity(form) });
}

export function postItemPrint(form: EnhancedItemPrintForm) {
    return client.itemsPrintPost({ itemPrint: form2Entity(form as any) });
}

export function deleteItemPrint(id: string) {
    return client.itemsPrintDelete({ id });
}

export function putItemOnline(form: EnhancedItemOnlineForm) {
    return client.itemsOnlinePut({ itemOnline: form2Entity(form as any) });
}

export function postItemOnline(form: EnhancedItemPrintForm) {
    return client.itemsOnlinePost({ itemOnline: form2Entity(form as any) });
}

export function deleteItemOnline(id: string) {
    return client.itemsOnlineDelete({ id });
}

export function putItemSocial(form: EnhancedItemOnlineForm) {
    return client.itemsSocialPut({ itemSocial: form2Entity(form as any) });
}

export function postItemSocial(form: EnhancedItemPrintForm) {
    return client.itemsSocialPost({ itemSocial: form2Entity(form as any) });
}

export function deleteItemSocial(id: string) {
    return client.itemsSocialDelete({ id });
}

export function putItemTvradio(form: EnhancedItemOnlineForm) {
    return client.itemsTvradioPut({ itemTvradio: form2Entity(form as any) });
}

export function postItemTvradio(form: EnhancedItemPrintForm) {
    return client.itemsTvradioPost({ itemTvradio: form2Entity(form as any) });
}

export function deleteItemTvradio(id: string) {
    return client.itemsTvradioDelete({ id });
}

export async function loadItemUpdates(config: ItemsUpdatesGetRequest = {}) {
    const items = await client.itemsUpdatesGet(config);

    return items.map<EnhancedItemUpdate>((item) => enhanceEntity(item));
}

export function emptyItemPrint(
    props: RecursivePartial<ItemPrint> = {}
): EnhancedItemPrint {
    return enhanceEntity(
        ItemPrintFromJSON({
            id: getNewId('ItemPrint'),
            createdAt: new Date(),
            updatedAt: new Date(),
            approval: 'wip',
            leadstory: '',
            date: undefined,
            cover: false,
            inset: false,
            quote: {},
            images: [],
            tags: [],
            authors: [],
            genres: [],
            ...props
        } as ItemPrintJSON),
        { new: true, initForm: true }
    );
}

export function emptyItemOnline(
    props: RecursivePartial<ItemOnline> = {}
): EnhancedItemOnline {
    return enhanceEntity(
        ItemOnlineFromJSON({
            id: getNewId('ItemOnline'),
            createdAt: new Date(),
            updatedAt: new Date(),
            headline: '',
            url: '',
            quote: {},
            approval: 'wip',
            images: [],
            tags: [],
            authors: [],
            genres: [],
            date: undefined,
            ...props
        } as ItemOnlineJSON),
        { new: true, initForm: true }
    );
}

export function emptyItemSocial(
    props: RecursivePartial<ItemSocial> = {}
): EnhancedItemSocial {
    return enhanceEntity(
        ItemSocialFromJSON({
            id: getNewId('ItemSocial'),
            createdAt: new Date(),
            updatedAt: new Date(),
            url: '',
            quote: {},
            approval: 'wip',
            images: [],
            tags: [],
            authors: [],
            genres: [],
            date: undefined,
            ...props
        } as ItemSocialJSON),
        { new: true, initForm: true }
    );
}

export function emptyItemTvradio(
    props: RecursivePartial<ItemTvradio> = {}
): EnhancedItemTvradio {
    return enhanceEntity(
        ItemTvradioFromJSON({
            id: getNewId('ItemTvradio'),
            createdAt: new Date(),
            updatedAt: new Date(),
            headline: '',
            url: '',
            quote: {},
            approval: 'wip',
            images: [],
            tags: [],
            authors: [],
            genres: [],
            date: undefined,
            ...props
        } as ItemTvradioJSON),
        { new: true, initForm: true }
    );
}

export function isApprovable(item?: Item, imageIndex?: number): boolean {
    if (!item) {
        return false;
    }

    return matchItem(item, {
        print: (item) => {
            const { form } = item;

            if (!form) {
                return isApprovableItemPrint(item);
            }

            const images = form.$.images.value;
            const imagesAreTagged =
                imageIndex !== undefined && images.length && images[imageIndex]
                    ? isImageTagged(images[imageIndex])
                    : images.every(isImageTagged);
            const imagesLength = Boolean(images.length);

            return (
                requiredItemPrintPropsForApproval.every(
                    (prop) => form.$[prop].value
                ) &&
                imagesAreTagged &&
                imagesLength
            );
        },
        online: (item) => {
            const { form } = item;

            if (!form) {
                return isApprovableItemOnline(item);
            }

            return requiredItemOnlinePropsForApproval.every(
                (prop) => form.$[prop].value
            );
        },
        social: (_item) => {
            // TODO: implement
            return false;
        },
        tvradio: (item) => {
            const { form } = item;

            if (!form) {
                return isApprovableItemOnline(item);
            }

            return requiredItemOnlinePropsForApproval.every(
                (prop) => form.$[prop].value
            );
        }
    });
}

export function isTagged(item?: EnhancedItemPrint, imageIndex?: number) {
    if (!isPrintItem(item)) {
        return false;
    }

    const { form } = item;
    const images = form ? form.$.images.value : item.images;

    return imageIndex !== undefined && images.length && images[imageIndex]
        ? isImageTagged(images[imageIndex])
        : images.every(isImageTagged);
}

export function getApprovalFromViewmode(viewmode?: Viewmode): Approval {
    switch (viewmode) {
        default:
        case 'dashboard':
        case 'live':
            return 'live';
        case 'wip':
            return 'wip';
        case 'killed':
            return 'killed';
    }
}

function isImageTagged(image: EnhancedFile) {
    if (image.isMetadataLoaded) {
        return Boolean(getHeader<Tag>(image, 'tag'));
    }

    return false;
}
