import {
    AuthorPut,
    AuthorsGetRequest,
    EnhancedMediaType,
    LeadstoriesGetRequest,
    LeadstoryPut,
    StatsItemsApprovalGetRequest,
    StatsItemsMediatypesGetRequest,
    StatsOverviewGetRequest,
    TagPut,
    TagsGetRequest,
    TranslatePostRequest,
    UrlMeta,
    UrlMetaGetRequest,
    enhanceEntity
} from '@reportroyal/api';
import { client } from './api';
import { getFile } from './image';

export async function loadAuthors(params?: AuthorsGetRequest) {
    return client.authorsGet(params ?? {});
}

export async function editAuthor(authorPut: AuthorPut) {
    return client.authorPut({ authorPut });
}

export async function deleteAuthor(author: string) {
    return client.authorDelete({ author });
}

export async function loadLeadstories(params: LeadstoriesGetRequest = {}) {
    return client.leadstoriesGet(params);
}

export async function editLeadstory(leadstoryPut: LeadstoryPut) {
    return client.leadstoryPut({ leadstoryPut });
}

export async function deleteLeadstory(leadstory: string) {
    return client.leadstoryDelete({ leadstory });
}

export async function loadTags(params: TagsGetRequest = {}) {
    return client.tagsGet(params);
}

export async function editTag(tagPut: TagPut) {
    return client.tagPut({ tagPut });
}

export async function deleteTag(tag: string) {
    return client.tagDelete({ tag });
}

export async function loadStatsOverview(options: StatsOverviewGetRequest) {
    return client.statsOverviewGet(options);
}

export async function loadStatsItemsApproval(
    options: StatsItemsApprovalGetRequest
) {
    return client.statsItemsApprovalGet(options);
}

export async function loadStatsItemsMediaType(
    options: StatsItemsMediatypesGetRequest
) {
    const res = await client.statsItemsMediatypesGet(options);

    return res.map((item) => ({
        num: item.num,
        mediaType: enhanceEntity<EnhancedMediaType>({ id: item.mediaType })
    }));
}

export async function translate(
    options: TranslatePostRequest['translationPost']
) {
    return client.translatePost({ translationPost: options });
}

export async function crawlUrlMeta(
    options: UrlMetaGetRequest
): Promise<UrlMeta> {
    const data = await client.urlMetaGet(options);
    const image = data.image ? await getFile(data.image as any) : undefined;

    return { ...data, image };
}
