import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { MultiLang } from '../models';
import { Rating } from '../schema';
import { Approval } from '../schema';
import { EnhancedFile } from '../../entities/enhanced-file';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedProject } from './Project';
import { EnhancedMediaOnline } from './MediaOnline';
import { EnhancedMediaGenre } from './MediaGenre';

export type EnhancedItemTvradioForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    date: FieldState<Date | undefined>;
    headline: FieldState<string>;
    url: FieldState<string>;
    rating: FieldState<Rating>;
    starRating: FieldState<number | undefined>;
    quote: FieldState<MultiLang>;
    approval: FieldState<Approval>;
    project: FieldState<EnhancedProject>;
    media: FieldState<EnhancedMediaOnline>;
    images: FieldState<EnhancedFile[]>;
    tags: FieldState<string[]>;
    authors: FieldState<string[]>;
    genres: FieldState<EnhancedMediaGenre[]>;
    customTypes: FieldState<string[]>;
}>;

export class EnhancedItemTvradio extends EnhancedEntity<EnhancedItemTvradioForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public date!: Date | undefined;
    public headline!: string;
    public url!: string;
    public rating!: Rating;
    public starRating!: number | undefined;
    public quote!: MultiLang;
    public approval!: Approval;
    public project!: EnhancedProject;
    public media!: EnhancedMediaOnline;
    public images!: EnhancedFile[];
    public tags!: string[];
    public authors!: string[];
    public genres!: EnhancedMediaGenre[];
    public customTypes!: string[];

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            date: observable,
            headline: observable,
            url: observable,
            rating: observable,
            starRating: observable,
            quote: observable,
            approval: observable,
            project: observable,
            media: observable,
            images: observable,
            tags: observable,
            authors: observable,
            genres: observable,
            customTypes: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            date: this.date,
            headline: this.headline,
            url: this.url,
            rating: this.rating,
            starRating: this.starRating,
            quote: this.quote,
            approval: this.approval,
            project: this.project,
            media: this.media,
            images: this.images,
            tags: this.tags,
            authors: this.authors,
            genres: this.genres,
            customTypes: this.customTypes,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            date: new FieldState<Date | undefined>(
                this.date === undefined || this.date === null
                    ? undefined
                    : new Date(this.date),
            ),
            headline: new FieldState<string>(this.headline),
            url: new FieldState<string>(this.url),
            rating: new FieldState<Rating>(this.rating),
            starRating: new FieldState<number | undefined>(this.starRating),
            quote: new FieldState<MultiLang>({ ...this.quote }),
            approval: new FieldState<Approval>(this.approval),
            project: new FieldState<EnhancedProject>(
                new EnhancedProject(this.project, { initForm: true }),
            ),
            media: new FieldState<EnhancedMediaOnline>(
                new EnhancedMediaOnline(this.media, { initForm: true }),
            ),
            images: new FieldState<EnhancedFile[]>(
                (this.images || []).map((item) => new EnhancedFile(item)),
            ),
            tags: new FieldState<string[]>(this.tags || []),
            authors: new FieldState<string[]>(this.authors || []),
            genres: new FieldState<EnhancedMediaGenre[]>(
                (this.genres || []).map(
                    (item) => new EnhancedMediaGenre(item, { initForm: true }),
                ),
            ),
            customTypes: new FieldState<string[]>(this.customTypes || []),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
