import * as React from 'react';
import styled, { css } from 'styled-components';

interface TabsProps {
    className?: string;
    children?: React.ReactNode;
    onChange(value: any): void;
}

const StyledTabs = styled.div`
    display: flex;
    border-bottom: 1px solid #94999d;
    position: relative;
    padding-right: 50px;
    overflow-x: auto;
`;

export const Tabs = (props: TabsProps) => {
    const { onChange, children, className } = props;

    return (
        <StyledTabs className={className}>
            {React.Children.map(
                children,
                (child: any) =>
                    child && React.cloneElement(child, { onClick: onChange })
            )}
        </StyledTabs>
    );
};

interface TabItemProps extends StyledTabItemProps {
    className?: string;
    value: string;
    children?: React.ReactNode;
}

interface StyledTabItemProps {
    selected?: boolean;
    onClick?(value: any): void;
}

const selectedCss = css`
    border-bottom: 2px solid #94999d;

    &:hover {
        background: inherit;
    }
`;

const StyledTabItem = styled.button<StyledTabItemProps>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    background: transparent;
    position: relative;
    max-width: 175px;
    white-space: nowrap;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        background: #f8f8f8;
    }

    ${(props) => (props.selected ? selectedCss : '')};
`;

export const TabItem = (props: TabItemProps) => {
    const { className, children, selected } = props;

    function onClick(): void {
        props.onClick?.(props.value);
    }

    return (
        <StyledTabItem
            type="button"
            className={className}
            selected={selected}
            onClick={onClick}
        >
            {children}
        </StyledTabItem>
    );
};
