const numberFormatter = new Intl.NumberFormat(['en-US']);
const currencyFormatter = new Intl.NumberFormat(['de-DE'], {
    currency: 'EUR',
    style: 'currency',
    currencyDisplay: 'symbol'
});

export function int(val: string): number {
    return parseInt(val, 10);
}

export function formatNumber(val?: number): string {
    if (!val) {
        return '-';
    }

    return numberFormatter.format(val);
}

export function formatCurrency(val): string {
    if (!val) {
        return '-';
    }

    return currencyFormatter.format(val);
}

export function isNumeric(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
