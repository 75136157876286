import './vendor/modernizr-webp';
import { EnhanceImageImageFormat } from '@reportroyal/api';

export let webpSupported = false;

(window['Modernizr'] as any).on('webp', result => {
    webpSupported = Boolean(result);
});

export function getDefaultImageFormat(): EnhanceImageImageFormat {
    return webpSupported ? 'webp' : 'jpg';
}
