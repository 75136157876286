import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { storage } from '../aws';

const bugsnagClient = bugsnag.start({
    apiKey: '49eec2fa28b156c6cb5f711d2d85702f',
    collectUserIp: false,
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
        const { me } = storage.get();

        if (me) {
            event.addMetadata('user', {
                id: me.id,
                name: `${me.firstname} ${me.lastname}`,
                email: me.email
            });
        }
    }
});

const Bugsnag = bugsnag.getPlugin('react')!.createErrorBoundary(React);

export { Bugsnag, bugsnagClient };
