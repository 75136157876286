import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedFileContentForm = FormState<{
    content: FieldState<string>;
    url: FieldState<string>;
}>;

export class EnhancedFileContent extends EnhancedEntity<EnhancedFileContentForm> {
    public content!: string;
    public url!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            content: observable,
            url: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { content: this.content, url: this.url };
    }

    public initForm() {
        this.form = new FormState({
            content: new FieldState<string>(this.content),
            url: new FieldState<string>(this.url),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
