import { FieldState } from 'formstate';
import { isObservableArray } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
    LabelComponentProps,
    Option,
    SelectProps as ReactSlctProps,
    Select
} from 'react-slct';
import { SelectLabel } from 'react-slct/src/label';
import styled from 'styled-components';
import { Label } from '../label';
import { Layout } from '../layout';

type SelectProps<T> = Omit<ReactSlctProps<T>, 'value'>;

interface DefaultSelectProps<T = any> extends SelectProps<T> {
    value: T | FieldState<T>;
    label?: string;
    width?: string;
}

const StyledSelect = styled(Select)<{ width?: string }>`
    width: ${(props) => props.width || '100%'};

    &.react-slct[disabled] .react-slct-value {
        cursor: not-allowed;
        background: #f9f9f9;
    }

    &.react-slct .react-slct-value {
        max-height: 250px;
        overflow-y: auto;
        align-items: flex-start;

        .value-right {
            position: sticky;
            top: calc(50% - 8px);
        }
    }
`;

export const DefaultSelect = observer((props: DefaultSelectProps) => {
    const { label, value, ...restProps } = props;

    function onChange(newValue: any, option: Option) {
        if (value instanceof FieldState) {
            value.onChange(newValue);
        }

        if (props.onChange) {
            props.onChange(newValue, option);
        }
    }

    function getValue() {
        if (value instanceof FieldState) {
            return isObservableArray(value.value)
                ? value.value.slice()
                : value.value;
        } else {
            return isObservableArray(value) ? value.slice() : value;
        }
    }

    if (label) {
        return (
            <Label asDiv text={label}>
                <DefaultSelect {...restProps} value={value} />
            </Label>
        );
    }

    return (
        <StyledSelect
            hideSelectedOptions={props.multi}
            {...restProps}
            value={getValue()}
            onChange={onChange}
        />
    );
});

const Suggestion = styled.span`
    font-size: 70%;
    text-transform: uppercase;
`;

export const AuthorLabelComponent = (
    props: LabelComponentProps & { suggestion?: boolean }
) => {
    if (props.type === 'option') {
        return (
            <Layout valign="center" halign="space-between" stretch>
                <SelectLabel>{props.label}</SelectLabel>
                {props.suggestion && <Suggestion>suggestion</Suggestion>}
            </Layout>
        );
    }
    return <SelectLabel>{props.label}</SelectLabel>;
};
