import { IconType } from '@reportroyal/api';
import * as React from 'react';
import './assets/icons.css';

interface IconProps {
    className?: string;
    type: IconType;
    children?: React.ReactNode;
}

export const Icon = (props: IconProps) => {
    return <i className={`${props.className || ''} icon icon-${props.type}`} />;
};
