import { IconType } from '@reportroyal/api';
import { FieldState } from 'formstate';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Option, Select } from 'react-slct';
import styled from 'styled-components';
import { Icon } from '../icon';
import { Layout } from '../layout';

interface StarRatingProps {
    numStars: number;
    rating?: number | FieldState<number | undefined>;
    disabled?: boolean;
    children?: React.ReactNode;
    onChange?(rating: number): void;
}

const Container = styled(Layout)`
    position: relative;
    width: 100%;
`;

const StarContainer = styled(Layout)`
    position: relative;
`;

const Star = styled(Icon)`
    font-size: 22px;
`;

const TileContainer = styled.div`
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
`;

const Tile = styled.div`
    height: 100%;
    cursor: pointer;
`;

export const StarRating = observer((props: StarRatingProps) => {
    const [stateRating, setStateRating] = React.useState<number | undefined>();
    const [blockHover, setBlockHover] = React.useState(false);
    const { numStars } = props;

    const rating = (() => {
        if (typeof stateRating === 'number') {
            return stateRating;
        }

        if (props.rating instanceof FieldState) {
            return props.rating.value || 0;
        }

        return props.rating || 0;
    })();

    function onMouseOverRating(e: React.SyntheticEvent<HTMLDivElement>) {
        if (!blockHover && !props.disabled) {
            const { currentTarget } = e;
            const rating = parseFloat(
                currentTarget.getAttribute('data-rating')!
            );

            setStateRating(rating);
        }
    }

    const onMouseLeave = React.useCallback(
        debounce(
            () => {
                if (!props.disabled) {
                    setStateRating(undefined);
                }
            },
            300,
            { trailing: true }
        ),
        [props.disabled]
    );

    function onChangeSelectRating(rating: number) {
        if (!props.disabled) {
            if (props.rating instanceof FieldState) {
                props.rating.onChange(rating);
            }

            props.onChange?.(rating);
        }
    }

    function onChangeStarRating(e: React.SyntheticEvent<HTMLDivElement>) {
        if (!props.disabled) {
            const { currentTarget } = e;
            const rating = parseFloat(
                currentTarget.getAttribute('data-rating')!
            );

            setStateRating(undefined);
            setBlockHover(true);

            if (props.rating instanceof FieldState) {
                props.rating.onChange(rating);
            }

            props.onChange?.(rating);

            setTimeout(() => setBlockHover(false), 1000);
        }
    }

    function renderSelect() {
        const options: Option[] = Array(numStars)
            .fill(undefined)
            .map((_, i) => i + 1)
            .map((num) => ({
                label: numStars === 100 ? `${num}%` : `${num}`,
                value: num
            }));

        return (
            <Select
                options={options}
                value={rating}
                onChange={onChangeSelectRating}
                disabled={props.disabled}
                placeholder="Please select a star rating..."
            />
        );
    }

    function renderStars() {
        const pWidth = Math.round((100 / (numStars * 2)) * 100) / 100;

        return (
            <StarContainer
                data-role="star-rating"
                data-role-value={rating}
                padding="8"
            >
                <Layout between="4">
                    {Array(numStars)
                        .fill(null)
                        .reduce((memo, _, i) => {
                            let type: IconType;

                            if (i + 0.5 === rating) {
                                type = 'star-half';
                            } else if (i + 1 <= rating) {
                                type = 'star-full';
                            } else {
                                type = 'star-empty';
                            }

                            return [...memo, <Star key={i} type={type} />];
                        }, [])}
                </Layout>
                <TileContainer onMouseLeave={onMouseLeave}>
                    <Tile
                        data-rating={0}
                        style={{ width: 5 }}
                        onMouseEnter={onMouseOverRating}
                        onClick={onChangeStarRating}
                    />
                    {Array(numStars)
                        .fill(null)
                        .map((_, i) => (
                            <React.Fragment key={i}>
                                <Tile
                                    data-rating={i + 0.5}
                                    style={{ width: `${pWidth}%` }}
                                    onMouseEnter={onMouseOverRating}
                                    onClick={onChangeStarRating}
                                />
                                <Tile
                                    data-rating={i + 1}
                                    style={{ width: `${pWidth}%` }}
                                    onMouseEnter={onMouseOverRating}
                                    onClick={onChangeStarRating}
                                />
                            </React.Fragment>
                        ))}
                </TileContainer>
            </StarContainer>
        );
    }

    return (
        <Container>
            {props.numStars > 10 ? renderSelect() : renderStars()}
        </Container>
    );
});
