import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { MediaPrintPrices } from '../models';
import { MediaItemMediaPrint } from '../schema';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedMediaType } from './MediaType';
import { EnhancedMediaFormat } from './MediaFormat';
import { EnhancedMediaReleaseFreq } from './MediaReleaseFreq';

export type EnhancedMediaPrintForm = FormState<{
    id: FieldState<string>;
    type: FieldState<MediaItemMediaPrint>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    title: FieldState<string>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    ivw: FieldState<number>;
    maxStarRating: FieldState<number>;
    relevance: FieldState<number>;
    mediaType: FieldState<EnhancedMediaType>;
    mediaFormat: FieldState<EnhancedMediaFormat>;
    mediaReleaseFreq: FieldState<EnhancedMediaReleaseFreq>;
    releaseDay: FieldState<Date | undefined>;
    releaseJan: FieldState<Date | undefined>;
    releaseFeb: FieldState<Date | undefined>;
    releaseMar: FieldState<Date | undefined>;
    releaseApr: FieldState<Date | undefined>;
    releaseMay: FieldState<Date | undefined>;
    releaseJun: FieldState<Date | undefined>;
    releaseJul: FieldState<Date | undefined>;
    releaseAug: FieldState<Date | undefined>;
    releaseSep: FieldState<Date | undefined>;
    releaseOct: FieldState<Date | undefined>;
    releaseNov: FieldState<Date | undefined>;
    releaseDec: FieldState<Date | undefined>;
    prices: FieldState<MediaPrintPrices | undefined>;
    pmgShortcut: FieldState<string[]>;
    group: FieldState<string | undefined>;
    active: FieldState<boolean>;
    temp: FieldState<boolean | undefined>;
    migrateId: FieldState<number | undefined>;
}>;

export class EnhancedMediaPrint extends EnhancedEntity<EnhancedMediaPrintForm> {
    /* public id!: string; */
    public type!: MediaItemMediaPrint;
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public title!: string;
    public createdAt!: Date;
    public updatedAt!: Date;
    public ivw!: number;
    public maxStarRating!: number;
    public relevance!: number;
    public mediaType!: EnhancedMediaType;
    public mediaFormat!: EnhancedMediaFormat;
    public mediaReleaseFreq!: EnhancedMediaReleaseFreq;
    public releaseDay!: Date | undefined;
    public releaseJan!: Date | undefined;
    public releaseFeb!: Date | undefined;
    public releaseMar!: Date | undefined;
    public releaseApr!: Date | undefined;
    public releaseMay!: Date | undefined;
    public releaseJun!: Date | undefined;
    public releaseJul!: Date | undefined;
    public releaseAug!: Date | undefined;
    public releaseSep!: Date | undefined;
    public releaseOct!: Date | undefined;
    public releaseNov!: Date | undefined;
    public releaseDec!: Date | undefined;
    public prices!: MediaPrintPrices | undefined;
    public pmgShortcut!: string[];
    public group!: string | undefined;
    public active!: boolean;
    public temp!: boolean | undefined;
    public migrateId!: number | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            type: observable,
            version: observable,
            title: observable,
            createdAt: observable,
            updatedAt: observable,
            ivw: observable,
            maxStarRating: observable,
            relevance: observable,
            mediaType: observable,
            mediaFormat: observable,
            mediaReleaseFreq: observable,
            releaseDay: observable,
            releaseJan: observable,
            releaseFeb: observable,
            releaseMar: observable,
            releaseApr: observable,
            releaseMay: observable,
            releaseJun: observable,
            releaseJul: observable,
            releaseAug: observable,
            releaseSep: observable,
            releaseOct: observable,
            releaseNov: observable,
            releaseDec: observable,
            prices: observable,
            pmgShortcut: observable,
            group: observable,
            active: observable,
            temp: observable,
            migrateId: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            type: this.type,
            version: this.version,
            acl: this.acl,
            title: this.title,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            ivw: this.ivw,
            maxStarRating: this.maxStarRating,
            relevance: this.relevance,
            mediaType: this.mediaType,
            mediaFormat: this.mediaFormat,
            mediaReleaseFreq: this.mediaReleaseFreq,
            releaseDay: this.releaseDay,
            releaseJan: this.releaseJan,
            releaseFeb: this.releaseFeb,
            releaseMar: this.releaseMar,
            releaseApr: this.releaseApr,
            releaseMay: this.releaseMay,
            releaseJun: this.releaseJun,
            releaseJul: this.releaseJul,
            releaseAug: this.releaseAug,
            releaseSep: this.releaseSep,
            releaseOct: this.releaseOct,
            releaseNov: this.releaseNov,
            releaseDec: this.releaseDec,
            prices: this.prices,
            pmgShortcut: this.pmgShortcut,
            group: this.group,
            active: this.active,
            temp: this.temp,
            migrateId: this.migrateId,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            type: new FieldState<MediaItemMediaPrint>(this.type),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            title: new FieldState<string>(this.title),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            ivw: new FieldState<number>(this.ivw),
            maxStarRating: new FieldState<number>(this.maxStarRating),
            relevance: new FieldState<number>(this.relevance),
            mediaType: new FieldState<EnhancedMediaType>(
                new EnhancedMediaType(this.mediaType, { initForm: true }),
            ),
            mediaFormat: new FieldState<EnhancedMediaFormat>(
                new EnhancedMediaFormat(this.mediaFormat, { initForm: true }),
            ),
            mediaReleaseFreq: new FieldState<EnhancedMediaReleaseFreq>(
                new EnhancedMediaReleaseFreq(this.mediaReleaseFreq, {
                    initForm: true,
                }),
            ),
            releaseDay: new FieldState<Date | undefined>(
                this.releaseDay === undefined || this.releaseDay === null
                    ? undefined
                    : new Date(this.releaseDay),
            ),
            releaseJan: new FieldState<Date | undefined>(
                this.releaseJan === undefined || this.releaseJan === null
                    ? undefined
                    : new Date(this.releaseJan),
            ),
            releaseFeb: new FieldState<Date | undefined>(
                this.releaseFeb === undefined || this.releaseFeb === null
                    ? undefined
                    : new Date(this.releaseFeb),
            ),
            releaseMar: new FieldState<Date | undefined>(
                this.releaseMar === undefined || this.releaseMar === null
                    ? undefined
                    : new Date(this.releaseMar),
            ),
            releaseApr: new FieldState<Date | undefined>(
                this.releaseApr === undefined || this.releaseApr === null
                    ? undefined
                    : new Date(this.releaseApr),
            ),
            releaseMay: new FieldState<Date | undefined>(
                this.releaseMay === undefined || this.releaseMay === null
                    ? undefined
                    : new Date(this.releaseMay),
            ),
            releaseJun: new FieldState<Date | undefined>(
                this.releaseJun === undefined || this.releaseJun === null
                    ? undefined
                    : new Date(this.releaseJun),
            ),
            releaseJul: new FieldState<Date | undefined>(
                this.releaseJul === undefined || this.releaseJul === null
                    ? undefined
                    : new Date(this.releaseJul),
            ),
            releaseAug: new FieldState<Date | undefined>(
                this.releaseAug === undefined || this.releaseAug === null
                    ? undefined
                    : new Date(this.releaseAug),
            ),
            releaseSep: new FieldState<Date | undefined>(
                this.releaseSep === undefined || this.releaseSep === null
                    ? undefined
                    : new Date(this.releaseSep),
            ),
            releaseOct: new FieldState<Date | undefined>(
                this.releaseOct === undefined || this.releaseOct === null
                    ? undefined
                    : new Date(this.releaseOct),
            ),
            releaseNov: new FieldState<Date | undefined>(
                this.releaseNov === undefined || this.releaseNov === null
                    ? undefined
                    : new Date(this.releaseNov),
            ),
            releaseDec: new FieldState<Date | undefined>(
                this.releaseDec === undefined || this.releaseDec === null
                    ? undefined
                    : new Date(this.releaseDec),
            ),
            prices: new FieldState<MediaPrintPrices | undefined>(
                this.prices ? { ...this.prices } : undefined,
            ),
            pmgShortcut: new FieldState<string[]>(this.pmgShortcut || []),
            group: new FieldState<string | undefined>(this.group),
            active: new FieldState<boolean>(this.active),
            temp: new FieldState<boolean | undefined>(this.temp),
            migrateId: new FieldState<number | undefined>(this.migrateId),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
