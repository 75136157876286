import { FieldState } from 'formstate';
import * as React from 'react';
import styled from 'styled-components';
import { Button } from '../button';
import { Icon } from '../icon';
import { Label } from '../label';
import { Textfield, TextfieldProps } from '../textfield';

interface PasswordProps<T = any> extends TextfieldProps<T> {
    showGenerator?: boolean;
    children?: React.ReactNode;
    onGenerate?(value: T): void;
}

const Container = styled.div`
    position: relative;
    width: 100%;
`;

const PasswordField = styled(Textfield)<{ showGenerator?: boolean }>`
    padding-right: ${(props) => (props.showGenerator ? '60px' : '30px')};
`;

const ShowButton = styled(Button)`
    position: absolute;
    right: 1px;
    top: 1px;
    height: 95%;
`;

const KeyButton = styled(Button)`
    position: absolute;
    right: 35px;
    top: 1px;
    height: 95%;
`;

export const Password = (props: PasswordProps) => {
    const { label, showGenerator, value, onChange, onGenerate } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    function onPasswordToggle(): void {
        setShowPassword((val) => !val);
    }

    function onGeneratePassword(): void {
        setShowPassword(true);
        const pw = Math.random().toString(36).substr(2, 10);

        if (value instanceof FieldState) {
            value.onChange(pw);
        }

        onChange?.(pw);
        onGenerate?.(pw);
    }

    if (label) {
        const { label, ...rest } = props;

        return (
            <Label text={label}>
                <Password {...rest} />
            </Label>
        );
    }

    return (
        <Container>
            <PasswordField
                {...props}
                password={!showPassword}
                showGenerator={showGenerator}
                label={undefined}
            />
            {showGenerator && (
                <KeyButton
                    borderless
                    title="Generate password"
                    onClick={onGeneratePassword}
                >
                    <Icon type="key" />
                </KeyButton>
            )}
            <ShowButton
                borderless
                title={showPassword ? 'Hide password' : 'Reveal password'}
                onClick={onPasswordToggle}
            >
                <Icon type={showPassword ? 'eye' : 'eye-blocked'} />
            </ShowButton>
        </Container>
    );
};
