import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { EnhancedMediaType } from './MediaType';

export type EnhancedStatsItemsMediaTypeForm = FormState<{
    num: FieldState<number>;
    mediaType: FieldState<EnhancedMediaType>;
}>;

export class EnhancedStatsItemsMediaType extends EnhancedEntity<EnhancedStatsItemsMediaTypeForm> {
    public num!: number;
    public mediaType!: EnhancedMediaType;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            num: observable,
            mediaType: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { num: this.num, mediaType: this.mediaType };
    }

    public initForm() {
        this.form = new FormState({
            num: new FieldState<number>(this.num),
            mediaType: new FieldState<EnhancedMediaType>(
                new EnhancedMediaType(this.mediaType, { initForm: true }),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
