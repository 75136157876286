import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { link } from 'takeme';
import { Layout, Link } from '../components';
import { useTSDI } from '../core/utils';
import { AppStore } from './store';

const year = new Date().getFullYear();

const Container = styled(Layout)`
    position: relative;
    margin-top: 40px;
`;

const Links = styled(Layout)`
    position: absolute;
    right: 10px;
    top: 0;
`;

export const Footer = observer(() => {
    const { mobile, loggedIn } = useTSDI(AppStore);

    return (
        <Container as="footer">
            <Layout halign={mobile ? 'space-between' : 'center'}>
                © {year} RR Media UG
            </Layout>
            <Links between="8">
                {loggedIn && (
                    <>
                        <Link href={link('/help')}>Help</Link>
                        <span>|</span>
                    </>
                )}
                <Link href={link('/legal/imprint')}>
                    Imprint | Privacy Policy
                </Link>
            </Links>
        </Container>
    );
});
