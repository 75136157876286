import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface SpinnerProps {
    margin?: string;
    color?: string;
    top?: string;
    size?: 'sm' | 'md';
    children?: React.ReactNode;
}

const rotate = keyframes`
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
    }
`;

function getSize(props: SpinnerProps): any {
    switch (props.size) {
        case 'sm':
            return css`
                width: 14px;
                height: 14px;
                border-width: 2px;
            `;
        case 'md':
        default:
            return css`
                width: 24px;
                height: 24px;
                border-width: 3px;
            `;
    }
}

export const Spinner = styled.div<SpinnerProps>`
    border-radius: 50%;
    margin: ${(props: SpinnerProps) => props.margin || '0 auto'};
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-style: solid;
    border-color: ${(props: SpinnerProps) =>
        props.color || 'rgba(78, 78, 78, 0.9)'};
    border-left-color: transparent;
    position: relative;
    top: ${(props: SpinnerProps) => props.top || 0};
    transform: translateZ(0);
    ${getSize};
    animation: ${rotate} 1.1s infinite linear;

    &:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
`;
