import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { MediaItemMediaFormat } from '../schema';
import { EnhancedAcl } from '../../entities/enhanced-acl';

export type EnhancedMediaFormatForm = FormState<{
    id: FieldState<string>;
    type: FieldState<MediaItemMediaFormat>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    name: FieldState<string>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    widthMM: FieldState<number | undefined>;
    heightMM: FieldState<number | undefined>;
    migrateId: FieldState<number | undefined>;
}>;

export class EnhancedMediaFormat extends EnhancedEntity<EnhancedMediaFormatForm> {
    /* public id!: string; */
    public type!: MediaItemMediaFormat;
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public name!: string;
    public createdAt!: Date;
    public updatedAt!: Date;
    public widthMM!: number | undefined;
    public heightMM!: number | undefined;
    public migrateId!: number | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            type: observable,
            version: observable,
            name: observable,
            createdAt: observable,
            updatedAt: observable,
            widthMM: observable,
            heightMM: observable,
            migrateId: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            type: this.type,
            version: this.version,
            acl: this.acl,
            name: this.name,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            widthMM: this.widthMM,
            heightMM: this.heightMM,
            migrateId: this.migrateId,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            type: new FieldState<MediaItemMediaFormat>(this.type),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            name: new FieldState<string>(this.name),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            widthMM: new FieldState<number | undefined>(this.widthMM),
            heightMM: new FieldState<number | undefined>(this.heightMM),
            migrateId: new FieldState<number | undefined>(this.migrateId),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
