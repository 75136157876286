import * as React from 'react';
import styled from 'styled-components';
import { WrapRequest } from 'wrap-request';
import { ErrorMessage } from '../../core/error-message';
import { Layout } from '../layout';
import { Textfield } from '../textfield';

interface LoginProps {
    children?: React.ReactNode;
    username: string;
    password: string;
    loginAction: WrapRequest<void, void, any, any>;
    onChangeUsername(username: string): void;
    onChangePassword(pw: string): void;
}

const Message = styled.p`
    margin: 20px 0 !important;
`;

const Space = styled.div`
    height: 8px;
`;

export function Login(props: LoginProps) {
    return (
        <Layout stack between="8">
            <Message>{props.children}</Message>
            <Textfield
                autoFocus
                email
                required
                autocomplete
                placeholder="E-Mail..."
                data-role="username"
                value={props.username}
                onChange={props.onChangeUsername}
            />
            <Textfield
                placeholder="Password..."
                password
                required
                value={props.password}
                data-role="password"
                onChange={props.onChangePassword}
            />
            <ErrorMessage wrapRequest={props.loginAction} />
            <Space />
        </Layout>
    );
}
