import moment from 'moment';

export function dateFormat(
    date: Date | string | number | undefined | null,
    format: string
) {
    if (!date) {
        return undefined;
    }

    return moment(date).format(format);
}

export function dateFormatForm(
    date: Date | string | number | undefined | null
) {
    if (!date) {
        return '';
    }

    return moment(date).format('YYYY-MM-DD');
}

export function startOfDay(date: Date | string | number) {
    return moment(date).startOf('day').toDate();
}

export function startOfWeek(date: Date | string | number) {
    return moment(date).startOf('week').toDate();
}
export function startOfMonth(date: Date | string | number) {
    return moment(date).startOf('month').toDate();
}

export function endOfDay(date: Date | string | number) {
    return moment(date).endOf('day').toDate();
}

export function endOfWeek(date: Date | string | number) {
    return moment(date).endOf('week').toDate();
}

export function isToday(date: Date | string | number) {
    return moment(date).isSame(new Date(), 'day');
}

export function subDays(date: Date | string | number, amount: number) {
    return moment(date).subtract(amount, 'days').toDate();
}

export function addHours(date: Date | string | number, amount: number) {
    return moment(date).add(amount, 'hours').toDate();
}

export function addDays(date: Date | string | number, amount: number) {
    return moment(date).add(amount, 'days').toDate();
}

export function addMonths(date: Date | string | number, amount: number) {
    return moment(date).add(amount, 'months').toDate();
}

export function addWeeks(date: Date | string | number, amount: number) {
    return moment(date).add(amount, 'weeks').toDate();
}

export function getWeek(date: Date | string | number) {
    return moment(date).get('isoWeek');
}

export function textfieldDateFormat(value: any) {
    if (isNaN(value)) {
        return '';
    }

    return dateFormat(value, 'YYYY-MM-DD') || '';
}
