import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { ItemUpdateDataItem } from '../models';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedCompany } from './Company';
import { EnhancedUser } from './User';
import { EnhancedMail } from './Mail';

export type EnhancedItemUpdateForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    company: FieldState<EnhancedCompany>;
    user: FieldState<EnhancedUser>;
    mail: FieldState<EnhancedMail>;
    data: FieldState<ItemUpdateDataItem[]>;
}>;

export class EnhancedItemUpdate extends EnhancedEntity<EnhancedItemUpdateForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public company!: EnhancedCompany;
    public user!: EnhancedUser;
    public mail!: EnhancedMail;
    public data!: ItemUpdateDataItem[];

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            company: observable,
            user: observable,
            mail: observable,
            data: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            company: this.company,
            user: this.user,
            mail: this.mail,
            data: this.data,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            company: new FieldState<EnhancedCompany>(
                new EnhancedCompany(this.company, { initForm: true }),
            ),
            user: new FieldState<EnhancedUser>(
                new EnhancedUser(this.user, { initForm: true }),
            ),
            mail: new FieldState<EnhancedMail>(
                new EnhancedMail(this.mail, { initForm: true }),
            ),
            data: new FieldState<ItemUpdateDataItem[]>(
                (this.data || []).map((item) => ({ ...item })),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
