import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedStatsItemsApprovalForm = FormState<{
    wip: FieldState<number>;
    live: FieldState<number>;
    killed: FieldState<number>;
}>;

export class EnhancedStatsItemsApproval extends EnhancedEntity<EnhancedStatsItemsApprovalForm> {
    public wip!: number;
    public live!: number;
    public killed!: number;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            wip: observable,
            live: observable,
            killed: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { wip: this.wip, live: this.live, killed: this.killed };
    }

    public initForm() {
        this.form = new FormState({
            wip: new FieldState<number>(this.wip),
            live: new FieldState<number>(this.live),
            killed: new FieldState<number>(this.killed),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
