import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedUser } from './User';
import { EnhancedProject } from './Project';
import { EnhancedProjectType } from './ProjectType';

export type EnhancedUserProjectTypeForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    user: FieldState<EnhancedUser>;
    project: FieldState<EnhancedProject>;
    types: FieldState<EnhancedProjectType[]>;
}>;

export class EnhancedUserProjectType extends EnhancedEntity<EnhancedUserProjectTypeForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public user!: EnhancedUser;
    public project!: EnhancedProject;
    public types!: EnhancedProjectType[];

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            user: observable,
            project: observable,
            types: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            user: this.user,
            project: this.project,
            types: this.types,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            user: new FieldState<EnhancedUser>(
                new EnhancedUser(this.user, { initForm: true }),
            ),
            project: new FieldState<EnhancedProject>(
                new EnhancedProject(this.project, { initForm: true }),
            ),
            types: new FieldState<EnhancedProjectType[]>(
                (this.types || []).map(
                    (item) => new EnhancedProjectType(item, { initForm: true }),
                ),
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
