import * as React from 'react';
import styled, { css, IStyledComponent } from 'styled-components';

interface LabelProps {
    className?: string;
    text?: string;
    asDiv?: boolean;
    invalid?: boolean;
    children?: React.ReactNode;
}

const invalidCSS = css`
    color: red;
`;

const StyledLabel = styled.label`
    display: flex;
    width: 100%;
    ${(props: LabelProps) => (props.invalid ? invalidCSS : '')};
`;

const StyledDiv = styled.div`
    display: flex;
    width: 100%;
    ${(props: LabelProps) => (props.invalid ? invalidCSS : '')};
`;

const LabelText = styled.span`
    flex: 0 0 90px;
    display: flex;
    margin: 2px 0 0 0;
    font-size: 12px;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Label = (props: LabelProps) => {
    const { text, asDiv, children } = props;
    const Container: IStyledComponent<any, LabelProps> = asDiv
        ? StyledDiv
        : StyledLabel;

    return (
        <Container className={props.className}>
            {text !== undefined && <LabelText>{text}</LabelText>}
            {children}
        </Container>
    );
};
