import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FileHeaders } from '../models';
import { S3Bucket } from '@reportroyal/s3';
import { EnhancedS3Tag } from './S3Tag';

export type EnhancedModelFileForm = FormState<{
    bucket: FieldState<S3Bucket>;
    key: FieldState<string>;
    headers: FieldState<FileHeaders | undefined>;
    tags: FieldState<EnhancedS3Tag[] | undefined>;
    url: FieldState<string>;
    path: FieldState<string>;
}>;

export class EnhancedModelFile extends EnhancedEntity<EnhancedModelFileForm> {
    public bucket!: S3Bucket;
    /* public key!: string; */
    public headers!: FileHeaders | undefined;
    public tags!: EnhancedS3Tag[] | undefined;
    public url!: string;
    public path!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            bucket: observable,
            key: observable,
            headers: observable,
            tags: observable,
            url: observable,
            path: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            bucket: this.bucket,
            key: this.key,
            headers: this.headers,
            tags: this.tags,
            url: this.url,
            path: this.path,
        };
    }

    public initForm() {
        this.form = new FormState({
            bucket: new FieldState<S3Bucket>(this.bucket),
            key: new FieldState<string>(this.key),
            headers: new FieldState<FileHeaders | undefined>(
                this.headers ? { ...this.headers } : undefined,
            ),
            tags: new FieldState<EnhancedS3Tag[] | undefined>(
                (this.tags || []).map(
                    (item) => new EnhancedS3Tag(item, { initForm: true }),
                ),
            ),
            url: new FieldState<string>(this.url),
            path: new FieldState<string>(this.path),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
