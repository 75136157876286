import {
    ApiClient,
    ApiError,
    AWS_API,
    config,
    Configuration,
    DefaultApi,
    Item,
    matchItem,
    MongoEntity,
    Path,
    userSpecificRoutes
} from '@reportroyal/api';
import { v1 as uuid } from 'uuid';
import { connect, getUserCache, storage } from './auth';
import { loadCompany, postCompany, putCompany } from './company';
import { getFile } from './image';
import {
    deleteItemOnline,
    deleteItemPrint,
    deleteItemSocial,
    deleteItemTvradio,
    loadOnlineItem,
    loadPrintItem,
    loadSocialItem,
    loadTvradioItem,
    postItemOnline,
    postItemPrint,
    postItemSocial,
    postItemTvradio,
    putItemOnline,
    putItemPrint,
    putItemSocial,
    putItemTvradio
} from './items';
import {
    loadMediaFormat,
    loadMediaGenre,
    loadMediaItemOnline,
    loadMediaItemPrint,
    loadMediaReleaseFreq,
    loadMediaTypeOnline,
    loadMediaTypePrint,
    postMediaOnline,
    postMediaPrint,
    putMediaOnline,
    putMediaPrint
} from './media';
import { deleteNotification } from './notifications';
import {
    deleteProject,
    loadProject,
    loadProjectType,
    postProject,
    putProject
} from './project';
import {
    deleteRole,
    deleteUserProjectType,
    loadUser,
    postRole,
    postUser,
    postUserProjectType,
    putRole,
    putUser,
    putUserProjectType
} from './user';

function isRequestOpts(obj: any): obj is ApiClient.RequestOpts {
    return obj.path;
}

function getRequestKey(
    context: ApiClient.RequestOpts | ApiClient.ResponseContext
): string {
    if (isRequestOpts(context)) {
        return `${context.method}:${context.path}`;
    } else {
        const url = new URL(context.url);
        const path = url.pathname.replace(/^\/api/, '');

        return `${context.init.method}:${path}`;
    }
}

ApiClient.JSONApiResponse.prototype.value = async function () {
    const { headers } = this.raw;
    const json = await this.raw.json();

    const pageResults = headers.get('X-Page-Results');
    const pageSkip = headers.get('X-Page-Skip');
    const pageLimit = headers.get('X-Page-Limit');
    const media = headers.get('X-Media') ?? '[]';
    const genres = headers.get('X-Genres') ?? '[]';

    if (pageResults && pageSkip && pageLimit) {
        json.page = {
            results: parseInt(pageResults, 10),
            skip: parseInt(pageSkip, 10),
            limit: parseInt(pageLimit, 10),
            media: JSON.parse(media),
            genres: JSON.parse(genres)
        } as PageResult;
    }

    return json;
};

// @ts-ignore
ApiClient.BaseAPI.prototype.request = async function (context) {
    requestStore.set(getRequestKey(context), context);

    // @ts-ignore
    const { url, init } = this.createFetchParams(context);
    // @ts-ignore
    const response: Response = await this.fetchApi(url, init);

    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const json = await response.json();

    throw new ApiError(json.message, response.status, json.data);
};

const requestStore = new Map<string, ApiClient.RequestOpts>();

// use for simulating request errors
let forceRequestError: number | null = null;

export const DEFAULT_ERROR =
    'An unexpected error occured. We are informed about that.';

export let client: DefaultApi;

function headersToObj(headers: Headers | { [key: string]: string }) {
    const h: { [key: string]: string } = {};

    if (headers instanceof Headers) {
        // @ts-ignore
        const keyVals = [...headers];

        keyVals.forEach(([key, val]) => {
            h[key] = val;
        });

        return h;
    }

    return headers;
}

function queryParamsStringify(params: any, prefix = ''): string {
    return Object.keys(params)
        .map((key) => {
            const fullKey = prefix + (prefix.length ? `[${key}]` : key);
            let value = params[key];

            if (value instanceof Array) {
                const multiValue = value
                    .map((singleValue) =>
                        encodeURIComponent(String(singleValue))
                    )
                    .join(`&${encodeURIComponent(fullKey)}=`);

                return `${encodeURIComponent(fullKey)}=${multiValue}`;
            } else if (value instanceof Date) {
                value = value.toISOString();
            } else if (value instanceof Object) {
                return queryParamsStringify(value, fullKey);
            }

            // prettier-ignore
            return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
        })
        .filter((part) => part.length > 0)
        .join('&');
}

function pathIsPublic(_path: Path) {
    return false; // path === '/user/me' || path === '/health';
}

async function middlewarePre(context: ApiClient.RequestContext) {
    const path = context.url.replace(AWS_API, '').replace(/(\?.*)/, '') as Path;
    const isPublic = pathIsPublic(path);

    if (context.init.method === 'GET') {
        if (isPublic) {
            return context;
        }

        const userSpecificRoute = userSpecificRoutes.includes(path);
        let { c } = userSpecificRoute ? getUserCache() : { c: undefined };

        if (path === '/user/me' && !c) {
            // DUMMY
            c = btoa(`/db/User/1 ${new Date().toISOString()}`);
        }

        const params = [
            c ? `c=${encodeURIComponent(c)}` : null,
            forceRequestError ? `forceErrorCode=${forceRequestError}` : null
        ]
            .filter((s) => Boolean(s))
            .join('&');
        const sep = params.length ? (/\?/.test(context.url) ? '&' : '?') : '';

        context.url += `${sep}${params}`;
    }

    return context;
}

async function middlewarePost(context: ApiClient.ResponseContext) {
    const { status } = context.response;

    if (status === 401) {
        await connect();

        const { apiKey } = storage.get();
        const headers = context.init.headers as
            | {
                  [key: string]: string;
              }
            | undefined;

        if (headers && apiKey) {
            headers.Authorization = apiKey;
        }
    }

    if (status === 401 || status >= 500) {
        const response = await retryRequest(context);

        if (response) {
            return response;
        }
    }

    requestStore.delete(getRequestKey(context));

    return context.response;
}

async function retryRequest(context: ApiClient.ResponseContext) {
    forceRequestError = null;

    const requestKey = getRequestKey(context);
    const requestContext = requestStore.get(requestKey);

    if (requestContext && context.init.headers) {
        requestContext.headers = headersToObj(context.init.headers as Headers);

        // @ts-ignore
        const xhr = ApiClient.BaseAPI.prototype.request.call(
            client,
            requestContext
        );

        requestStore.delete(requestKey);

        return xhr;
    }

    return undefined;
}

export function createApiClient() {
    if (client) {
        return client;
    }

    const config = new Configuration({
        basePath: AWS_API,
        apiKey: () => storage.get().apiKey ?? '',
        middleware: [
            {
                pre: middlewarePre,
                post: middlewarePost
            }
        ],
        queryParamsStringify
    });

    client = new DefaultApi(config);

    return client;
}

export function getNewId(entity: MongoEntity) {
    return `/db/${entity}/${uuid()}`;
}

export function getNewIdByItem(item: Item) {
    return matchItem(item, {
        online: () => getNewId('ItemOnline'),
        print: () => getNewId('ItemPrint'),
        social: () => getNewId('ItemSocial'),
        tvradio: () => getNewId('ItemTvradio')
    });
}

export interface Page {
    page?: PageResult;
}

export interface PageResult {
    results: number;
    skip: number;
    limit: number;
    media: string[];
    genres: string[];
}

export function withPage<T = any>(items: T[], page?: PageResult): T[] & Page {
    if (page) {
        Object.assign(items, { page });
    }

    return items as any;
}

config.crud = {
    create: {
        Company: postCompany,
        ItemPrint: postItemPrint,
        ItemOnline: postItemOnline,
        ItemSocial: postItemSocial,
        ItemTvradio: postItemTvradio,
        MediaOnline: postMediaOnline,
        MediaPrint: postMediaPrint,
        Role: postRole,
        Project: postProject,
        UserProjectType: postUserProjectType,
        User: postUser
    },
    read: {
        Company: loadCompany,
        ItemPrint: loadPrintItem,
        ItemOnline: loadOnlineItem,
        ItemSocial: loadSocialItem,
        ItemTvradio: loadTvradioItem,
        Project: loadProject,
        ProjectType: loadProjectType,
        MediaOnline: loadMediaItemOnline,
        MediaPrint: loadMediaItemPrint,
        MediaFormat: loadMediaFormat,
        MediaGenre: loadMediaGenre,
        MediaType: loadMediaTypePrint,
        MediaTypeOnline: loadMediaTypeOnline,
        MediaReleaseFreq: loadMediaReleaseFreq,
        User: loadUser
    },
    update: {
        Company: putCompany,
        ItemPrint: putItemPrint,
        ItemOnline: putItemOnline,
        ItemSocial: putItemSocial,
        ItemTvradio: putItemTvradio,
        MediaOnline: putMediaOnline,
        MediaPrint: putMediaPrint,
        Project: putProject,
        Role: putRole,
        User: putUser,
        UserProjectType: putUserProjectType
    },
    delete: {
        ItemPrint: deleteItemPrint,
        ItemOnline: deleteItemOnline,
        ItemSocial: deleteItemSocial,
        ItemTvradio: deleteItemTvradio,
        Notification: deleteNotification,
        Role: deleteRole,
        Project: deleteProject,
        UserProjectType: deleteUserProjectType
    }
};
config.getFile = getFile;
