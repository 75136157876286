import { FileHeaders } from '@reportroyal/api';
import { client } from './api';

export function getFile(key: string) {
    return client.fileGet({ key });
}

export function putMetadata(key: string, metadata: FileHeaders) {
    return client.fileMetadataPut({
        key,
        fileHeaders: metadata
    });
}
