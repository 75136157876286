import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedTagPutForm = FormState<{
    oldTag: FieldState<string>;
    newTag: FieldState<string>;
}>;

export class EnhancedTagPut extends EnhancedEntity<EnhancedTagPutForm> {
    public oldTag!: string;
    public newTag!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            oldTag: observable,
            newTag: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { oldTag: this.oldTag, newTag: this.newTag };
    }

    public initForm() {
        this.form = new FormState({
            oldTag: new FieldState<string>(this.oldTag),
            newTag: new FieldState<string>(this.newTag),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
