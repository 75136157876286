import { lighten } from 'polished';
import styled from 'styled-components';

interface BadgeProps {
    upper?: boolean;
    color?: 'grey' | 'blue' | 'light-blue';
}

const getColor = (props: BadgeProps) => {
    switch (props.color) {
        default:
        case 'blue':
            return '#22c1f2';
        case 'light-blue':
            return lighten(0.3, '#22c1f2');
        case 'grey':
            return '#c4a986';
    }
};

export const Badge = styled.div<BadgeProps>`
    background: ${getColor};
    background-clip: padding-box;
    border: 0.1rem solid #fff;
    border-radius: 1rem;
    color: #fff;
    display: inline-block;
    font-size: 1.1rem;
    height: 1.8rem;
    line-height: 1.2rem;
    min-width: 1.8rem;
    padding: 2px 6px;
    text-align: center;
    transform: ${(props: BadgeProps) =>
        props.upper ? 'translate(-0.2rem, -0.8rem)' : 'none'};
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
`;
