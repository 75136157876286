import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { EnhancedFile } from '../../entities/enhanced-file';
import { EnhancedAcl } from '../../entities/enhanced-acl';

export type EnhancedCompanyForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    name: FieldState<string>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    image: FieldState<EnhancedFile | undefined>;
    archived: FieldState<boolean | undefined>;
}>;

export class EnhancedCompany extends EnhancedEntity<EnhancedCompanyForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public name!: string;
    public createdAt!: Date;
    public updatedAt!: Date;
    public image!: EnhancedFile | undefined;
    public archived!: boolean | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            name: observable,
            createdAt: observable,
            updatedAt: observable,
            image: observable,
            archived: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            name: this.name,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            image: this.image,
            archived: this.archived,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            name: new FieldState<string>(this.name),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            image: new FieldState<EnhancedFile | undefined>(
                this.image === undefined || this.image === null
                    ? undefined
                    : new EnhancedFile(this.image),
            ),
            archived: new FieldState<boolean | undefined>(this.archived),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
