import {
    EnhancedPdf,
    enhanceEntity,
    FileContent,
    PdfGenerateConfig
} from '@reportroyal/api';
import { client } from './api';

interface AWSError {
    errorMessage: string;
    errorType: string;
    stackTrace: string[];
}

export async function loadPdf(id: string): Promise<EnhancedPdf> {
    const item = await client.pdfGet({ id });

    return enhanceEntity<EnhancedPdf>(item);
}

export async function loadPdfContent(pdfId: string): Promise<FileContent> {
    return client.fileContentGet({ pdfId });
}

export async function generatePdf(config: PdfGenerateConfig, v2: boolean) {
    const item = v2
        ? await client.pdfGeneratePost({ pdfGenerateConfig: config })
        : await client.pdfPost({ pdfGenerateConfig: config });

    return enhanceEntity<EnhancedPdf>(item);
}

export async function validatePdfItems(config: PdfGenerateConfig) {
    return client.pdfValidateItemsPost({
        pdfGenerateConfig: config
    });
}

export async function generateBulkImages(config: PdfGenerateConfig) {
    return client.bulkImagesPost({ pdfGenerateConfig: config });
}

export function isAWSError(message?: AWSError | any): message is AWSError {
    return message && Boolean((message as AWSError).errorMessage);
}
