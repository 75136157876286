import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { EnhancedFile } from '../../entities/enhanced-file';

export type EnhancedPoolForm = FormState<{
    id: FieldState<string>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    image: FieldState<EnhancedFile>;
}>;

export class EnhancedPool extends EnhancedEntity<EnhancedPoolForm> {
    /* public id!: string; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public image!: EnhancedFile;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            createdAt: observable,
            updatedAt: observable,
            image: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            image: this.image,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            image: new FieldState<EnhancedFile>(new EnhancedFile(this.image)),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
