import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { IconType } from '../schema';
import { EnhancedFile } from '../../entities/enhanced-file';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedUser } from './User';

export type EnhancedNotificationForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    text: FieldState<string>;
    link: FieldState<string>;
    icon: FieldState<IconType>;
    image: FieldState<EnhancedFile>;
    user: FieldState<EnhancedUser>;
    expire: FieldState<number>;
}>;

export class EnhancedNotification extends EnhancedEntity<EnhancedNotificationForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public createdAt!: Date;
    public updatedAt!: Date;
    public text!: string;
    public link!: string;
    public icon!: IconType;
    public image!: EnhancedFile;
    public user!: EnhancedUser;
    public expire!: number;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            createdAt: observable,
            updatedAt: observable,
            text: observable,
            link: observable,
            icon: observable,
            image: observable,
            user: observable,
            expire: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            text: this.text,
            link: this.link,
            icon: this.icon,
            image: this.image,
            user: this.user,
            expire: this.expire,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            text: new FieldState<string>(this.text),
            link: new FieldState<string>(this.link),
            icon: new FieldState<IconType>(this.icon),
            image: new FieldState<EnhancedFile>(new EnhancedFile(this.image)),
            user: new FieldState<EnhancedUser>(
                new EnhancedUser(this.user, { initForm: true }),
            ),
            expire: new FieldState<number>(this.expire),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
