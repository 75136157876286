import { EnhancedFile } from '@reportroyal/api';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { component } from 'tsdi';
import { openModalForCropping } from '../crop-image';

export interface ProcessResult {
    url: string;
    blob: Blob;
}

@component
export class ProcessPdf {
    public async start(file: File): Promise<File[]> {
        const data = await this.read(file);
        const doc = await this.createDocument(data);
        const urls = await Promise.all(
            Array(doc.numPages)
                .fill(null)
                .map(async (_, i) => {
                    const result = await this.renderAsImage(doc, i + 1);

                    return result.url;
                })
        );

        const blobs = await openModalForCropping({ urls, type: 'print' });

        return blobs.map((blob, i) => {
            return new File(
                [blob],
                file.name.replace(/\.pdf$/, `_{${i + 1}}.png`),
                {
                    lastModified: file.lastModified,
                    type: blob.type
                }
            );
        });
    }

    public createGroups(files: EnhancedFile[]): EnhancedFile[][] {
        return files.reduce((memo, newFile) => {
            const fileHeaders = newFile.headers;

            if (fileHeaders['from-pdf']) {
                const found = memo.some((group) => {
                    const file = group.find((groupFile) => {
                        const headers = groupFile.headers;

                        const pageRegEx = /_\{\d+\}/;

                        const fromPdf = headers['from-pdf'] === 'true';
                        const headersTitle = decodeURIComponent(
                            headers.title ?? ''
                        ).replace(pageRegEx, '');
                        const fileHeadersTitle = decodeURIComponent(
                            fileHeaders.title ?? ''
                        ).replace(pageRegEx, '');

                        return fromPdf && headersTitle === fileHeadersTitle;
                    });

                    if (file) {
                        group.push(newFile);

                        return true;
                    } else {
                        return false;
                    }
                });

                if (!found) {
                    memo.push([newFile]);
                }
            }

            return memo;
        }, [] as EnhancedFile[][]);
    }

    private read(file: File) {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = async (e) => {
                const target = e.target as any;

                if (target && target.result) {
                    resolve(target.result);
                } else {
                    reject();
                }
            };

            fileReader.readAsArrayBuffer(file);
        });
    }

    private async createDocument(data: ArrayBuffer) {
        const pdfjs = await import('pdfjs-dist');

        pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

        return pdfjs.getDocument({ data }).promise;
    }

    private renderAsImage(doc: PDFDocumentProxy, pageNum: number) {
        return new Promise<ProcessResult>(async (resolve) => {
            const canvas = document.createElement('canvas');

            const page = await doc.getPage(pageNum);
            const viewport = page.getViewport({ scale: 4 });

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const canvasContext = canvas.getContext('2d')!;
            const task = page.render({
                canvasContext,
                viewport
            });

            task.promise.then(() => {
                canvas.toBlob((blob) => {
                    const url = URL.createObjectURL(blob!);

                    resolve({ url, blob: blob! });

                    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
                    canvas.remove();
                });
            });
        });
    }
}
