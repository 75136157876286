import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedLeadstoryPutForm = FormState<{
    oldLeadstory: FieldState<string>;
    newLeadstory: FieldState<string>;
}>;

export class EnhancedLeadstoryPut extends EnhancedEntity<EnhancedLeadstoryPutForm> {
    public oldLeadstory!: string;
    public newLeadstory!: string;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            oldLeadstory: observable,
            newLeadstory: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            oldLeadstory: this.oldLeadstory,
            newLeadstory: this.newLeadstory,
        };
    }

    public initForm() {
        this.form = new FormState({
            oldLeadstory: new FieldState<string>(this.oldLeadstory),
            newLeadstory: new FieldState<string>(this.newLeadstory),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
