import {
    EnhancedEntity,
    EnhancedEntityOptions,
    EnhancedMediaFormat,
    EnhancedMediaGenre,
    EnhancedMediaOnline,
    EnhancedMediaOnlineForm,
    EnhancedMediaPrint,
    EnhancedMediaPrintForm,
    EnhancedMediaReleaseFreq,
    EnhancedMediaSocial,
    EnhancedMediaType,
    EnhancedMediaTypeOnline,
    EnhancedMediaTypeSocial,
    enhanceEntity,
    form2Entity,
    MediaItemsIdsGetRequest,
    MediaItemsOnlineGetRequest,
    MediaItemsPrintGetRequest,
    MediaItemsSocialGetRequest,
    MediaOnline,
    MediaPrint,
    MediaPrintPrices,
    RecursivePartial
} from '@reportroyal/api';
import { client, getNewId } from './api';

export async function loadMediaItemsPrint(
    options: MediaItemsPrintGetRequest = {}
): Promise<EnhancedMediaPrint[]> {
    const items = await client.mediaItemsPrintGet(options);

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaItemPrint(id: string) {
    const item = await client.mediaItemPrintGet({
        id
    });

    return enhanceEntity<EnhancedMediaPrint>(item);
}

export async function loadMediaItemsOnline(
    options: MediaItemsOnlineGetRequest = {}
): Promise<EnhancedMediaOnline[]> {
    const items = await client.mediaItemsOnlineGet(options);

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaItemsSocial(
    options: MediaItemsSocialGetRequest = {}
): Promise<EnhancedMediaSocial[]> {
    const items = await client.mediaItemsSocialGet(options);

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaItemOnline(
    id: string,
    options?: EnhancedEntityOptions
) {
    const item = await client.mediaItemOnlineGet({
        id
    });

    return enhanceEntity<EnhancedMediaOnline>(item, options);
}

export async function loadMediaGenre(id: string) {
    const item = await client.mediaGenreGet({ id });

    return enhanceEntity<EnhancedMediaGenre>(item);
}

export async function loadMediaGenres(): Promise<EnhancedMediaGenre[]> {
    const items = await client.mediaGenresGet({});

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaFormats(): Promise<EnhancedMediaFormat[]> {
    const items = await client.mediaFormatsGet({});

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaFormat(id: string) {
    const item = await client.mediaFormatGet({ id });

    return enhanceEntity<EnhancedMediaFormat>(item);
}

export async function loadMediaReleaseFreq(id: string) {
    const item = await client.mediaReleasefreqGet({ id });

    return enhanceEntity<EnhancedMediaReleaseFreq>(item);
}

export async function loadMediaReleaseFreqs(): Promise<
    EnhancedMediaReleaseFreq[]
> {
    const items = await client.mediaReleasefreqsGet();

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaTypePrint(id: string) {
    const item = await client.mediaTypePrintGet({ id });

    return enhanceEntity<EnhancedMediaType>(item);
}

export async function loadMediaTypesPrint(): Promise<EnhancedMediaType[]> {
    const items = await client.mediaTypesPrintGet();

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaTypeOnline(id: string) {
    const item = await client.mediaTypeOnlineGet({ id });

    return enhanceEntity<EnhancedMediaTypeOnline>(item);
}

export async function loadMediaTypesOnline(): Promise<
    EnhancedMediaTypeOnline[]
> {
    const items = await client.mediaTypesOnlineGet();

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaTypesSocial(): Promise<
    EnhancedMediaTypeSocial[]
> {
    const items = await client.mediaTypesSocialGet();

    return items.map((item) => enhanceEntity(item));
}

export async function loadMediaIds(options: MediaItemsIdsGetRequest) {
    return client.mediaItemsIdsGet(options);
}

export function putMediaOnline(form: EnhancedMediaOnlineForm) {
    return client.mediaItemOnlinePut({ mediaOnline: form2Entity(form as any) });
}

export function postMediaOnline(form: EnhancedMediaOnlineForm) {
    return client.mediaItemOnlinePost({
        mediaOnline: form2Entity(form as any)
    });
}

export function postMediaPrint(form: EnhancedMediaPrintForm) {
    return client.mediaItemPrintPost({
        mediaPrint: form2Entity(form as any)
    });
}

export function putMediaPrint(form: EnhancedMediaPrintForm) {
    return client.mediaItemPrintPut({ mediaPrint: form2Entity(form as any) });
}

export function isPrintMedia(obj?: any): obj is EnhancedMediaPrint {
    return obj instanceof EnhancedEntity && obj.Entity === 'MediaPrint';
}

export function isOnlineMedia(obj?: any): obj is EnhancedMediaOnline {
    return obj instanceof EnhancedEntity && obj.Entity === 'MediaOnline';
}

export function emptyMediaPrint(
    obj: RecursivePartial<MediaPrint>
): EnhancedMediaPrint {
    const defaultPrices: MediaPrintPrices = {
        'adv_1-1': 0,
        'adv_1-2': 0,
        'adv_1-3': 0,
        'adv_1-4': 0,
        'adv_1-6': 0,
        'adv_2-1': 0,
        'adv_2-3': 0,
        migrateId: 0,
        mm: 0
    };

    return enhanceEntity(
        {
            id: getNewId('MediaPrint'),
            createdAt: new Date(),
            updatedAt: new Date(),
            ivw: 0,
            maxStarRating: 0,
            relevance: 1,
            pmgShortcut: [],
            active: true,
            prices: defaultPrices,
            ...obj
        },
        { new: true, initForm: true }
    );
}

export function emptyMediaOnline(
    obj: RecursivePartial<MediaOnline>
): EnhancedMediaOnline {
    return enhanceEntity(
        {
            id: getNewId('MediaOnline'),
            createdAt: new Date(),
            updatedAt: new Date(),
            agofUniqueUser: 0,
            maxStarRating: 0,
            relevance: 1,
            active: true,
            ...obj
        },
        { new: true, initForm: true }
    );
}
