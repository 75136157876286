import * as React from 'react';
import styled from 'styled-components';

interface NavProps {
    className?: string;
    children: React.ReactNode;
}

const StyledNav = styled.nav`
    display: flex;
`;

const Ul = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
`;

export const Nav = (props: NavProps) => {
    const { className, children } = props;

    return (
        <StyledNav className={className}>
            <Ul>{children}</Ul>
        </StyledNav>
    );
};

interface NavItemProps {
    href: string;
    active?: boolean;
    children?: React.ReactNode;
    onClick(href: string): void;
}

const A = styled.a<NavItemProps>`
    display: flex;
    padding: 0 12px;
    height: 100%;
    align-items: center;

    background-color: ${(props) => (props.active ? '#eee' : 'transparent')};
`;

export const NavItem = (props: NavItemProps) => {
    const { href, children, active } = props;

    function onClick(e: any): void {
        e.preventDefault();
        props.onClick(href);
    }

    return (
        <li>
            <A href={href} active={active} onClick={onClick}>
                {children}
            </A>
        </li>
    );
};
