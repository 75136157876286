import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

interface CheckboxProps<T = any> {
    value?: T | FieldState<T>;
    checked?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    onChange?(checked: boolean, value: T): void;
}

const Label = styled.label<{ disabled?: boolean }>`
    display: flex;
    user-select: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const Input = styled.input`
    margin-right: 10px;
`;

const Span = styled.span`
    word-break: break-all;
`;

export const Checkbox = observer((props: CheckboxProps) => {
    const { children, disabled, checked, value } = props;

    function onChange(e: React.SyntheticEvent<HTMLInputElement>): void {
        const { checked } = e.currentTarget;

        if (value instanceof FieldState) {
            value.onChange(checked);
        }

        if (props.onChange) {
            props.onChange(checked, value);
        }
    }

    function getValue() {
        if (value instanceof FieldState) {
            return value.value;
        }

        return value;
    }

    return (
        <Label disabled={disabled}>
            <Input
                type="checkbox"
                disabled={disabled}
                checked={checked === undefined ? Boolean(getValue()) : checked}
                onChange={onChange}
            />
            <Span>{children}</Span>
        </Label>
    );
});
