import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedS3TagForm = FormState<{
    Key: FieldState<string | undefined>;
    Value: FieldState<string | undefined>;
}>;

export class EnhancedS3Tag extends EnhancedEntity<EnhancedS3TagForm> {
    public Key!: string | undefined;
    public Value!: string | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            Key: observable,
            Value: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { Key: this.Key, Value: this.Value };
    }

    public initForm() {
        this.form = new FormState({
            Key: new FieldState<string | undefined>(this.Key),
            Value: new FieldState<string | undefined>(this.Value),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
