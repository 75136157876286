import { IconType } from '@reportroyal/api';
import { darken, lighten } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'takeme';
import { Icon } from '../icon';
import { Spinner } from '../spinner';

interface ButtonProps<T = any> extends StyledButtonProps {
    className?: string;
    value?: T;
    submit?: boolean;
    disabled?: boolean;
    loading?: boolean;
    title?: string;
    href?: string;
    'data-role'?: string;
    children?: React.ReactNode;
    onClick?(
        value: T,
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void;
    onMouseDown?(e: React.SyntheticEvent<HTMLButtonElement>): void;
}

interface StyledButtonProps {
    pale?: boolean;
    active?: boolean;
    danger?: boolean;
    secondary?: boolean;
    borderless?: boolean;
    transparent?: boolean;
    bobble?: string | number;
    icon?: IconType;
}

const defaultCSS = css`
    border-color: #3f4d56;
    color: #3f4d56;
    background: #fff;

    &:not(:disabled):hover {
        background: #3f4d56;
        border-color: ${darken(0.2, '#3f4d56')};
        color: #fff;
    }
`;

const dangerCSS = css`
    border-color: #cf4d00;
    color: #fff;
    background: #e85600;

    &:not(:disabled):hover {
        background: ${darken(0.2, '#e85600')};
    }
`;

const secondaryCSS = css`
    border-color: #cfcfcf;
    background: #fff;

    &:not(:disabled):hover {
        background: ${lighten(0.2, '#e85600')};
    }
`;

const activeCSS = css`
    border-color: #3f4d56;
    color: #fff;
    background: #3f4d56;

    &:not(:disabled):hover {
        background: ${darken(0.2, '#3f4d56')};
    }
`;

const paleCSS = css`
    border-color: #3f4d56;
    color: #3f4d56;
    background: #fff;

    &:not(:disabled):hover {
        background: #3f4d56;
        color: #fff;
    }
`;

const bobbleSizeNormal = css`
    right: -8px;
    top: -8px;
    width: 13px;
    height: 13px;
`;

const bobbleSizeBig = css`
    width: 22px;
    height: 22px;
    right: -15px;
    top: -12px;
`;

const StyledButton = styled.button<StyledButtonProps>`
    -webkit-appearance: none;
    border-radius: 0.2rem;
    transition: all 0.15s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    // height: 3.2rem;
    line-height: 1.6rem;
    outline: 0;
    padding: 0.7rem 1rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 0;
    position: relative;
    border: ${(props) => (props.borderless ? 'none' : '0.1rem solid')};

    &:disabled {
        cursor: not-allowed;
        opacity: 0.65;
    }

    &:after {
        content: '${(props: StyledButtonProps) => props.bobble || ''}';
        ${(props: StyledButtonProps) =>
            props.bobble
                ? css`
                      position: absolute;
                      right: -8px;
                      top: -8px;
                      width: 13px;
                      height: 13px;
                      color: #fff;
                      background: #c4a986;
                      padding: 2px;
                      border-radius: 100%;
                      z-index: 1;
                      pointer-events: none;
                      font-size: 11px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                  `
                : ''};
        ${(props: StyledButtonProps) =>
            props.bobble
                ? String(props.bobble).length <= 2
                    ? bobbleSizeNormal
                    : bobbleSizeBig
                : ''}
    }

    ${(props: StyledButtonProps) =>
        props.active
            ? activeCSS
            : props.danger
              ? dangerCSS
              : props.secondary
                ? secondaryCSS
                : props.pale
                  ? paleCSS
                  : defaultCSS};

    ${(props: StyledButtonProps) =>
        props.transparent &&
        css`
            &:not(:hover) {
                background: transparent !important;
            }
        `}
`;

const StyledIcon = styled(Icon)`
    margin-right: 7px;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -7px 0 0 -7px;
`;

const Children = styled.span<{ isloading?: boolean }>`
    visibility: ${(props) => (props.isloading ? 'hidden' : 'visible')};
`;

export function Button<T = any>(props: ButtonProps<T>) {
    const {
        className,
        active,
        children,
        submit,
        disabled,
        danger,
        secondary,
        pale,
        borderless,
        transparent,
        title,
        icon,
        bobble = '',
        onMouseDown
    } = props;
    const type = submit ? 'submit' : 'button';
    const loading = Boolean(props.loading);

    const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        if (props.href) {
            navigate(props.href);
        } else if (props.onClick) {
            props.onClick(props.value!, e);
        }
    };

    return (
        <StyledButton
            className={className}
            borderless={borderless}
            disabled={disabled || loading}
            active={active}
            onClick={onClick}
            onMouseDown={onMouseDown}
            type={type}
            title={title}
            danger={danger}
            secondary={secondary}
            pale={pale}
            bobble={bobble}
            transparent={transparent}
            data-role={props['data-role']}
        >
            {icon && !loading && <StyledIcon type={icon} />}
            {loading && (
                <StyledSpinner
                    color={active ? '#FFFFFF' : '#3F4D56'}
                    size="sm"
                />
            )}
            <Children isloading={loading}>{children}</Children>
        </StyledButton>
    );
}

const StyledButtonGroup = styled.div<ButtonGroupProps>`
    display: flex;
    width: ${(props) => (props.width ? props.width : 'auto')};

    button {
        flex: 1;
        border-right-color: transparent;

        &:last-child {
            border-right-color: inherit;
        }
    }
`;

interface ButtonGroupProps {
    width?: string;
    children?: React.ReactNode;
}

export const ButtonGroup = (props: ButtonGroupProps) => {
    const { children, width } = props;

    return <StyledButtonGroup width={width}>{children}</StyledButtonGroup>;
};

export default Button;
