import { EnhancedFile, FileHeaders } from '@reportroyal/api';

export async function loadImageMetadata(file: File): Promise<FileHeaders> {
    return new Promise<FileHeaders>(resolve => {
        const fr = new FileReader();

        fr.onload = () => {
            var img = new Image();

            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;

                const mediumHeight = 550;
                const mediumWidth = Math.round((mediumHeight / height) * width);

                const smallWidth = 250;
                const smallHeight = Math.round((smallWidth / width) * height);

                resolve({
                    size: {
                        width,
                        height
                    },
                    'size-medium': {
                        width: mediumWidth,
                        height: mediumHeight
                    },
                    'size-small': {
                        width: smallWidth,
                        height: smallHeight
                    }
                });
            };

            img.src = fr.result as string;
        };
        fr.readAsDataURL(file);
    });
}

export function sortImages(
    itemImages: EnhancedFile[],
    imageToMove: EnhancedFile,
    imageBase?: EnhancedFile
): EnhancedFile[] {
    if (!imageBase) {
        // move image to first place
        return itemImages.reduce(
            (memo, image, i) => {
                if (i === 0) {
                    memo.push(imageToMove);
                }

                if (image.id !== imageToMove.id) {
                    memo.push(image);
                }

                return memo;
            },
            [] as EnhancedFile[]
        );
    } else {
        return itemImages.reduce(
            (memo, image) => {
                if (image.id !== imageToMove.id) {
                    memo.push(image);
                }

                if (image.id === imageBase.id) {
                    memo.push(imageToMove);
                }

                return memo;
            },
            [] as EnhancedFile[]
        );
    }
}
