import {
    Acl,
    MediaPrintPrices,
    MultiLang,
    FileHeaders,
    ItemUpdateDataItem,
    S3Tag,
    PdfGenerateConfig,
    PdfData,
    ErrorCause,
} from './models';
import { Path } from './paths';
import { S3Bucket } from '@reportroyal/s3';
import { FitEnum } from 'sharp';

export { FileHeaders, MultiLang, MediaPrintPrices };

export * from './enhancers';

export type Entity = "Company" | "Item" | "ItemSearch" | "ItemStatic" | "ItemStaticUpdate" | "ItemUpdate" | "Mail" | "Media" | "Notification" | "Pdf" | "Project" | "Role" | "URLCache" | "User" | "UserProjectType" | "Websocket";

export type MongoEntity =
    | 'Company'
    | 'ItemHistoryOnline'
    | 'ItemHistoryPrint'
    | 'ItemOnline'
    | 'ItemPrint'
    | 'ItemSocial'
    | 'ItemTvradio'
    | 'ItemUpdate'
    | 'ItemUpdateHistory'
    | 'Mail'
    | 'MediaFormat'
    | 'MediaGenre'
    | 'MediaOnline'
    | 'MediaPrint'
    | 'MediaSocial'
    | 'MediaReleaseFreq'
    | 'MediaSocial'
    | 'MediaType'
    | 'MediaTypeOnline'
    | 'MediaTypeSocial'
    | 'Notification'
    | 'PathResolver'
    | 'Pdf'
    | 'Project'
    | 'ProjectType'
    | 'Role'
    | 'SearchOnline'
    | 'SearchPrint'
    | 'Test'
    | 'URLCache'
    | 'User'
    | 'UserProjectType';

export interface BasicEntity {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
}

export type Nullable<T> = { [P in keyof T]?: T[P] | null };

export type IconType =
    | 'mic'
    | 'copy'
    | 'external'
    | 'fulltag'
    | 'bell'
    | 'calendar'
    | 'settings'
    | 'inbox'
    | 'save'
    | 'fullscreen'
    | 'history'
    | 'trash'
    | 'download'
    | 'plus'
    | 'check'
    | 'logout'
    | 'arrow-right'
    | 'arrow-left'
    | 'search'
    | 'circle'
    | 'clipped'
    | 'eye'
    | 'eye-blocked'
    | 'key'
    | 'mail'
    | 'star-empty'
    | 'star-half'
    | 'star-full'
    | 'translate'
    | 'instagram'
    | 'youtube'
    | 'twitter'
    | 'facebook'
    | 'database'
    | 'view-list'
    | 'view-tile';
export type Rating =
    | 'positive'
    | 'neutral'
    | 'negative'
    | 'mixed'
    | 'positive-mixed'
    | 'negative-mixed';
export type Type = 'print' | 'online' | 'printonline' | 'social' | 'tvradio';
export type Approval = 'wip' | 'live' | 'killed';
export type Viewmode = 'dashboard' | 'wip' | 'live' | 'killed';
export type DisplayType = 'full' | 'list' | 'images' | 'sony-text' | 'excel';
export type SortingType = 'order' | 'date' | 'media' | 'ivw' | 'relevance';
export type MailType = 'daily-update' | 'share' | 'info' | 'test';
export type ItemStaticType = 'Author' | 'Tag' | 'Leadstory';
export type Lang = 'de' | 'en';
export type MediaItemMediaType = 'MediaType';
export type MediaItemMediaTypeOnline = 'MediaTypeOnline';
export type MediaItemMediaTypeSocial = 'MediaTypeSocial';
export type MediaItemMediaGenre = 'MediaGenre';
export type MediaItemMediaReleaseFreq = 'MediaReleaseFreq';
export type MediaItemMediaPrint = 'MediaPrint';
export type MediaItemMediaOnline = 'MediaOnline';
export type MediaItemMediaSocial = 'MediaSocial';
export type MediaItemMediaFormat = 'MediaFormat';
export interface Tag {
    x: number;
    y: number;
    width: number;
    height: number;
}
export type EnhanceImageImageFormat = 'webp' | 'jpg' | 'png';
export interface EnhanceImageQueryParams {
    quality?: number;
    width?: number;
    height?: number;
    blur?: number;
    tint?: string;
    format?: EnhanceImageImageFormat;
    fit?: keyof FitEnum;
    flatten?: string;
    crop?: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
}
export type WebsocketTopic =
    | 'version'
    | 'db.trigger'
    | 'notify'
    | 'bulk-images';

export type UserGroup = 'admin' | 'worker' | 'user' | 'guest';

export interface WebsocketDbTriggerResult<D = any> {
    documentKey: { _id: string };
    entity: Entity | MongoEntity;
    document?: D;
    operationType: 'update' | 'insert' | 'delete';
}

export const WSS_URL =
    'wss://no5yc39gai.execute-api.eu-central-1.amazonaws.com/ws';
export const WSS_URL_HTTPS = WSS_URL.replace(/^wss:\/\//, 'https://');

export interface AnyObjectJSON {
    _u?: string;
}

export interface AuthorPutJSON {
    oldAuthor: string;
    newAuthor: string;
}

export interface BulkImagesResultJSON {
    uuid: string;
}

export interface CompanyJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    name: string;
    createdAt: string;
    updatedAt: string;
    image?: F;
    archived?: boolean;
}

export interface FileContentJSON {
    content: string;
    url: string;
}

export interface ItemOnlineJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    date?: string;
    headline: string;
    url: string;
    rating: string;
    starRating?: number;
    quote: MultiLang;
    approval: string;
    project: string;
    media: string;
    images: F[];
    tags: string[];
    authors: string[];
    genres: string[];
    customTypes: string[];
}

export interface ItemPrintJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    date?: string;
    localEditions?: string;
    rating: string;
    quote: MultiLang;
    approval: string;
    starRating?: number;
    project: string;
    media: string;
    mediaValue: number;
    leadstory?: string;
    cover?: boolean;
    inset?: boolean;
    images: F[];
    tags: string[];
    authors: string[];
    genres: string[];
    customTypes: string[];
}

export interface ItemSocialJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    date?: string;
    url: string;
    rating: string;
    quote: MultiLang;
    approval: string;
    starRating?: number;
    project: string;
    media: string;
    leadstory?: string;
    images: F[];
    authors: string[];
    tags: string[];
    genres: string[];
    customTypes: string[];
}

export interface ItemStaticUpdateJSON {
    type: ItemStaticType;
    oldValue: string;
    newValue?: string;
}

export interface ItemTvradioJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    date?: string;
    headline: string;
    url: string;
    rating: string;
    starRating?: number;
    quote: MultiLang;
    approval: string;
    project: string;
    media: string;
    images: F[];
    tags: string[];
    authors: string[];
    genres: string[];
    customTypes: string[];
}

export interface ItemUpdateJSON {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    company: string;
    user: string;
    mail: string;
    data: ItemUpdateDataItem[];
}

export interface LatestMailJSON {
    date?: string;
}

export interface LeadstoryPutJSON {
    oldLeadstory: string;
    newLeadstory: string;
}

export interface MailJSON {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    type: MailType;
    messageId?: string;
    user: string;
    error?: string;
    to: string;
    from: string;
    subject: string;
    bodyHTML: string;
    bodyText: string;
    projects: string[];
    expire: number;
}

export interface MailNotificationsConfigJSON {
    uuid: string;
    companyId: string;
    NODE_ENV: string;
}

export interface MailNotificationsResultJSON {
    uuid: string;
}

export interface MailQueueConfigJSON {
    uuid: string;
    type: MailType;
    to: string;
    subject: string;
    content: string;
    userId: string;
    retries?: number;
    projects: string[];
}

export interface MediaFormatJSON {
    id: string;
    type: MediaItemMediaFormat;
    version: number;
    acl: Acl;
    name: string;
    createdAt: string;
    updatedAt: string;
    widthMM?: number;
    heightMM?: number;
    migrateId?: number;
}

export interface MediaGenreJSON {
    id: string;
    type: MediaItemMediaGenre;
    version: number;
    acl: Acl;
    name: string;
    createdAt: string;
    updatedAt: string;
}

export interface MediaOnlineJSON {
    id: string;
    type: MediaItemMediaOnline;
    version: number;
    acl: Acl;
    title: string;
    url: string;
    createdAt: string;
    updatedAt: string;
    agofUniqueUser: number;
    maxStarRating: number;
    relevance: number;
    mediaType: string;
    active: boolean;
    migrateId?: number;
}

export interface MediaPrintJSON {
    id: string;
    type: MediaItemMediaPrint;
    version: number;
    acl: Acl;
    title: string;
    createdAt: string;
    updatedAt: string;
    ivw: number;
    maxStarRating: number;
    relevance: number;
    mediaType: string;
    mediaFormat: string;
    mediaReleaseFreq: string;
    releaseDay?: string;
    releaseJan?: string;
    releaseFeb?: string;
    releaseMar?: string;
    releaseApr?: string;
    releaseMay?: string;
    releaseJun?: string;
    releaseJul?: string;
    releaseAug?: string;
    releaseSep?: string;
    releaseOct?: string;
    releaseNov?: string;
    releaseDec?: string;
    prices?: MediaPrintPrices;
    pmgShortcut: string[];
    group?: string;
    active: boolean;
    temp?: boolean;
    migrateId?: number;
}

export interface MediaReleaseFreqJSON {
    id: string;
    type: MediaItemMediaReleaseFreq;
    version: number;
    acl: Acl;
    name: string;
    createdAt: string;
    updatedAt: string;
    migrateId?: number;
}

export interface MediaSocialJSON {
    id: string;
    type: MediaItemMediaSocial;
    version: number;
    acl: Acl;
    title: string;
    url: string;
    createdAt: string;
    updatedAt: string;
    relevance: number;
    active: boolean;
    mediaType: string;
}

export interface MediaTypeJSON {
    id: string;
    type: MediaItemMediaType;
    version: number;
    acl: Acl;
    title: string;
    createdAt: string;
    updatedAt: string;
}

export interface MediaTypeOnlineJSON {
    id: string;
    type: MediaItemMediaTypeOnline;
    version: number;
    acl: Acl;
    title: string;
    createdAt: string;
    updatedAt: string;
}

export interface MediaTypeSocialJSON {
    id: string;
    type: MediaItemMediaTypeSocial;
    version: number;
    acl: Acl;
    title: string;
    createdAt: string;
    updatedAt: string;
}

export interface ModelFileJSON {
    bucket: S3Bucket;
    key: string;
    headers?: FileHeaders;
    tags?: S3Tag[];
    url: string;
    path: string;
}

export interface NotificationJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    text: string;
    link: string;
    icon: string;
    image: F;
    user: string;
    expire: number;
}

export interface PathResolverJSON {
    acl: Acl;
    path: Path;
    entityId?: string;
    version: number;
    payload?: string;
}

export interface PdfJSON {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    user: string;
    pdfUrl?: string;
    htmlUrl?: string;
    xlsUrl?: string;
    error?: string;
    errorCause?: ErrorCause;
    baseKey?: string;
    valid?: boolean;
    progress?: number;
    duration?: number;
    estimate?: number;
    config: PdfGenerateConfig;
    data: PdfData;
    executionArn?: string;
    files?: string[];
    expire: number;
}

export interface PoolJSON<F = string> {
    id: string;
    createdAt: string;
    updatedAt: string;
    image: F;
}

export interface ProjectJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    name: string;
    createdAt: string;
    updatedAt: string;
    image?: F;
    releasedate: string;
    startdate: string;
    enddate: string;
    company: string;
    types: string[];
    lang: string[];
    tags: string[];
    archived: boolean;
    autoArchieveDisabled?: boolean;
}

export interface ProjectTypeJSON {
    id: string;
    version: number;
    acl: Acl;
    name: string;
    createdAt: string;
    updatedAt: string;
    active: boolean;
}

export interface RoleJSON {
    id: string;
    version: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    users: string[];
}

export interface S3TagJSON {
    Key?: string;
    Value?: string;
}

export interface StatsItemsApprovalJSON {
    wip: number;
    live: number;
    killed: number;
}

export interface StatsItemsMediaTypeJSON {
    num: number;
    mediaType: string;
}

export interface StatsOverviewJSON {
    mediaValue: number;
    ratings: string;
}

export interface StatsOverviewRatingsJSON {
    positive: number;
    neutral: number;
    negative: number;
    mixed: number;
    positiveMixed: number;
    negativeMixed: number;
}

export interface TagPutJSON {
    oldTag: string;
    newTag: string;
}

export interface TranslationJSON {
    text: string;
}

export interface TranslationPostJSON {
    source_lang: string;
    target_lang: string;
    text: string[];
}

export interface URLCacheJSON<F = string> {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    headline: string;
    quote?: string;
    media?: string;
    lang?: string;
    url: string;
    ref?: string;
    image?: F;
    expire?: number;
}

export interface UrlMetaJSON<F = string> {
    headline?: string;
    quote?: string;
    media?: string;
    lang?: Lang;
    image?: F;
}

export interface UserJSON {
    id: string;
    version: number;
    acl: Acl;
    username: string;
    email?: string;
    emails: string[];
    createdAt: string;
    updatedAt: string;
    lastLogin: string;
    numLogins?: number;
    password?: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    notifications?: boolean;
    unsubscribed?: boolean;
    inactive?: boolean;
    group?: UserGroup;
    cognitoCreated?: boolean;
}

export interface UserProjectTypeJSON {
    id: string;
    version: number;
    acl: Acl;
    createdAt: string;
    updatedAt: string;
    user: string;
    project: string;
    types: string[];
}

export interface VoidSuccessJSON {
    success?: boolean;
}
