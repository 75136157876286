import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { Label } from '../label';

export interface TextfieldProps<T = any> {
    className?: string;
    value: string | Date | FieldState<T>;
    placeholder?: string;
    label?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    password?: boolean;
    email?: boolean;
    number?: boolean;
    url?: boolean;
    required?: boolean;
    mobile?: boolean;
    lowercase?: boolean;
    autocomplete?: boolean | 'new-password';
    'data-role'?: string;
    format?(value: T): string;
    onChange?(value: T): void;
}

const Input = styled.input<TextfieldProps>`
    padding: 7px 9px;
    width: 100%;
    height: 32px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    border: 1px solid #ccc;
    background: #fff;
    text-transform: ${(props) => (props.lowercase ? 'lowercase' : 'none')};

    &[type='date'] {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &::placeholder {
        color: #aaa;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:disabled {
        cursor: not-allowed;
        background: #f9f9f9;
    }
`;

export const Textfield = observer((props: TextfieldProps) => {
    const {
        password,
        email,
        url,
        number,
        className,
        placeholder,
        autoFocus,
        disabled,
        label,
        required,
        lowercase,
        autocomplete
    } = props;

    function getType(): string {
        const {} = props;

        if (password) {
            return 'password';
        }

        if (email) {
            return 'email';
        }

        if (number) {
            return 'number';
        }

        if (url) {
            return 'url';
        }

        return 'text';
    }

    function getValue() {
        const { format } = props;
        const value =
            props.value instanceof FieldState ? props.value.value : props.value;

        if (format) {
            return format(value);
        }

        return value || '';
    }

    function onChange(e: React.SyntheticEvent<HTMLInputElement>): void {
        const { value } = e.currentTarget;

        const newValue = props.number ? Number(value) : value;

        if (props.value instanceof FieldState) {
            props.value.onChange(newValue);
        }

        if (props.onChange) {
            props.onChange(newValue);
        }
    }

    if (label) {
        const { label, ...rest } = props;

        return (
            <Label text={label}>
                <Textfield {...rest} />
            </Label>
        );
    }

    return (
        <Input
            className={className}
            type={getType()}
            required={required}
            placeholder={placeholder}
            autoFocus={autoFocus}
            lowercase={lowercase}
            spellCheck={false}
            autoComplete={
                autocomplete
                    ? typeof autocomplete === 'string'
                        ? autocomplete
                        : 'on'
                    : 'off'
            }
            data-role={props['data-role']}
            onChange={onChange}
            value={getValue()}
            disabled={disabled}
        />
    );
});
