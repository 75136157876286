import { observer } from 'mobx-react';
import * as React from 'react';
import { DEFAULT_BROWSERTITLE } from '../../core/utils';

interface BrowserTitleProps {
    title?: string;
}

export const BrowserTitle = observer((props: BrowserTitleProps) => {
    React.useEffect(() => {
        document.title = props.title || DEFAULT_BROWSERTITLE();

        return () => {
            document.title = DEFAULT_BROWSERTITLE();
        };
    }, [props.title]);

    return <></>;
});
