import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';
import { FieldStateAcl } from '../../entities/enhanced-acl';
import { UserGroup } from '../schema';
import { EnhancedAcl } from '../../entities/enhanced-acl';
import { EnhancedCompany } from './Company';

export type EnhancedUserForm = FormState<{
    id: FieldState<string>;
    version: FieldState<number>;
    acl: FieldStateAcl;
    username: FieldState<string>;
    email: FieldState<string | undefined>;
    emails: FieldState<string[]>;
    createdAt: FieldState<Date>;
    updatedAt: FieldState<Date>;
    lastLogin: FieldState<Date>;
    numLogins: FieldState<number | undefined>;
    password: FieldState<string | undefined>;
    firstname: FieldState<string | undefined>;
    lastname: FieldState<string | undefined>;
    company: FieldState<EnhancedCompany | undefined>;
    notifications: FieldState<boolean | undefined>;
    unsubscribed: FieldState<boolean | undefined>;
    inactive: FieldState<boolean | undefined>;
    group: FieldState<UserGroup>;
    cognitoCreated: FieldState<boolean | undefined>;
}>;

export class EnhancedUser extends EnhancedEntity<EnhancedUserForm> {
    /* public id!: string; */
    public version!: number;
    /* public acl!: EnhancedAcl; */
    public username!: string;
    public email!: string | undefined;
    public emails!: string[];
    public createdAt!: Date;
    public updatedAt!: Date;
    public lastLogin!: Date;
    public numLogins!: number | undefined;
    public password!: string | undefined;
    public firstname!: string | undefined;
    public lastname!: string | undefined;
    public company!: EnhancedCompany | undefined;
    public notifications!: boolean | undefined;
    public unsubscribed!: boolean | undefined;
    public inactive!: boolean | undefined;
    public group!: UserGroup;
    public cognitoCreated!: boolean | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            version: observable,
            username: observable,
            email: observable,
            emails: observable,
            createdAt: observable,
            updatedAt: observable,
            lastLogin: observable,
            numLogins: observable,
            password: observable,
            firstname: observable,
            lastname: observable,
            company: observable,
            notifications: observable,
            unsubscribed: observable,
            inactive: observable,
            group: observable,
            cognitoCreated: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            id: this.id,
            version: this.version,
            acl: this.acl,
            username: this.username,
            email: this.email,
            emails: this.emails,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            lastLogin: this.lastLogin,
            numLogins: this.numLogins,
            password: this.password,
            firstname: this.firstname,
            lastname: this.lastname,
            company: this.company,
            notifications: this.notifications,
            unsubscribed: this.unsubscribed,
            inactive: this.inactive,
            group: this.group,
            cognitoCreated: this.cognitoCreated,
        };
    }

    public initForm() {
        this.form = new FormState({
            id: new FieldState<string>(this.id),
            version: new FieldState<number>(this.version),
            acl: new FieldStateAcl(new EnhancedAcl(this.acl)),
            username: new FieldState<string>(this.username),
            email: new FieldState<string | undefined>(this.email),
            emails: new FieldState<string[]>(this.emails || []),
            createdAt: new FieldState<Date>(new Date(this.createdAt)),
            updatedAt: new FieldState<Date>(new Date(this.updatedAt)),
            lastLogin: new FieldState<Date>(new Date(this.lastLogin)),
            numLogins: new FieldState<number | undefined>(this.numLogins),
            password: new FieldState<string | undefined>(this.password),
            firstname: new FieldState<string | undefined>(this.firstname),
            lastname: new FieldState<string | undefined>(this.lastname),
            company: new FieldState<EnhancedCompany | undefined>(
                this.company === undefined || this.company === null
                    ? undefined
                    : new EnhancedCompany(this.company, { initForm: true }),
            ),
            notifications: new FieldState<boolean | undefined>(
                this.notifications,
            ),
            unsubscribed: new FieldState<boolean | undefined>(
                this.unsubscribed,
            ),
            inactive: new FieldState<boolean | undefined>(this.inactive),
            group: new FieldState<UserGroup>(this.group),
            cognitoCreated: new FieldState<boolean | undefined>(
                this.cognitoCreated,
            ),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
