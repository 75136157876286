import * as React from 'react';
import styled from 'styled-components';

interface FileUploadProps {
    loading?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    accept: 'image/*' | 'image/*,application/pdf' | '.xlsx, .xls';
    children?: React.ReactNode;
    onChange(files: File[]): void | Promise<void>;
}

const Container = styled.label`
    position: relative;
    display: block;
    overflow: hidden;
    cursor: pointer;

    * {
        cursor: pointer;
    }
`;

const FileInput = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:focus {
        outline: none;
    }
`;

export const FileUpload = (props: FileUploadProps) => {
    const { disabled, loading, accept, multiple, children } = props;

    async function onChange(
        e: React.SyntheticEvent<HTMLInputElement>
    ): Promise<void> {
        const input = e.currentTarget;
        const { files } = input;

        if (files && files.length) {
            const fileIterator: File[] = [];

            for (let i = 0; i < files.length; i++) {
                fileIterator.push(files[i]);
            }

            await props.onChange(fileIterator);
            input.value = '';
        }
    }

    return (
        <Container>
            {children}
            <FileInput
                disabled={loading || disabled}
                type="file"
                multiple={multiple}
                accept={accept}
                onChange={onChange}
            />
        </Container>
    );
};
