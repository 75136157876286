import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { Label } from '../label';
import moment from 'moment';

export interface DatepickerProps {
    className?: string;
    value: Date | FieldState<Date> | FieldState<Date | undefined> | undefined;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    mobile?: boolean;
    clearable?: boolean;
    onChange?(value?: Date): void;
}

const StyledInput = styled.input`
    width: 100%;
    height: 32px;
    border: 1px solid #ccc;
    color: #333;
    font-family: 'Ubuntu', sans-serif;
    padding: 5px 10px;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
`;

function getValue(propValue: DatepickerProps['value']) {
    let returnValue: Date | undefined;

    if (propValue instanceof FieldState) {
        returnValue = propValue.value;
    } else {
        returnValue = propValue;
    }

    return returnValue ? moment(returnValue).format('YYYY-MM-DD') : undefined;
}

export const Datepicker = observer((props: DatepickerProps) => {
    const value = getValue(props.value);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const date = e.currentTarget.value
            ? new Date(e.currentTarget.value)
            : undefined;

        if (props.value instanceof FieldState) {
            props.value.onChange(date as any);
        }

        props.onChange?.(date);
    };

    if (props.label) {
        const { label, ...rest } = props;

        return (
            <Label asDiv text={label}>
                <Datepicker {...rest} />
            </Label>
        );
    }

    return (
        <StyledInput
            type="date"
            value={value || ''}
            disabled={props.disabled}
            placeholder={props.placeholder}
            onChange={onChange}
        />
    );
});
