import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedTranslationPostForm = FormState<{
    source_lang: FieldState<string>;
    target_lang: FieldState<string>;
    text: FieldState<string[]>;
}>;

export class EnhancedTranslationPost extends EnhancedEntity<EnhancedTranslationPostForm> {
    public source_lang!: string;
    public target_lang!: string;
    public text!: string[];

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            source_lang: observable,
            target_lang: observable,
            text: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return {
            source_lang: this.source_lang,
            target_lang: this.target_lang,
            text: this.text,
        };
    }

    public initForm() {
        this.form = new FormState({
            source_lang: new FieldState<string>(this.source_lang),
            target_lang: new FieldState<string>(this.target_lang),
            text: new FieldState<string[]>(this.text || []),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
