import { Type } from '@reportroyal/api';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { AppStore, Route } from '../../app/store';
import { Spinner } from '../../components';
import { useViewTransition } from '../../start-transition';
import { useTSDI } from '../utils';

// tslint:disable-next-line:no-any
export type DefaultComponent<T = any> = React.LazyExoticComponent<
    React.ComponentType<T>
> | null;

const cacheComponentMap = new Map<string, DefaultComponent>();

function cacheDefaultComponent(key: string, component: DefaultComponent) {
    const cachedComponent = cacheComponentMap.get(key);

    if (!cachedComponent) {
        cacheComponentMap.set(key, component);
    }

    return cachedComponent || component;
}

const Content = styled.div`
    flex: 1;
`;

function getComponent(
    route: Route | undefined,
    params: { [key: string]: string }
): React.LazyExoticComponent<any> | null {
    switch (route) {
        case '/':
            return cacheDefaultComponent(
                'projects',
                React.lazy(() => import('../../projects'))
            );
        case '/login':
            return React.lazy(() => import('../../login'));
        case '/password-forgot':
            return React.lazy(() => import('../../password-forgot'));
        case '/search/:query':
            return React.lazy(() => import('../../search'));
        case '/project/:id/:type':
        case '/project/:id/:type/:viewmode':
        case '/project/:id/:type/:viewmode/:item':
            return React.lazy(() => import('../../project'));
        case '/calendar/evt':
            return React.lazy(() => import('../../calendar-evt'));
        case '/settings':
            return React.lazy(() => import('../../settings'));
        case '/settings/leadstories':
            return React.lazy(() => import('../../settings/leadstories'));
        case '/settings/authors':
            return React.lazy(() => import('../../settings/authors'));
        case '/settings/tags':
            return React.lazy(() => import('../../settings/tags'));
        case '/settings/users':
            return React.lazy(() => import('../../settings/users'));
        case '/settings/users/:id':
            return React.lazy(() => import('../../settings/user'));
        case '/settings/projects':
            return React.lazy(() => import('../../settings/projects'));
        case '/settings/projects/:id':
            return React.lazy(() => import('../../settings/project'));
        case '/settings/media/:type':
            return React.lazy(() => import('../../settings/media'));
        case '/settings/media/:type/:id':
            // TODO: add more types
            const type = params.type as Type;

            switch (type) {
                case 'print':
                    return React.lazy(
                        () => import('../../settings/media/print-edit')
                    );
                case 'online':
                    return React.lazy(
                        () => import('../../settings/media/online-edit')
                    );
                default:
                    return React.lazy(
                        () => import('../../settings/media/noop-edit')
                    );
            }
        case '/settings/mails':
            return React.lazy(() => import('../../settings/mails'));
        case '/settings/companies':
            return React.lazy(() => import('../../settings/companies'));
        case '/settings/companies/:id':
            return React.lazy(() => import('../../settings/company'));
        case '/settings/roles':
            return React.lazy(() => import('../../settings/roles'));
        case '/settings/roles/:id':
            return React.lazy(() => import('../../settings/role'));
        case '/settings/pool':
            return React.lazy(() => import('../../settings/pool'));
        case '/legal/imprint':
            return React.lazy(() => import('../../legal/imprint'));
        case '/notify/unsubscribe/:token':
            return React.lazy(() => import('../../notify-unsubscribe'));
        case '/help':
        case '/help/:id':
            return React.lazy(() => import('../../help'));
    }

    return null;
}

export const Router = observer(() => {
    const { route, params } = useTSDI(AppStore);
    const Component = React.useMemo(() => getComponent(route, params), [route]);

    const { visibility } = useViewTransition(route);

    return (
        <Content style={{ visibility }}>
            {Component && (
                <React.Suspense fallback={<Spinner />}>
                    <Component {...toJS(params)} />
                </React.Suspense>
            )}
        </Content>
    );
});
